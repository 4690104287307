import { IColumn, ICommandBarItemProps, TooltipHost, Persona, PersonaSize, Stack, Icon, DialogType, ComboBox, TextField, PanelType, IComboBoxOption, IComboBox, IActivityItemProps } from '@fluentui/react';
import { useState, useEffect, useContext, useImperativeHandle } from "react";
import { TableBase } from "../../../components/Table/TableBase";
import { IException, IItemComboBaseDTO, ISearch } from "../../../interfaces/base/IBase";
import { IPagination } from "../../../interfaces/base/ITable";
import { IProgramacionPersona, IFilterAsignacion, IEmailHeloValido } from "../../../interfaces/IProgramacion";
import ProgramacionBusService from "../../../services/programacionBusService";
import { _initialFilter, _initialPagination, splitExceptionDetail, baseImageUrl, stackTokensHorizontal, ItemNoneSelect } from "../../../utils/Helper/helper";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../../utils/Helper/tableHelper";
import ThemeBase from "../../../utils/Theme/themeBase";
import { useBoolean } from "@fluentui/react-hooks";
import { ActualizarDatosVuelo } from "../vuelo/panel/actualizarDatosVuelo";
import { MasivoDatosVuelo } from "../vuelo/panel/masivoDatosVuelo";
import { useNavigate, useParams } from "react-router-dom";
import { isRoleAccessAll, roleAdmin, ROLE_AGENCIA_VUELO } from "../../../utils/Helper/roleHelper";
import { AuthContext } from "../../../auth/context/auth-context";
import RepoteBase from "../../../components/Generic/ReporteBase";
import { DialogBase } from "../../../components/Dialog/DialogBase";
import ProgramacionService from "../../../services/ProgramacionService";
import { ICollectionBusAndVuelo } from "../../../interfaces/IProgramacionBus";
import { PanelPlantillaFiltro } from '../../../components/PanelUpload/PanelPlantillaFiltro';
import EmpresaService from '../../../services/empresaService';
import { Ifilter } from '../../../interfaces/IFilter';
import { IEmpresa } from '../../../interfaces/IEmpresa';
import ConfiguracionService from '../../../services/configuracionService';
import OrganigramaService from '../../../services/organigramaService';
import { ProgramacionProps, ProgramacionPropsVuelo } from '../../../types/ITypes';
import React from 'react';
import ActulizarComunicado from '../vuelo/panel/actualizarComunicado';

import { Selection } from "@fluentui/react/lib/DetailsList";
import { IDetalleUbigeo } from '../../../interfaces/IUbigeos';
import { MasivoDatosComunicados } from '../vuelo/panel/masivoDatosComunicados';
import RepoteBaseGenerico from '../../../components/Generic/ReporteBaseGenerico';

const roleCerrarVuelo = () => {
    let a = [
        ROLE_AGENCIA_VUELO,
    ];
    return roleAdmin().concat(a);
}



export const ListarBusAndVuelo = React.forwardRef((props: ProgramacionPropsVuelo, forwardedRef) => {
    const { id } = useParams()

    const _FILTRO_INICIAL_LISTA: IFilterAsignacion = {
        ..._initialFilter(),
        programacionId: props.programacionId,
        gerenciaId: null,
        nivelLst: [],
        levelLst: [],
    }

    /* Access & Role */
    const { access } = useContext(AuthContext);
    let role = access?.role ?? [];
    let isAdmin = isRoleAccessAll(role, roleAdmin());
    let isAgenciaVuelo = isRoleAccessAll(role, roleCerrarVuelo());

    const navigate = useNavigate();
    const [hidden, setHidden] = useState(false);

    /* Data & Colum */
    const [data, setData] = useState<ICollectionBusAndVuelo>({
        ..._initialDataCollection(),
        isCierreAgenciaViaje: true,
    });
    const [column, setColumn] = useState<IColumn[]>([]);

    /* Filter */
    const [filter, setFilter] = useState<IFilterAsignacion>(_FILTRO_INICIAL_LISTA);

    /* Panel */
    /* Actualizar individual */
    const [isOpenActualizar, { setTrue: openActualizar, setFalse: dismissActualizar }] = useBoolean(false);
    const [selId, setSelId] = useState('');

    /* Actualizacion masiva */
    const [isOpenMasivo, { setTrue: openMasivo, setFalse: dismissMasivo }] = useBoolean(false);

    /* Informacion de comunicado */
    const [isOpenComunicado, { setTrue: openComunicado, setFalse: dismissComunicado }] = useBoolean(false);


    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    //Paneles
    const [isOpenReport, { setTrue: openReport, setFalse: dismissReport }] = useBoolean(false);
    const [isOpenPanelFiltro, { setTrue: openPanelFiltro, setFalse: dismissPanelFiltro },] = useBoolean(false);
    const [isOpenMasivoComunicados, { setTrue: openMasivoComunicados, setFalse: dismissMasivoComunicados }] = useBoolean(false);


    /* Dialog */
    const [hideEmailCierre, { toggle: toggleEmailCierre }] = useBoolean(true);
    const [hideEmailCAE, { toggle: toggleEmailCAE }] = useBoolean(true);
    const [hideSms, { toggle: toggleSms }] = useBoolean(true);

    /* Loading */
    const [isLoadingEmail, { setTrue: loadingEmail, setFalse: dismissLoadingEmail }] = useBoolean(false);
    const [isLoadingSms, { setTrue: loadingSms, setFalse: dismissLoadingSms }] = useBoolean(false);

    const [empresaCombo, setEmpresaCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterEmpresa, setFilterEmpresa] = useState<Ifilter>({
        ..._initialFilter()
    });

    const [nivelCombo, setNivelCombo] = useState<IItemComboBaseDTO[]>([]);
    const [keysNivel, setKeysNivel] = useState<string[]>([]);
    const [filterNivel, setFilterNivel] = useState<ISearch>({
        search: ""
    });

    const [levelCombo, setLevelCombo] = useState<IItemComboBaseDTO[]>([]);
    const [keysLevel, setKeysLevel] = useState<string[]>([]);
    const [filterLevel, setFilterLevel] = useState<ISearch>({
        search: ""
    });

    const [gerenciaCombo, setGerenciaCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterGerencia, setFilterGerencia] = useState<ISearch>({
        search: ""
    });

    const [distritoCombo, setDistritoCombo] = useState<IItemComboBaseDTO[]>([]);



    /* Imperative Hook */
    useImperativeHandle(forwardedRef, () => {
        return {
            filterFromParent: (search = '') => {

                let p = _initialPagination().currentPage;

                let f: IFilterAsignacion = {
                    ..._initialFilter(),
                    programacionId: props.programacionId,
                    skip: (p - 1) * pageSize,
                    take: pageSize,
                    search: search.trim(),
                }

                setFilter(f);
                setCurrentPage(p);
                reloadInitial(f);
            }
        }
    });

    /* Hook */
    useEffect(() => {
        if (props.programacionId != null) {
            reloadInitial();
        }
    }, []);

    useEffect(() => {
        setTitleColumn();
    }, [data]);

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);

    useEffect(() => {
        dataEmpresa();

    }, [filterEmpresa]);

    useEffect(() => {
        dataNivel();

    }, [filterNivel]);

    useEffect(() => {
        dataLevel();

    }, [filterLevel]);

    useEffect(() => {
        dataGerencia();

    }, [filterGerencia]);

    useEffect(() => {
        if (isOpenPanelFiltro) {
            dataDistrito()
        }
    }, [isOpenPanelFiltro]);

    /* useEffect(() => {
        setFilter({
          ...filter,
          search: cajaBusqueda
        })

        _onPress()
    }, [cajaBusqueda]); */


    /* Api */
    const dataPersonaBusAndVuelo = (param = filter) => {
        if (props.programacionId != '') {
            setData({
                ..._initialDataCollection(),
                isCierreAgenciaViaje: true,
            });
            ProgramacionBusService.allBusAndVuelo(param)
                .then((res) => {
                    if (res.status == 200) {

                        // console.log("****** tabla ********");
                        // console.table(res.data.items);
                        setData(res.data);
                        setHidden(true);
                        setTotal(res.data.total);
                    }
                })
                .catch((e: IException) => {
                    alert(splitExceptionDetail(e));
                    setHidden(true);
                });
        }
    }

    const dataEmpresa = () => {

        EmpresaService.all(filterEmpresa)
            .then((res) => {
                if (res.status == 200) {

                    // console.log("***** empresa *****");
                    // console.table(res.data.items);
                    let d: IEmpresa[] = res.data.items;
                    let t: IItemComboBaseDTO[] = d.map((x) => {
                        return {
                            key: x.empresaId,
                            text: x.nombre as string,
                        };
                    });

                    setEmpresaCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    };

    const dataNivel = () => {

        ConfiguracionService.allNivel(filterNivel)
            .then((res) => {
                if (res.status == 200) {

                    let t: IItemComboBaseDTO[] = res.data;
                    // t.unshift(ItemSelectAll());
                    setNivelCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    };

    const dataLevel = () => {

        ConfiguracionService.allLevel(filterLevel)
            .then((res) => {
                if (res.status == 200) {

                    let t: IItemComboBaseDTO[] = res.data;
                    // t.unshift(ItemSelectAll());
                    setLevelCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    };

    const dataGerencia = () => {
        setGerenciaCombo([]);
        OrganigramaService.organigramaByTipo('01', filterGerencia)
            .then((res) => {
                if (res.status == 200) {
                    let t: IItemComboBaseDTO[] = res.data;
                    t.unshift(ItemNoneSelect());

                    setGerenciaCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataDistrito = () => {
        ProgramacionService.ubigeosProgramacion(id!).then((res) => {
            let d: IDetalleUbigeo[] = res.data
            let op: IItemComboBaseDTO[] = []
            d.map((item) =>
                op.push({
                    key: item.ubigeoId,
                    text: item.distrito
                })
            )
            setDistritoCombo(op)
        }).catch((e) => {
            console.log(e)
        })
    }

    const emailCierreDatos = () => {
        loadingEmail();

        let t: IEmailHeloValido = {
            programacionId: props.programacionId,
        }

        ProgramacionService.emailCierreAgenciaVuelo(t)
            .then((res) => {
                if (res.status == 200) {
                    toggleEmailCierre();
                    dismissLoadingEmail();
                }
            })
            .catch((e) => {
                alert(splitExceptionDetail(e));
                dismissLoadingEmail();
            });
    }

    const emailCAE = () => {
        loadingEmail();

        ProgramacionBusService.emailCAE(props.programacionId)
            .then((res) => {
                if (res.status == 200) {
                    toggleEmailCAE();
                    dismissLoadingEmail();
                }
            })
            .catch((e) => {
                alert(splitExceptionDetail(e));
                dismissLoadingEmail();
            });
    }

    const smsCAE = () => {
        loadingSms();

        ProgramacionBusService.smsCAE(props.programacionId)
            .then((res) => {
                if (res.status == 200) {
                    toggleSms();
                    dismissLoadingSms();
                }
            })
            .catch((e) => {
                alert(splitExceptionDetail(e));
                dismissLoadingSms();
            });
    }

    /* Function */
    const reloadInitial = (param = filter) => {
        setFilter(_FILTRO_INICIAL_LISTA)
        setSelectItems([])
        setHidden(false);
        setTitleColumn();
        dataPersonaBusAndVuelo(param);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial(),
            }
        ];

        if (isAdmin) {
            _items.push(
                {
                    ...IconHelper.ICON_INFO,
                    iconProps: { iconName: 'MegaphoneSolid' },
                    text: 'Comunicado',
                    onClick: openComunicado,
                    disabled: selectItems.length > 0 ? false : true
                },
                {
                    ...IconHelper.ICON_UPLOAD,
                    text: 'Cargar',
                    onClick: openMasivo,
                },
                {
                    ...IconHelper.ICON_MAIL_CHECK,
                    text: 'Cerrar Datos',
                    onClick: toggleEmailCierre,
                },
                {
                    ...IconHelper.ICON_REPLY_MIRRORED,
                    text: 'Notificar CAE',
                    subMenuProps: {
                        items: [
                            {
                                ...IconHelper.ICON_MAIL_CHECK,
                                key: '1',
                                onClick: toggleEmailCAE
                            },
                            {
                                ...IconHelper.ICON_SMS,
                                key: '2',
                                onClick: toggleSms
                            }
                        ],
                    },
                },

            );
        } else if (isAgenciaVuelo) {
            _items.push(
                {
                    ...IconHelper.ICON_UPLOAD,
                    text: 'Cargar',
                    onClick: openMasivo,
                    // disabled: data.isCierreAgenciaViaje,
                    title: 'TERMINO',
                },
                {
                    ...IconHelper.ICON_MAIL_CHECK,
                    text: 'Cerrar Datos',
                    onClick: toggleEmailCierre,
                    // disabled: data.isCierreAgenciaViaje
                },
                {
                    ...IconHelper.ICON_MAIL_CHECK,
                    text: 'Notificar',
                    onClick: toggleEmailCAE,
                },
            )
        }

        return _items;
    };

    const _rightBar = () => {
        const _items: ICommandBarItemProps[] = [
            ...(props.tipoPersonal === "MMG"
                ? [{
                    ...IconHelper.ICON_UPLOAD_COMUNICADO,
                    onClick: openMasivoComunicados,
                  }]
                : []
            ),
            {
                ...IconHelper.ICON_EXCEL,
                onClick: openReport,
            },
            {
                ...IconHelper.ICON_FILTER,
                onClick: () => openPanelFiltro(),
            },
        ];

        return _items;
    };

    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: "column1",
                name: "Foto",
                ariaLabel:
                    "Column operations for File type, Press to sort on File type",
                isIconOnly: true,
                fieldName: "foto",
                minWidth: 30,
                maxWidth: 30,
                onRender: (item: IProgramacionPersona) => (
                    <TooltipHost content={`Foto`}>
                        <Persona
                            text=""
                            size={PersonaSize.size24}
                            imageUrl={baseImageUrl}
                        />
                    </TooltipHost>
                ),
            },
            {
                key: "column2",
                name: "Nombre",
                fieldName: "nombres",
                minWidth: 170,
                maxWidth: 170,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return (
                        <span style={{ fontSize: '90%' }}>{item.nombres}</span>
                    );
                },
            },
            {
                key: "column3",
                name: "Identificación",
                fieldName: "nroDOI",
                minWidth: 80,
                maxWidth: 90,
                isResizable: true,
                data: "number",
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return (
                        <span
                            style={{ fontSize: "95%" }}>{`${item.tipoDocumento} ${item.nroDOI}`}</span>
                    );
                },
            },
            {
                key: 'column3.1',
                name: 'Nivel',
                fieldName: 'nivel',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3.2',
                name: 'Level',
                fieldName: 'level',
                minWidth: 60,
                maxWidth: 60,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3.3',
                name: 'Gerencia',
                fieldName: 'gerencia',
                minWidth: 170,
                maxWidth: 170,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column17',
                name: 'Tipo Traslado',
                fieldName: 'tipoTraslado',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'comentario',
                name: 'Comunicado',
                fieldName: 'comentario',
                minWidth: 60,
                maxWidth: 60,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
                onRender: (item: ICollectionBusAndVuelo) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal} style={ThemeBase.justifiedStyle}>
                            <TooltipHost content={`${item.comentario == null ? "" : item.comentario}`}>
                                {(item.comentario == null || item.comentario === "") ?
                                    <Icon
                                        iconName='CannedChat'
                                        style={{ cursor: "pointer", color: "#ddd" }}
                                        className={ThemeBase.classes.iconFont}
                                    /> :
                                    <Icon
                                        iconName='CannedChat'
                                        style={{ cursor: "pointer", color: "#0177D3" }}
                                        className={ThemeBase.classes.iconFont}
                                    />
                                }
                            </TooltipHost>
                        </Stack>
                    </>
                ),
            },
        ];

        /* Validar solo el admin tendria acceso */
        if (isAdmin) {
            col.push(
                {
                    key: "column16",
                    name: "Opciones",
                    fieldName: "opciones",
                    minWidth: 70,
                    maxWidth: 80,
                    isResizable: true,
                    isCollapsible: true,
                    data: "number",
                    onRender: (item: IProgramacionPersona) => (
                        <>
                            <Stack horizontal tokens={stackTokensHorizontal} style={ThemeBase.justifiedStyle}>
                                <TooltipHost content={`Datos Vuelo`}>
                                    <Icon
                                        iconName='PageEdit'
                                        style={{ cursor: "pointer" }}
                                        className={ThemeBase.classes.iconFont}
                                        onClick={() => {
                                            setSelId(item.programacionPersonaId);
                                            openActualizar();
                                        }}
                                    />
                                </TooltipHost>
                            </Stack>
                        </>
                    ),
                },
            );
        } else {
            if (isAgenciaVuelo) {
                col.push(
                    {
                        key: "column16",
                        name: "Opciones",
                        fieldName: "opciones",
                        minWidth: 90,
                        maxWidth: 100,
                        isResizable: true,
                        isCollapsible: true,
                        data: "number",
                        onRender: (item: IProgramacionPersona) => (
                            <>
                                <Stack horizontal tokens={stackTokensHorizontal} style={ThemeBase.justifiedStyle}>
                                    {!item.isDisabled &&
                                        <TooltipHost content={`Datos Vuelo`}>
                                            <Icon
                                                iconName='PageEdit'
                                                style={{ cursor: "pointer" }}
                                                className={ThemeBase.classes.iconFont}
                                                onClick={() => {
                                                    setSelId(item.programacionPersonaId);
                                                    openActualizar();
                                                }}
                                            />
                                        </TooltipHost>
                                    }
                                </Stack>
                            </>
                        ),
                    },
                );
            }
        }

        col.push(
            {
                key: 'column3.9',
                name: 'Fecha Ingreso',
                fieldName: 'fechaIngreso',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3.10',
                name: 'Antiguedad',
                fieldName: 'antiguedad',
                minWidth: 160,
                maxWidth: 160,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Fecha Vuelo',
                fieldName: 'fechaVuelo',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Aerolinea',
                fieldName: 'aerolinea',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column6',
                name: 'Vuelo',
                fieldName: 'vuelo',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column7',
                name: 'Ruta',
                fieldName: 'ruta',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column8',
                name: 'Hora Sale',
                fieldName: 'horaSale',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column9',
                name: 'Hora Llega',
                fieldName: 'horaLlega',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column10',
                name: 'Código Aerolinea',
                fieldName: 'codAerolinea',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column11',
                name: 'Código Gds',
                fieldName: 'codGds',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column12',
                name: 'Tarifa',
                fieldName: 'tarifa',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column13',
                name: 'Status',
                fieldName: 'status',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column14',
                name: 'Estado Vuelo',
                fieldName: 'estadoVuelo',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
        );

        setColumn(col);
    };

    /* Event Tab */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: IFilterAsignacion = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
                // nivelLst: keysNivel

            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    const _onChangeComboFiltro = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {

                switch (type) {
                    case 'nivel':
                        let data1 = (prevSelectedKeys: any[]) =>
                            option.selected
                                ? [...prevSelectedKeys, option!.key as string]
                                : prevSelectedKeys.filter((k) => k !== option!.key);

                        console.log(" ====== data1 ==========");

                        setKeysNivel(data1);

                        break;
                    case 'level':
                        let data2 = (prevSelectedKeys: any[]) =>
                            option.selected
                                ? [...prevSelectedKeys, option!.key as string]
                                : prevSelectedKeys.filter((k) => k !== option!.key);

                        setKeysLevel(data2);

                        break;
                    case 'gerencia':
                        setFilter({
                            ...filter,
                            gerenciaId: option.key.toString(),
                        })
                        break;
                    case 'empresa':
                        setFilter({
                            ...filter,
                            empresaId: option.key.toString(),
                        })
                        break;
                    case 'distrito':
                        setFilter({
                            ...filter,
                            ubigeoId: option.key.toString(),
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const _onChangeTextFiltro = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (event != undefined) {
            if (newValue != undefined) {
                let nameInput = event.currentTarget.getAttribute("name");

                switch (nameInput) {
                    case "doi":

                        setFilter({
                            ...filter,
                            search: newValue.trim(),
                        });

                        break;
                    case "nombres":
                        setFilter({
                            ...filter,
                            search: newValue.trim(),
                        });

                        break;
                    default:
                        break;
                }
            }
        }
    };

    /* const _onPress = () => {

        if (cajaBusqueda.length >= 4) {
            let model: IFilterAsignacion = {
                ...filter,
                search: cajaBusqueda
            }
            setFilter(model);
            reloadInitial(model);

            console.log("******** press 2 **************")
        }

        if (cajaBusqueda.length == 0) {

            let model: IFilterAsignacion = {
                ...filter,
                search: ""
            }
            setFilter(model);
            reloadInitial(model);

            console.log("******** press 3 **************")
        }

        onPress();
    } */

    //filtro
    const _buscarFiltro = () => {

        let modelTablaProgramacion: IFilterAsignacion = {
            ...filter,
            // transporteId?: string | null; 
            // nivelId?: string | null,
            // levelId?: string | null,
            // campamentoId?: string | null;
            // empresaId?: string | null,
            // gerenciaId?: string | null,
            nivelLst: keysNivel,
            levelLst: keysLevel
        }
        setFilter(modelTablaProgramacion);
        reloadInitial(modelTablaProgramacion);

        dismissPanelFiltro();
    }

    const _limpiarFiltro = () => {
        setKeysLevel([])
        setKeysNivel([])
        setFilter(_FILTRO_INICIAL_LISTA);
        reloadInitial(_FILTRO_INICIAL_LISTA);

        dismissPanelFiltro();
    }

    //#region ProgramacionPersonas Seleccionados
    const [selectItems, setSelectItems] = useState<string[]>([]);
    const selection = new Selection({
        onSelectionChanged: () => {
            let d: any[] = selection.getSelection();
            let t: string[] = d
                .filter(x => !x.isDisabled)
                .map((x) => {
                    return x.programacionPersonaId
                });
            setSelectItems(t)
        },
        getKey: (item: any) => item.programacionPersonaId
    });
    //#endregion

    return (
        <>
            <TableBase
                leftBar={_leftBar()}
                rigthBar={_rightBar()}
                column={column}
                data={data.items}
                style={ThemeBase.notPaddingTableBase}
                pagination={pager}
                isLoading={!hidden}
                selection={selection}
            />

            <ActualizarDatosVuelo programacionPersonaId={selId} isOpen={isOpenActualizar} dismiss={dismissActualizar} reloadParent={reloadInitial}></ActualizarDatosVuelo>
            <MasivoDatosVuelo programacionId={props.programacionId} isTabPersona={true} isOpen={isOpenMasivo} dismiss={dismissMasivo} reloadParent={reloadInitial}></MasivoDatosVuelo>
            <MasivoDatosComunicados programacionId={props.programacionId} isTabPersona={true} isOpen={isOpenMasivoComunicados} dismiss={dismissMasivoComunicados} reloadParent={reloadInitial}></MasivoDatosComunicados>

            {/* Comunicado personas programadas segun seleccion */}
            <ActulizarComunicado
                isOpen={isOpenComunicado}
                onDismiss={dismissComunicado}
                reloadParent={reloadInitial}
                programacionesPersonaIds={selectItems}
            />

            {/* Report personas programadas en su totalidad - similar admin */}
            <RepoteBaseGenerico
                headerName={`BCD`}
                NameReport='MOVReporteDatosBCD.trdx'
                Parameters={{ ProgramacionId: props.programacionId }}
                openTelerik={isOpenReport}
                dismissTelerik={dismissReport}
            ></RepoteBaseGenerico>

            {/* Dialog email Cierre Datos */}
            <DialogBase
                isHidden={hideEmailCierre}
                subTitle={'Al confirmar el envio de notificacion se tomará como completado la actualizacion de datos de vuelo hacia el Área de Transportes'}
                tipo={DialogType.normal}
                toggleHidden={() => {
                    dismissLoadingEmail();
                    toggleEmailCierre();
                }}
                dependency={[]}
                confirm={emailCierreDatos}
                isDisableConfirm={isLoadingEmail}
            ></DialogBase>

            {/* Dialog email CAE */}
            <DialogBase
                isHidden={hideEmailCAE}
                subTitle={'Al confirmar se desplegarán las notificaciones a los colaboradores con la información registrada'}
                tipo={DialogType.normal}
                toggleHidden={() => {
                    dismissLoadingEmail();
                    toggleEmailCAE();
                }}
                dependency={[]}
                confirm={emailCAE}
                isDisableConfirm={isLoadingEmail}
            ></DialogBase>

            {/* Dialog SMS CAE */}
            <DialogBase
                isHidden={hideSms}
                subTitle={'Al confirmar se hará el envío de los SMS correspondientes según el lugar de movilización'}
                tipo={DialogType.normal}
                toggleHidden={() => {
                    dismissLoadingSms();
                    toggleSms();
                }}
                dependency={[]}
                confirm={smsCAE}
                isDisableConfirm={isLoadingSms}
            ></DialogBase>

            <div id='panelFiltro'>
                <>
                    <PanelPlantillaFiltro
                        textHeader='Filtro'
                        subTextHeader='Elija las condiciones para el filtro personalizado'
                        textBoton='Buscar'
                        textBotonDefault='Limpiar'
                        onclickBoton={_buscarFiltro}
                        onclickBotonDefault={_limpiarFiltro}
                        openPanel={isOpenPanelFiltro}
                        closePanel={dismissPanelFiltro}
                        // onRenderHeader={onRenderHeaderFilter}
                        size={PanelType.medium}
                        disabledBoton={false}
                        estadoBarraEnvio={false}
                        nameIconHeader={"Filter"}
                        nameIconButton={"Search"}
                    // dep={[filter, keysNivel]}
                    >
                        <>

                            <div className="ms-Grid" dir="ltr">
                                {/* <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <br />
                                        <br />
                                        <DatePicker
                                            label="Fecha de:"
                                            placeholder="dd/mm/yyyy"
                                            ariaLabel="Seleccione una fecha"
                                            // value={fechaInicioFilter}
                                            // onSelectDate={(date?: any) => { setFechaInicioFilter(date); setminFechaFin(addDays(date, 1)) }}
                                            // formatDate={onFormatDate}
                                            // maxDate={Ayer}
                                            underlined
                                        />

                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <br />
                                        <br />
                                        <DatePicker
                                            label="a:"
                                            placeholder="dd/mm/yyyy"
                                            ariaLabel="Seleccione una fecha"
                                            // value={fechaFinFilter}
                                            // onSelectDate={setFechaFinFilter as (date?: any) => void}
                                            // formatDate={onFormatDate}
                                            // maxDate={new Date()}
                                            // minDate={minFechaFin}
                                            // disabled={(fechaInicioFilter != undefined) ? false : true}
                                            underlined
                                        />

                                    </div>
                                </div>
                                <br /> */}
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg612" >
                                        <TextField
                                            // label='Nro Documento Identidad'
                                            placeholder='Busque por nombres o DOI'
                                            name='doi'
                                            value={filter?.search != undefined ? filter?.search : ""}
                                            onChange={(e, o) => _onChangeTextFiltro(e, o)}
                                        />
                                    </div>
                                    {/* <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label='Apellidos y Nombres'
                                            name='nombres'
                                            value={filter?.search != undefined ? filter?.search : ""}
                                            onChange={(e, o) => _onChangeTextFiltro(e, o)}
                                        />
                                    </div> */}
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                                        <ComboBox
                                            label="Nivel:"
                                            options={nivelCombo}
                                            // componentRef={comboBoxRef}
                                            selectedKey={keysNivel}
                                            // onChange={_onChangePersonaEstado}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            multiSelect
                                            onChange={(e, o) => _onChangeComboFiltro("nivel", e, o)}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                                        <ComboBox
                                            label="Level:"
                                            options={levelCombo}
                                            // componentRef={comboBoxRef}
                                            selectedKey={keysLevel}
                                            // calloutProps={{ doNotLayer: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            multiSelect
                                            onChange={(e, o) => _onChangeComboFiltro("level", e, o)}
                                            // placeholder='Gerencia'
                                            autoComplete='off'
                                            calloutProps={{ directionalHintFixed: true }}
                                            // onKeyUp={(e) => _onUpCombo('gerencia', e)}
                                            // onBlur={() => _onBlurCombo('gerencia')}
                                            required
                                        />

                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Gerencia:"
                                            options={gerenciaCombo}
                                            // componentRef={comboBoxRef}
                                            selectedKey={filter.gerenciaId}

                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            // multiSelect
                                            onChange={(e, o) => _onChangeComboFiltro("gerencia", e, o)}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Empresa:"
                                            options={empresaCombo}
                                            // componentRef={comboBoxRef}
                                            selectedKey={filter.empresaId}
                                            // onChange={_onChangePersonaEstado}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            // multiSelect
                                            onChange={(e, o) => _onChangeComboFiltro("empresa", e, o)}
                                        />
                                    </div>
                                </div>
                                <br />
                                {/* UBIGUEO */}
                                <div className="ms-Grid-row">
                                    {/* <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                        <ComboBox
                                            label="Departamento"
                                            options={[]}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                        <ComboBox
                                            label="Provincia"
                                            options={[]}
                                        />
                                    </div> */}
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
                                        <ComboBox
                                            label="Distrito"
                                            options={distritoCombo}
                                            selectedKey={filter.ubigeoId}
                                            // onChange={(arg, op) => {
                                            //     if (op) {
                                            //         setFilter({ ...filter, ubigeoId: op.key.toString() })
                                            //     }
                                            // }}
                                            onChange={(e, o) => _onChangeComboFiltro("distrito", e, o)}
                                            useComboBoxAsMenuWidth
                                        />
                                    </div>
                                </div>
                                {/* <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Nivel:"
                                            id='nivel'
                                            options={[
                                                { key: "1", text: "opcion1" },
                                                { key: "2", text: "opcion2" }
                                            ]}
                                            // options={estadoCombo}
                                            // componentRef={comboBoxRef}
                                            // selectedKey={keyspersonaEstados}
                                            // onChange={_onChangePersonaEstado}
                                            calloutProps={{ doNotLayer: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                        // multiSelect
                                        // onChange={(e, o) => _onChangeComboFiltro("1", e, o)}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Nivel:"
                                            id='nivel'
                                            options={[
                                                { key: "1", text: "opcion1" },
                                                { key: "2", text: "opcion2" }
                                            ]}
                                            // options={estadoCombo}
                                            // componentRef={comboBoxRef}
                                            // selectedKey={keyspersonaEstados}
                                            // onChange={_onChangePersonaEstado}
                                            calloutProps={{ doNotLayer: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                        // multiSelect
                                        // onChange={(e, o) => _onChangeComboFiltro("1", e, o)}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Nivel:"
                                            id='nivel'
                                            options={[
                                                { key: "1", text: "opcion1" },
                                                { key: "2", text: "opcion2" }
                                            ]}
                                            // options={estadoCombo}
                                            // componentRef={comboBoxRef}
                                            // selectedKey={keyspersonaEstados}
                                            // onChange={_onChangePersonaEstado}
                                            calloutProps={{ doNotLayer: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                        // multiSelect
                                        // onChange={(e, o) => _onChangeComboFiltro("1", e, o)}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Nivel:"
                                            id='nivel'
                                            options={[
                                                { key: "1", text: "opcion1" },
                                                { key: "2", text: "opcion2" }
                                            ]}
                                            // options={estadoCombo}
                                            // componentRef={comboBoxRef}
                                            // selectedKey={keyspersonaEstados}
                                            // onChange={_onChangePersonaEstado}
                                            calloutProps={{ doNotLayer: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                        // multiSelect
                                        // onChange={(e, o) => _onChangeComboFiltro("1", e, o)}
                                        />
                                    </div>
                                </div> */}
                            </div>
                        </>
                    </PanelPlantillaFiltro>

                </>

            </div>
        </>
    )
})