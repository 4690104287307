import { IGuardia, IGuardiaFilter, IGuardiaPaxFilter } from "../interfaces/IGuadia";
import { fetchMovilizacion } from "./http-common";

const urlGuardia = 'Guardia';

const allGuardia = (filter: IGuardiaFilter) => {
    return fetchMovilizacion.get(`${urlGuardia}?Skip=${filter.skip}&Take=${filter.take}&Search=${filter.search}`);
}

const addGuardia = (data: IGuardia) => {
    return fetchMovilizacion.post(urlGuardia, data);
}

const editGuardia = (data: IGuardia) => {
    return fetchMovilizacion.put(urlGuardia, data);
}

const deleteGuardia = (id: string) => {
    return fetchMovilizacion.delete(`${urlGuardia}/${id}`);
}

const guardiaListaPax = (filter : IGuardiaPaxFilter, controller = new AbortController()) => {
    return fetchMovilizacion.get(`${urlGuardia}/ListaPax?GuardiaId=${filter.guardiaId}&Skip=${filter.skip}&Take=${filter.take}&Search=${filter.search}`, {signal : controller.signal});
}

const GuardiaService = {
    allGuardia,
    addGuardia,
    editGuardia,
    deleteGuardia,
    guardiaListaPax
}

export default GuardiaService;