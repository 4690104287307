import { fetchMovilizacion } from "./http-common";

const urlListaDataStorage = "ListaDataStorage"

const cargarblobstorage = (listUrlBase) => {
    const data = {
        ListUrlBase: [listUrlBase]
    };
    return fetchMovilizacion.post(`v1/${urlListaDataStorage}/HistoricoAdjuntos`, data);
};

const blobStorageService = {
    cargarblobstorage
};

export default blobStorageService;