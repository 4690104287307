import {
    DetailsList,
    DetailsListLayoutMode,
    DetailsRow,
    getTheme,
    IButtonStyles, IColumn, ICommandBarItemProps, Icon, IconButton, IDetailsListProps, IDetailsListStyles, IDetailsRowStyles, Label, Link, mergeStyleSets, Modal,
    Pivot,
    PivotItem,
    PrimaryButton, Selection, SelectionMode, Spinner, SpinnerSize, Stack, TextField, TooltipHost
} from "@fluentui/react";
import { useNavigate } from "react-router-dom";
import { useBoolean } from '@fluentui/react-hooks';
import { useEffect, useState } from "react";
import React from "react";
import PersonaService from "../../services/PersonaService";
import SpinnerCenter from "../../components/Generic/SpinnerCenter";
import { stackTokensHorizontal, TAB_BAJADA, TAB_SUBIDA, _initialFilter, _initialPagination } from "../../utils/Helper/helper";
import { saveAs } from 'file-saver';
import { HeadingBar } from "../../components/Generic/HeadingBar";
import { IProgramacion } from "../../interfaces/IProgramacion";
import { Ifilter } from "../../interfaces/IFilter";
import ProgramacionService from "../../services/ProgramacionService";
import { TableBase } from "../../components/Table/TableBase";
import ThemeBase from "../../utils/Theme/themeBase";
import { IDataCollection, IPagination } from "../../interfaces/base/ITable";
import { _initialDataCollection } from "../../utils/Helper/tableHelper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { CustomCommandBar } from "../../components/Generic/customCommandBar";
import RepoteBase from "../../components/Generic/ReporteBase";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { State } from "../../components/Generic/state";
import { ColorHelper } from "../../utils/Helper/colorHelper";
import RepoteBaseGenerico from "../../components/Generic/ReporteBaseGenerico";

export const ListarProgramacionPruebaCovid = () => {
    let navigate = useNavigate();

    const [tabIndex, setTabIndex] = useState('0');
    const [hidden, setHidden] = useState(false);

    const [columnIngreso, setColumnIngreso] = useState<IColumn[]>([]);
    const [columnSalida, setColumnSalida] = useState<IColumn[]>([]);
    const [ingreso, setIngreso] = useState<IDataCollection<IProgramacion>>(_initialDataCollection);
    const [salida, setSalida] = useState<IDataCollection<IProgramacion>>(_initialDataCollection);

    const [filter, setFilter] = useState<Ifilter>({
        ..._initialFilter(),
    });
    /* Modal de reporte */
    const [isModalOpenDown, { setTrue: showModalDonw, setFalse: hideModalDown }] = useBoolean(false);

    /* Telerik */
    const [isOpenTelerik, { setTrue: openTelerik, setFalse: dismissTelerik }] = useBoolean(false);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    useEffect(() => {
        setTitleIngresoColumn();
        dataIngreso();
    }, []);

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);

    useEffect(() => {
        console.log('cambiando tab ' + tabIndex);
    }, [tabIndex])

    /* Api */
    const dataIngreso = (param = filter) => {
        setIngreso(_initialDataCollection);
        PersonaService.getListaPrograma(param).then(res => {
            setIngreso(res.data);
            setTotal(res.data.total);
            setHidden(true);
        }).catch((err) => {
            alert(err.message);
            setHidden(true);
        });
    }

    const dataSalida = (param = filter) => {
        setSalida(_initialDataCollection);
        PersonaService.getListaProgramaSalidas(param).then(res => {
            setSalida(res.data);
            setTotal(res.data.total);
            setHidden(true);
        }).catch((err) => {
            alert(err.message);
            setHidden(true);
        });
    }

    /* Function */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: Ifilter = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            eventTab(f);
        }
    };

    const eventTab = (param = filter) => {
        switch (tabIndex) {
            case '0':
                setTitleIngresoColumn();
                dataIngreso(param);
                break;
            case '1':
                setTitleSalida();
                dataSalida(param);
                break;
            default:
                break;
        }
    }

    const setup = () => {
        setHidden(false);
        switch (tabIndex) {
            case '0':
                dataIngreso();
                break;
            case '1':
                dataSalida();
                break;
            default:
                break;
        }
    };

    const setTitleIngresoColumn = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Programacion',
                fieldName: 'titulo',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isSortedDescending: false,
                data: 'string',
                isPadded: true,
            },
            {
                key: "column2",
                name: "Tipo",
                fieldName: "tipoPersonal",
                minWidth: 30,
                maxWidth: 30,
                isResizable: true,
                data: "string",
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Fecha Cierre',
                fieldName: 'fechaCierre',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
                onRender: (item: IProgramacion) => {
                    return <span style={{ color: ColorHelper.DANGER }}>{item.fechaCierre}</span>;
                },
            },
            {
                key: 'column4',
                name: 'Fecha Mov.',
                fieldName: 'fechaIngreso',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Fecha Bajada',
                fieldName: 'fechaSalida',
                minWidth: 140,
                maxWidth: 140,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            // {
            //     key: 'column5',
            //     name: 'Guardia',
            //     fieldName: 'guardia',
            //     minWidth: 200,
            //     maxWidth: 200,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: 'string',
            //     isPadded: true,
            // },
            {
                key: "column8",
                name: "Inscritos Bus",
                fieldName: "inscritos",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                isPadded: true,
            },
            {
                key: "column8.1",
                name: "Helo",
                fieldName: "inscritoHelo",
                minWidth: 20,
                maxWidth: 20,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                isPadded: true,
            },
            {
                key: "column7.1",
                name: "Estado",
                fieldName: "estado",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isMultiline: true,
                data: "string",
                isPadded: true,
                onRender: (item: IProgramacion) => (
                    <>
                        <State estado={item.estado} colorEstado={item.colorEstado}></State>
                    </>
                )
            },
            {
                key: "column9.1",
                name: "Usuario Reg.",
                fieldName: "usuarioRegistra",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                isPadded: true,
            },
            {
                key: "column9.2",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: IProgramacion) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            <TooltipHost content={`Detalle`}>
                                <Icon iconName={IconHelper.ICON_DETAIL.iconProps?.iconName}
                                    style={{ cursor: 'pointer' }}
                                    className={classNames.iconFont}
                                    onClick={() => navigate(`${ConfigHelper.urlBase()}/prueba.covid/${item.programacionId}`)}
                                />
                            </TooltipHost>
                        </Stack>
                    </>
                ),
            },
        ];

        setColumnIngreso(col);
    };

    const setTitleSalida = () => {
        let col: IColumn[] = [
            {
                key: 'column1',
                name: 'Programacion',
                fieldName: 'titulo',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                data: 'string',
                isPadded: true,
            },
            {
                key: "column2",
                name: "Tipo",
                fieldName: "tipoPersonal",
                minWidth: 30,
                maxWidth: 30,
                isResizable: true,
                sortAscendingAriaLabel: "Sorted A to Z",
                sortDescendingAriaLabel: "Sorted Z to A",
                data: "string",
                isPadded: true,
            },
            {
                key: 'column2.1',
                name: 'Fecha Cierre',
                fieldName: 'fechaCierre',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
                onRender: (item: IProgramacion) => {
                    return <span style={{ color: ColorHelper.DANGER }}>{item.fechaCierre}</span>;
                },
            },
            {
                key: 'column2.2',
                name: 'Fecha Mov.',
                fieldName: 'fechaIngreso',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Guardia',
                fieldName: 'guardia',
                minWidth: 200,
                maxWidth: 200,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: "column8",
                name: "Inscritos Bus",
                fieldName: "inscritos",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                isPadded: true,
            },
            {
                key: "column8.1",
                name: "Helo",
                fieldName: "inscritoHelo",
                minWidth: 20,
                maxWidth: 20,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                isPadded: true,
            },
            {
                key: "column7.1",
                name: "Estado",
                fieldName: "estado",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isMultiline: true,
                data: "string",
                isPadded: true,
                onRender: (item: IProgramacion) => (
                    <>
                        <State estado={item.estado} colorEstado={item.colorEstado}></State>
                    </>
                )
            },
            {
                key: "column9",
                name: "Usuario Reg.",
                fieldName: "usuarioRegistra",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                isPadded: true,
            },
            {
                key: "column6",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 90,
                maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: IProgramacion) => (
                    <>
                        <TooltipHost content={`Detalle`}>
                            <Icon iconName={IconHelper.ICON_DETAIL.iconProps?.iconName}
                                style={{ cursor: 'pointer' }}
                                className={classNames.iconFont}
                                // onClick={() => { navigate(`${ConfigHelper.urlBase()}/programaciones/${item.programacionId}`) }}
                                onClick={() => navigate(`${ConfigHelper.urlBase()}/prueba.covid/${item.programacionId}`)}
                            />
                        </TooltipHost>
                    </>
                ),
            },
        ];

        setColumnSalida(col);
    };

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_RELOAD,
                onClick: setup,
            },
        ];

        return _items;
    };

    const _rigthBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_EXCEL,
                onClick: openTelerik,
            },
            {
                ...IconHelper.ICON_INFO,
            },
        ];
        return _items;
    };

    const _onChangeText = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        if (newValue != undefined) {
            if (newValue === '') {
                loadBase();
            } else {
                setFilter({
                    ...filter,
                    search: newValue,
                })
            }
        }
    };

    const _onPress = (newValue: string) => {
        loadBase(newValue as string);
    };

    const _onChangeTab = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
        if (item != undefined) {
            let { itemKey } = item.props;

            if (itemKey != undefined) {
                setTabIndex(itemKey);

                let p = _initialPagination().currentPage;

                let f: Ifilter = {
                    ..._initialFilter(),
                    skip: (p - 1) * pageSize,
                    take: pageSize,
                    search: null,
                };

                setFilter(f);
                setCurrentPage(p);

                switch (itemKey) {
                    case '0':
                        setTitleIngresoColumn();
                        dataIngreso(f);
                        break;
                    case '1':
                        setTitleSalida();
                        dataSalida(f);
                        break;
                    default:
                        break;
                }
            }
        }
    }

    const _onClearSearch = () => {
        loadBase();
    };

    const loadBase = (text: string | null = null) => {
        let p = _initialPagination().currentPage;

        let f: Ifilter = {
            ..._initialFilter(),
            skip: (p - 1) * pageSize,
            take: pageSize,
            search: text,
        };

        setFilter(f);
        setCurrentPage(p);

        eventTab(f);
    }

    const descargaReporte = () => {
        showModalDonw()
        //Cambiar api de reporte 
        ProgramacionService.reporteProgramacion().then((x) => {
            console.log(x)
            saveAs(x.data, 'Reporte Programaciones Covid.xlsx');
            hideModalDown()
        })
    }

    return (
        <>
            <HeadingBar
                title='Prueba Covid'
                subTitle="Gestion de pruebas covid"
                searchChange={_onChangeText}
                searchHandler={_onPress}
                searchValue={filter.search ?? ''}
                onClearHandler={_onClearSearch}
            ></HeadingBar>

            <div style={{ padding: '20px' }}>
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <CustomCommandBar
                            item={_leftBar()}
                            farItem={_rigthBar()}
                            style={{ paddingRight: "0px", paddingLeft: "0px" }}
                        ></CustomCommandBar>
                    </div>
                </div>
                <Pivot aria-label="Basic Pivot Example" selectedKey={tabIndex} onLinkClick={_onChangeTab}>
                    <PivotItem itemKey='0'
                        headerText={TAB_SUBIDA}
                        headerButtonProps={{
                            'data-order': 1,
                            'data-title': 'My Files Title',
                        }}
                    >
                        <>
                            <TableBase column={columnIngreso} data={ingreso.items} style={ThemeBase.notPaddingTableBase} pagination={pager} isLoading={!hidden}></TableBase>

                            <Modal
                                isOpen={isModalOpenDown}
                                onDismiss={hideModalDown}
                                isBlocking={false}
                            >
                                <Label style={{ fontSize: '20pt', textAlign: 'center', margin: '0 0 50px 0' }}>Descargando Reporte</Label>
                                <Spinner
                                    label="Descargando datos excel"
                                    style={{ overflow: 'hidden', display: 'flex', justifyContent: 'center' }}
                                    size={SpinnerSize.large} />
                                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 20, minWidth: 450 }}>
                                    <TooltipHost
                                        content='Cancelar descarga'
                                    >
                                        <PrimaryButton
                                            text="Cancelar"
                                            iconProps={{ iconName: 'DrillDownSolid', style: { fontSize: '12pt' } }}
                                            onClick={() => {
                                                hideModalDown()
                                            }}
                                        />
                                    </TooltipHost>
                                </div>

                            </Modal>
                        </>

                    </PivotItem>
                    <PivotItem itemKey='1'
                        headerText={TAB_BAJADA}
                        headerButtonProps={{
                            'data-order': 2,
                            'data-title': 'My Files Title',
                        }}>

                        <TableBase column={columnSalida} data={salida.items} style={ThemeBase.notPaddingTableBase} pagination={pager} isLoading={!hidden}></TableBase>
                    </PivotItem>
                </Pivot>
            </div>

            {/* Panel - Reporte - Telerik */}
            <RepoteBaseGenerico
                headerName="Programaciones"
                NameReport="MOVReporteProgramacion.trdx"
                openTelerik={isOpenTelerik}
                dismissTelerik={dismissTelerik}
            ></RepoteBaseGenerico>
        </>
    );
}

function HeaderCargaPruebasC(props: any) {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    /* Modal de reporte */
    const [isModalOpenDown, { setTrue: showModalDonw, setFalse: hideModalDown }] = useBoolean(false);

    const [url, setUrl] = useState('');
    let navigate = useNavigate();

    const refresh = () => {
        navigate(`${ConfigHelper.urlBase()}/ProgExamenes`)
    }
    const agregar = () => {
        navigate(`${ConfigHelper.urlBase()}/programaciones/crear`)
    }
    const botonesProps: Partial<IButtonStyles> = { root: { minWidth: 50 } }
    const descargaReporte = () => {
        /* showModalDonw()
        //Cambiar api de reporte 
        PersonaService.reporteAccesoProgramado('').then((x) => {
            console.log(x)
            saveAs(x.data, 'Reporte.xlsx');
            hideModalDown()
        }) */
    }

    return (
        <>
            <div style={{ marginBottom: '20px', height: '80px', borderBottom: '1px solid gray' }}>
                <div style={{
                    height: '80px',
                    display: "grid",
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridColumnGap: '10px', alignItems: 'center'
                }}>
                    <div style={{ alignItems: 'center' }} >
                        <div style={{ fontSize: '20pt', color: 'gray' }}>PRUEBAS COVID</div>
                        <span style={{ color: 'gray', fontSize: '10pt' }}>Gestion de pruebas covid</span>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <IconButton
                            iconProps={{ iconName: 'Search' }}
                        />
                        <TextField
                            placeholder="Buscar"
                            style={{ width: '100%' }}
                            styles={{ root: { width: '100%' } }}
                            underlined
                        />
                    </div>
                    <div style={{ padding: '10px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <TooltipHost
                                content='Atras'
                            >
                                <PrimaryButton
                                    iconProps={{ iconName: 'Refresh', style: { fontSize: '12pt' } }}
                                    onClick={refresh}
                                    styles={botonesProps}
                                />

                            </TooltipHost>
                            <TooltipHost
                                content='Atras'
                            >
                                <PrimaryButton
                                    iconProps={{ iconName: 'download', style: { fontSize: '12pt' } }}
                                    onClick={descargaReporte}
                                    styles={botonesProps}
                                />

                            </TooltipHost>

                            <TooltipHost
                                content='Cargar Archivo'
                            >
                                <PrimaryButton
                                    iconProps={{ iconName: 'add' }}
                                    styles={botonesProps}
                                    onClick={agregar}
                                />
                            </TooltipHost>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                // titleAriaId={titleId}
                isOpen={isModalOpenDown}
                onDismiss={hideModalDown}
                isBlocking={false}
            >
                <Label style={{ fontSize: '20pt', textAlign: 'center', margin: '0 0 50px 0' }}>Descargando Reporte</Label>
                <Spinner
                    label="Descargando datos excel"
                    style={{ overflow: 'hidden', display: 'flex', justifyContent: 'center' }}
                    size={SpinnerSize.large} />
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 20, minWidth: 450 }}>
                    <TooltipHost
                        content='Cancelar descarga'
                    >
                        <PrimaryButton
                            text="Cancelar"
                            iconProps={{ iconName: 'DrillDownSolid', style: { fontSize: '12pt' } }}
                            onClick={() => {
                                hideModalDown()
                            }}
                            styles={botonesProps}
                        />
                    </TooltipHost>
                </div>

            </Modal>
        </>
    );
}

const theme = getTheme();

//======================================================================
export interface IDetailsListDocumentsExampleState {
    columns: IColumn[];
    items: IDocument[];
    selectionDetails: string;
    isModalSelection: boolean;
    isCompactMode: boolean;
    announcedMessage?: string;
    opAmin?: string;
    hiden?: boolean;
}
export interface IDocument {
    key: string;
    titulo: string;
    inicioProgramacion: string;
    finProgramacion: string;
    fechaIngreso: string;
    inscritos: string;
    fechaSalida: string;
    guardia: string;
    estado: string;
    fechaCierre: string;
}

export class TablaProgExamenes extends React.Component<{}, IDetailsListDocumentsExampleState> {
    private _selection: Selection;
    private _allItems: IDocument[];
    constructor(props: {}) {
        super(props);
        const columns: IColumn[] = [
            {
                key: 'column1',
                name: 'Programacion',
                fieldName: 'titulo',
                minWidth: 120,//210
                // maxWidth: 350,//250
                // isRowHeader: true,
                isResizable: true,
                isSorted: true,
                isSortedDescending: false,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onColumnClick: this._onColumnClick,
                data: 'string',
                isPadded: true,
                flexGrow: 1,
            },
            {
                key: 'column2',
                name: 'Fecha de Ingreso',
                fieldName: 'fechaIngreso',
                minWidth: 120,
                // maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.fechaIngreso}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column3',
                name: 'Fecha Cierre',
                fieldName: 'fechaCierre',
                minWidth: 120,
                // maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IProgramacion) => {
                    return <span style={{ color: ColorHelper.DANGER }}>{item.fechaCierre}</span>;
                },
                isPadded: true,
            },
            {
                key: 'column4',
                name: 'Salida',
                fieldName: 'fechaSalida',
                minWidth: 150,
                // maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.fechaSalida}</span>;
                },
            },
            {
                key: 'column5',
                name: 'Régimen',
                fieldName: 'guardia',
                minWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.guardia}</span>;
                },
            },
            {
                key: 'column6',
                name: 'Estado',
                fieldName: 'estado',
                minWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.estado}</span>;
                },
            },
            {
                key: 'column7',
                name: 'Inscritos',
                fieldName: 'inscritos',
                minWidth: 100,
                // maxWidth: 90,
                isResizable: true,
                isCollapsible: true,
                data: 'number',
                onColumnClick: this._onColumnClick,
                onRender: (item: IDocument) => {
                    return <span>{item.inscritos}</span>;
                },
            },
            {
                key: "column8",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 100,
                // maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                // flexGrow: 1/100,
                onColumnClick: this._onColumnClick,

                onRender: (item: IDocument) => (

                    <>
                        <TooltipHost content={`Detalle`}>{/* ${item.fileType}  */}
                            <Link href={`${ConfigHelper.urlBase()}/ProgExamenes/${item.key}`}>
                                <Icon
                                    iconName="ContactList"
                                    className={classNames.iconFont}
                                    style={{ color: 'blueviolet' }}
                                />
                            </Link>
                        </TooltipHost>
                    </>
                ),
            },
        ];
        //PersonaService.getListaPersonas(10).then(response => console.log(response.data.data))
        this._allItems = this._Load();
        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({
                    selectionDetails: this._getSelectionDetails(),
                });
            },
        });
        this.state = {
            items: this._allItems,
            columns: columns,
            selectionDetails: this._getSelectionDetails(),
            isModalSelection: false,
            isCompactMode: false,
            announcedMessage: undefined,
            opAmin: "CircleFill",
            hiden: false,
        };
    }

    public render() {
        const { columns, isCompactMode, items, hiden } = this.state;
        return (
            <>
                <DetailsList
                    items={items}
                    compact={isCompactMode}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    getKey={this._getKey}
                    setKey="single"
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    isHeaderVisible={true}
                    selection={this._selection}
                    onItemInvoked={this._onItemInvoked}
                    onRenderRow={this._onRenderRow}
                    styles={{
                        headerWrapper: { margin: '0 0 15px 0', boxShadow: theme.effects.elevation8 },
                        root: { overflowX: 'auto' },
                    }}
                />
                <SpinnerCenter isLoad={!hiden} />

            </>
        );
    }

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            customStyles.root = { margin: '0 0 10px 0', boxShadow: theme.effects.elevation8 };
            return <DetailsRow  {...props} styles={customStyles} />;
        }
        return null;
    };

    public componentDidUpdate(previousProps: any, previousState: IDetailsListDocumentsExampleState) {
        if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
            this._selection.setAllSelected(false);
        }
    }

    private _getKey(item: any, index?: number): string {
        return item.key;
    }

    private _onChangeCompactMode = (ev: React.MouseEvent<HTMLElement>, checked?: boolean): void => {
        if (checked != undefined) {
            this.setState({ isCompactMode: checked });
        }
    };

    private _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement, MouseEvent>, checked?: boolean): void => {
        if (checked != undefined) {
            this.setState({ isModalSelection: checked });
        }
    };

    private _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text?: string): void => {
        if (text != undefined)
            this.setState({
                items: text ? this._allItems.filter((i) => i.titulo.toLowerCase().indexOf(text) > -1) : this._allItems,
            });
    };

    private _onItemInvoked(item: any): void {
        alert(`Item invoked: ${item.name}`);
    }

    private _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();

        switch (selectionCount) {
            case 0:
                return 'No items selected';
            case 1:
                return '1 item selected: ' + (this._selection.getSelection()[0] as IDocument).titulo;
            default:
                return `${selectionCount} items selected`;
        }
    }

    private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
        const { columns, items } = this.state;
        const newColumns: IColumn[] = columns.slice();
        const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
                this.setState({
                    announcedMessage: `${currColumn.name} is sorted ${currColumn.isSortedDescending ? 'descending' : 'ascending'
                        }`,
                });
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
    };

    private _Load() {
        const items: IDocument[] = [];
        this.setState({ hiden: false })
        PersonaService.getListaPrograma({ skip: 0, take: 50, search: '' }).then(response => {
            var pesonasL = response.data.data
            pesonasL.map((resp: any) => {
                items.push({
                    key: resp.programacionId,
                    titulo: resp.titulo,
                    inicioProgramacion: resp.fechaInicioProgramacion,
                    finProgramacion: resp.fechaFinProgramacion,
                    fechaIngreso: resp.fechaIngreso,
                    inscritos: resp.inscritos,
                    fechaSalida: resp.fechaSalida,
                    guardia: resp.guardia,
                    estado: resp.estado,
                    fechaCierre: resp.fechaCierre,
                });
            })

            this.setState({ hiden: true })
            console.log(response.data.data)
        })
        return items;
    }
}

const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
        padding: 0,
        fontSize: '16px',
    },
    iconFont: {
        padding: 0,
        fontSize: "16px",
        marginRight: 5,
    },
    fileIconCell: {
        textAlign: 'center',
        selectors: {
            '&:before': {
                content: '.',
                display: 'inline-block',
                verticalAlign: 'middle',
                height: '100%',
                width: '0px',
                visibility: 'hidden',
            },
        },
    },
    fileIconImg: {
        verticalAlign: 'middle',
        maxHeight: '16px',
        maxWidth: '16px',
    },
    controlWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    exampleToggle: {
        display: 'inline-block',
        marginBottom: '10px',
        marginRight: '30px',
    },
    selectionDetails: {
        marginBottom: '20px',
    },
    contenedor: {
        width: '100%',
    },
});