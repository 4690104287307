import { fetchUrlTelerikDescarga } from "./http-common";

const exportaReporte = (data = {}) => {
    return fetchUrlTelerikDescarga.post(`/v1/ExportarReporte`,
        //responsetype: "blob", params: Object.entries(data)
        //.filter(([_, value]) => value !== "")
        //.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
        data
    );
}

const reporteServicio = {
    exportaReporte,
}

export default reporteServicio;