import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  getTheme,
  IColumn, ICommandBarItemProps, Icon, IconButton, ITooltipHostStyles, Label, Link, Modal,
  Persona,
  PersonaSize,
  PrimaryButton, Spinner, SpinnerSize, Stack, TooltipHost
} from "@fluentui/react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useBoolean } from '@fluentui/react-hooks';
import { GenButtonAdd } from "../../components/Generic/botonesCrear";
import { useEffect, useState } from "react";
import React from "react";
import PersonaService from "../../services/PersonaService";
import { IFilterProgramacion, IProgramacionPersona, IValidarPruebaCovid } from "../../interfaces/IProgramacion";
import saveAs from "file-saver";
import { HeadingBar } from "../../components/Generic/HeadingBar";
import { baseImageUrl, baseSearch, downloadBlob, stackTokens, stackTokensHorizontal, _initialFilter, _initialPagination } from "../../utils/Helper/helper";
import ProgramacionPersonaService from "../../services/programacionPersonaService";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { TableBase } from "../../components/Table/TableBase";
import ThemeBase from "../../utils/Theme/themeBase";
import { BlobHelper } from "../../utils/Helper/blobHelper";
import RepoteBase from "../../components/Generic/ReporteBase";
import { IDataCollection, IPagination } from "../../interfaces/base/ITable";
import { _initialDataCollection } from "../../utils/Helper/tableHelper";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import RepoteBaseGenerico from "../../components/Generic/ReporteBaseGenerico";
import blobStorageService from "../../services/blobStorageService";

const theme = getTheme();
interface ArchivoCovidProps {
  cancelar?: any;
  guardarHiden?: any;
}
interface IfileDetail {
  nombre: string,
  tamanio: string,
  tipo: string,
}

const ArchivoCovid: React.FunctionComponent<ArchivoCovidProps> = (props: any) => {
  const [fileDetail, setfileDetail] = useState<IfileDetail>();
  const [url, setUrl] = useState('');
  const [archivoExcel, setarchivoExcel] = useState<FormData>();
  const [isSend, setIsSend] = useState(false);
  let { id } = useParams()

  const _onChangeSeleccion = (e: any) => {
    const fileList: FileList = e.target.files;

    if (fileList.length > 0) {
      const file: File = fileList[0];
      setUrl(URL.createObjectURL(file))

      var formData = new FormData();
      formData.append('file', file, file.name);

      setarchivoExcel(formData)

      setfileDetail({
        nombre: fileList[0].name,
        tamanio: `${fileList[0].size}`,
        tipo: fileList[0].type,
      })
    }
  };

  useEffect(() => {
    console.log("====Parametros Envio====")
    console.log(archivoExcel)
    console.log(id)
    console.log("========")
  }, [archivoExcel]);

  const _enviarPruebas = () => {
    setIsSend(true)
    PersonaService.putCargarPruebasCovid(archivoExcel, id?.toString()).then(() => {
      console.log("Pruebas Covid Cargadas")
      setIsSend(false)
      props.guardarHiden()
    }).catch((e) => {
      console.log(e)
      setIsSend(false)
    })
  }

  const obtenerblob = (listUrlBase: any) => {
    blobStorageService.cargarblobstorage(listUrlBase.url)
    .then((res) => {
      const blobUrl = res.data;

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${listUrlBase.nombre}.${listUrlBase.extension}`;
      link.click();
    })
    .catch((e) => {
      console.log("Esto es error")
    });
  };

  const _PlantillaCargaPruebas = () => {
    //downloadBlob(BlobHelper.urlCargaPruebaCovid);
    obtenerblob(BlobHelper.urlCargaPruebaCovid);
  }

  const TextL = () => {
    const estilo = {
      padding: 2,
      fontSize: "11pt",
      color: theme.palette.blueLight,
      margin: '5px 0 0 0'
    }
    return (
      <>
        <div style={{ display: 'block' }}>
          <Link href={url} onClick={_PlantillaCargaPruebas}>
            <Label style={estilo}>
              <Icon iconName="DownloadDocument" color={theme.palette.blueLight} style={{ margin: '0 5px 0 5px' }} />
              Descargar plantilla excel
            </Label>
          </Link>
        </div>
      </>
    )
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Icon iconName="ExcelDocument" style={{ fontSize: '50pt' }} />
      </div>
      <Label
        style={{
          borderBottom: "1px solid #ccc",
          textAlign: 'center',
          padding: 2,
          fontSize: "10pt",
          color: "gray",
          margin: '10px 0 0 0'
        }}>Carga masiva de pruebas Covid
      </Label>
      <br />
      <div style={{ display: 'flex', justifyContent: 'start' }}>
        <div>
          <Label
            className="custom-file-upload"
            style={{
              border: "1px solid #ccc",
              display: "inline-block",
              padding: "6px 12px",
              cursor: "pointer",
              maxWidth: 190,
              minWidth: 190,
              textAlign: 'center',
              margin: '10px 0 0 0'
            }}>
            <input
              type="file"
              multiple
              onChange={_onChangeSeleccion}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              style={{ display: "none", textDecoration: "none" }}
            />
            Seleccionar
          </Label>
        </div>
      </div>
      <br />
      <div style={{
        textAlign: 'center',
        minWidth: 450,
        maxWidth: 500,
        width: '100%',
        minHeight: 5,
        boxShadow: theme.effects.elevation4,
      }}>

        {url ? (
          <div
            style={{
              textAlign: 'center',
              minWidth: 300,
              maxWidth: 500,
              width: '100%',
              minHeight: 5,
              boxShadow: theme.effects.elevation4,
              // border: '1px solid rgba(0, 0, 0, 0.3)',
            }}
          >
            {/* {url} */}
            {/* {this.props.programaId} */}
            <Icon iconName="ExcelDocument" className={theme.palette.greenLight} style={{
              fontSize: 50,
              height: 50,
              width: 50,
              margin: '0 25px',
            }} />
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
              <br />
              Nombre: {fileDetail?.nombre}
              <br />
              Tamaño: {fileDetail?.tamanio}
            </div>
          </div>
        ) : (
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              minHeight: 5,
              height: '80px',
              boxShadow: theme.effects.elevation4,
              fontSize: '9pt',
              minWidth: 450,
              maxWidth: 500,
            }}
          >
            Detalles de Archivo
            <TextL />
          </div>
        )}
      </div>
      <div style={{ padding: 20, minWidth: 450 }}>
        <GenButtonAdd
          cancelar={props.cancelar}
          text={'CARGAR'}
          guardar={_enviarPruebas}
        />
      </div>
      <Modal
        isOpen={isSend}
        // onDismiss={hideModal}
        isBlocking={false}
      >
        <Label style={{ margin: 10 }}>Cargando Datos</Label>
        <Spinner
          label="Enviando datos excel"
          style={{ overflow: 'hidden', display: 'flex', justifyContent: 'center' }}
          size={SpinnerSize.large} />
      </Modal>
    </>
  );
}

//======================================================================
export interface IDocument {
  key: string;
  foto: any;
  nombres: string;
  nroDOI: string;
  empresa: string;
  puesto: string;
  email: string;
  fechaCovid: string;
  pruebaCovid: string;
}

export const DetalleProgramacionPruebaCovid = () => {
  const navigate = useNavigate();
  let { id } = useParams()

  const [hidden, setHidden] = useState(false);
  const [column, setColumn] = useState<IColumn[]>([]);
  const [data, setData] = useState<IDataCollection<IProgramacionPersona>>(_initialDataCollection);

  // MODAL DE CARGA
  const [isModalOpenDown, { setTrue: showModalDonw, setFalse: hideModalDown }] = useBoolean(false);

  // MODAL DE CARGA
  const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

  const [filter, setFilter] = useState<IFilterProgramacion>({
    skip: 0,
    take: 50,
    search: null,
    programacionId: null
  });

  /* Dialog */
  const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);
  const [validarCovidId, setValidarCovidId] = useState<string>(null!);
  /* Panel Telerik */
  const [isOpenTelerik, { setTrue: openTelerik, setFalse: dismissTelerik }] = useBoolean(false);

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);

  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => { dataByPager(page) },
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });

  /* useEffect */
  useEffect(() => {
    setTitleColumn();
    dataPersonas();
  }, []);

  /* Hook Pagination */
  useEffect(() => {
    setPager({
      ...pager,
      currentPage: currentPage,
    });

    if (filter) {
      setFilter({
        ...filter,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setPager({
      ...pager,
      total: total,
    });
  }, [total]);

  /* Function */
  const dataByPager = (page) => {
    if (true) {
      setTotal(0);

      setPager({
        ...pager,
        currentPage: page,
      });

      let f: IFilterProgramacion = {
        ...filter,
        skip: (page - 1) * pageSize,
        take: pageSize,
      }

      setFilter(f);
      setCurrentPage(page);

      dataPersonas(f);
    }
  }

  const setTitleColumn = () => {
    let col: IColumn[] = [
      {
        key: 'column1',
        name: 'Foto',
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        isIconOnly: true,
        fieldName: 'foto',
        minWidth: 45,
        maxWidth: 45,
        onRender: (item: IProgramacionPersona) => (
          <TooltipHost content={`Foto`}>
            <Persona
              text=''
              size={PersonaSize.size24}
              imageUrl={baseImageUrl}
            />
          </TooltipHost>
        ),
      },
      {
        key: 'column2',
        name: 'Nombre',
        fieldName: 'nombres',
        minWidth: 160,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        data: 'string',
        isPadded: true,
        onRender: (item: IProgramacionPersona) => {
          return <span style={{ fontSize: '90%', textDecorationLine: item.motivoRetiro == '' ? 'none' : 'line-through' }}>{item.nombres}</span>
        },
      },
      {
        key: 'column3',
        name: 'Identificación',
        fieldName: '',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        onRender: (item: IProgramacionPersona) => {
          return <span>{`${item.tipoDocumento}: ${item.nroDOI}`}</span>
        },
      },
      {
        key: "column3.1",
        name: "Origen",
        fieldName: "origen",
        minWidth: 90,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column3.2",
        name: "Destino",
        fieldName: "destino",
        minWidth: 90,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      // {
      //   key: 'column5',
      //   name: 'Estado',
      //   fieldName: 'estadoActual',
      //   minWidth: 140,
      //   maxWidth: 150,
      //   isResizable: true,
      //   isCollapsible: true,
      //   data: 'number',
      // },
      {
        key: "column15",
        name: "Validar",
        fieldName: "opciones",
        minWidth: 90,
        maxWidth: 120,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: IProgramacionPersona) => (
          <>
            <Stack horizontal tokens={stackTokensHorizontal}>
              {item.motivoRetiro == '' &&
                <TooltipHost content={`Validar Prueba Covid`}>
                  <Icon iconName='TestBeaker'
                    style={{ cursor: 'pointer' }}
                    className={ThemeBase.classes.iconFont}
                    onClick={() => {
                      console.log(item.programacionPersonaId);
                      setValidarCovidId(item.programacionPersonaId);
                      toggleHideDialog()
                    }
                    }
                  />
                </TooltipHost>
              }
            </Stack>
          </>
        ),
      },
      {
        key: 'column6',
        name: 'Prueba Covid',
        fieldName: 'pruebaCovid',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        onRender: (item: IProgramacionPersona) => {
          // return <span>{`${item.pruebaCovid !== null ? (item.pruebaCovid ? 'Apto' : 'No Apto') : ''}`}</span>
          return <span>{`${item.resultadoPrueba !== null ? item.resultadoPrueba : ''}`}</span>
        },
      },
      {
        key: 'column7',
        name: 'Fecha Covid',
        fieldName: 'fCovid',
        minWidth: 100,
        maxWidth: 110,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
      },
      {
        key: 'column8',
        name: 'Motivo Retiro',
        fieldName: 'motivoRetiro',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'number',
      },
      {
        key: 'column9',
        name: 'Email',
        fieldName: 'email',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
      },
      {
        key: 'column10',
        name: 'Celular',
        fieldName: 'celular',
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column11',
        name: 'Fecha Salida',
        fieldName: 'fechaSalida',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'number',
      },
      {
        key: 'column12',
        name: 'Empresa',
        fieldName: 'empresa',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
      },
      // {
      //   key: 'column13',
      //   name: 'Hotel',
      //   fieldName: 'hotel',
      //   minWidth: 70,
      //   maxWidth: 90,
      //   isResizable: true,
      //   isCollapsible: true,
      //   data: 'string',
      // },
      // {
      //   key: 'column4',
      //   name: 'Hora Llega Vuelo',
      //   fieldName: 'horaLlega',
      //   minWidth: 200,
      //   maxWidth: 200,
      //   isResizable: true,
      //   isCollapsible: true,
      //   data: 'number',
      // },
      {
        key: 'column4',
        name: 'Proceso - Estado',
        fieldName: 'nivelActual',
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: 'number',
      },
      {
        key: 'column14',
        name: 'Registrado por',
        fieldName: 'nombreUsuarioRegistra',
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
      },
      // {
      //   key: 'column15',
      //   name: 'Validado por',
      //   fieldName: 'nombreUsuarioValida',
      //   minWidth: 70,
      //   maxWidth: 90,
      //   isResizable: true,
      //   isCollapsible: true,
      //   data: 'string',
      // },
    ];

    setColumn(col);
  }

  /* api */
  const dataPersonas = (param = filter) => {
    setData(_initialDataCollection);
    return PersonaService.getAccesoPersona(`${id}`, param).then(response => {
      if (response.status == 200) {
        setData(response.data);
        setTotal(response.data.total);
        setHidden(true);
      }
    }).catch((e: Error) => {
      alert(e);
      setHidden(true);
    });
  }

  const _setup = () => {
    setHidden(false);
    dataPersonas();
  }

  const _descargaReporte = () => {
    showModalDonw()
    PersonaService.reporteCovid(id).then((x) => {
      console.log(x)
      saveAs(x.data, 'Reporte Pruebas Covid.xlsx');
      hideModalDown()
    })
  }

  const validarPruebaCovid = (resultado: string) => {
    let d: IValidarPruebaCovid = {
      accesoPersonaId: validarCovidId,
      codigoResultado: resultado
    };

    return ProgramacionPersonaService.validarPruebaCovid(d).then(res => {
      if (res.status == 200) {
        toggleHideDialog();
        _setup();
      }
    }).catch((e: Error) => {
      alert(e);
      setHidden(true);
    });
  }

  /* Function */
  const goBack = () => {
    navigate(`${ConfigHelper.urlBase()}/prueba.covid`);
  }

  const _leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_BACK,
        onClick: goBack,
      },
      {
        ...IconHelper.ICON_RELOAD,
        onClick: _setup,
      },
      {
        ...IconHelper.ICON_UPLOAD,
        text: 'Carga Masiva',
        onClick: showModal,
      },
    ];

    return _items;
  }

  const _rigthBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_EXCEL,
        onClick: openTelerik,
      },
      {
        ...IconHelper.ICON_INFO,
        onClick: () => { alert('info') },
      },
    ];

    return _items;
  }

  const _onChangeText = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
    if (newValue != undefined) {
      if (newValue === '') {
        loadBase();
      } else {
        setFilter({
          ...filter,
          search: newValue,
        })
      }
    }
  };

  const loadBase = (text: string | null = null) => {
    let p = _initialPagination().currentPage;

    let f: IFilterProgramacion = {
      ..._initialFilter(),
      programacionId: id,
      skip: (p - 1) * pageSize,
      take: pageSize,
      search: text,
    };

    setFilter(f);
    setCurrentPage(p);

    dataPersonas(f);
  }

  const _onPress = (newValue: string) => {
    loadBase(newValue);
  };

  /* Dialog */
  const hostStyles: Partial<ITooltipHostStyles> = { root: { display: 'inline-block', color: 'red' } };
  const modalPropsStyles = { main: { maxWidth: 500 } };
  const dialogContentProps = {
    type: DialogType.normal,
    title: 'Validar Prueba Covid',
    subText: '¿Está seguro?, se validará el registro del colaborador con la opción marcada',
  };

  const modalProps = React.useMemo(
    () => ({
      isBlocking: true,
      styles: modalPropsStyles,
    }),
    [validarCovidId],
  );

  /* Event Filter */
  const _onClearSearch = () => {
    loadBase();
  };

  return (
    <div>
      <HeadingBar
        title='Validación Prueba Covid'
        subTitle='Unidad Médica'
        searchChange={_onChangeText}
        searchHandler={_onPress}
        searchValue={filter.search ?? ''}
        onClearHandler={_onClearSearch}
      ></HeadingBar>

      <>
        <TableBase leftBar={_leftBar()} rigthBar={_rigthBar()} column={column} data={data.items} pagination={pager} isLoading={!hidden}></TableBase>

        <Dialog
          hidden={hideDialog}
          onDismiss={toggleHideDialog}
          dialogContentProps={dialogContentProps}
          modalProps={modalProps}
        >
          <div className='ms-Grid-row'>
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
              <DefaultButton text='SR' onClick={() => validarPruebaCovid('04')}></DefaultButton>
            </div>

            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
              <DefaultButton text='Apto' onClick={() => validarPruebaCovid('02')}></DefaultButton>
            </div>

            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg4">
              <DefaultButton text='No Apto' onClick={() => validarPruebaCovid('03')} theme={ThemeBase.DangerButtonTheme}></DefaultButton>
            </div>
          </div>

          <DialogFooter>
            <DefaultButton onClick={toggleHideDialog} text='Cancelar' />
          </DialogFooter>
        </Dialog>
      </>

      {/* Modal Carga Masiva Covid */}
      <Modal
        isOpen={isModalOpen}
        onDismiss={hideModal}
        isBlocking={false}
      >
        <div style={{ padding: 20, minWidth: 450 }}>
          <ArchivoCovid
            cancelar={hideModal}
            guardarHiden={() => { hideModal(); _setup() }}
          />
        </div>
      </Modal>

      {/* Modal Reporte */}
      <Modal
        isOpen={isModalOpenDown}
        onDismiss={hideModalDown}
        isBlocking={false}
      >
        <Label style={{ fontSize: '20pt', textAlign: 'center', margin: '0 0 50px 0' }}>Descargando Reporte</Label>
        <Spinner
          label="Descargando datos excel"
          style={{ overflow: 'hidden', display: 'flex', justifyContent: 'center' }}
          size={SpinnerSize.large} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 20, minWidth: 450 }}>
          <TooltipHost
            content='Cancelar descarga'
          >
            <PrimaryButton
              text="Cancelar"
              iconProps={{ iconName: 'DrillDownSolid', style: { fontSize: '12pt' } }}
              onClick={() => {
                hideModalDown()
              }}
            // styles={botonesProps}
            />
          </TooltipHost>
        </div>

      </Modal>
      <RepoteBaseGenerico
        // headerName="Validación Prueba Covid"
        NameReport="MOVProgramadoPersonaUM.trdx"
        Parameters={{
          ProgramacionId: id
        }}
        openTelerik={isOpenTelerik}
        dismissTelerik={dismissTelerik}
      ></RepoteBaseGenerico>
    </div>
  )
}