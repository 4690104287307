import { ComboBox, IColumn, IComboBox, IComboBoxOption, ICommandBarItemProps, Icon, IconButton, Modal, ProgressIndicator, Stack, TextField, TooltipHost } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TableBase } from "../../../components/Table/TableBase"
import { IBase, IException, IItemComboBaseDTO, IMotivoEliminacion } from "../../../interfaces/base/IBase";
import { IDataCollection, IPagination } from "../../../interfaces/base/ITable";
import { IFilterAsignacion } from "../../../interfaces/IProgramacion";
import { IEliminarProgramacionBus, IRegistrarRuta, ITransporteRuta } from "../../../interfaces/IProgTransporte";
import PersonaService from "../../../services/PersonaService";
import { ItemNoneSelect, modalCancelIcon, modalIconButtonStyles, modalStyle, splitExceptionDetail, stackTokens, stackTokensHorizontal, _initialFilter, _initialPagination } from "../../../utils/Helper/helper";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../../utils/Helper/tableHelper";
import ThemeBase from "../../../utils/Theme/themeBase"
import { useBoolean } from "@fluentui/react-hooks";
import RepoteBase from "../../../components/Generic/ReporteBase";
import { ButtonForm } from "../../../components/Generic/buttonForm";
import TransporteService from "../../../services/transporteService";
import ProgramacionService from "../../../services/ProgramacionService";
import { ModuloHelper } from "../../../utils/Helper/moduloHelper";
import { CrearRutaProgramacionBus } from "./crearRutaProgramacionBus";
import { MessageHelper } from "../../../utils/Helper/messageHelper";
import { AsignacionMultipleTransporte } from "./asignacionMultipleTransporte";
import { ConfigHelper } from "../../../utils/Helper/configHelper";
import { color } from "d3";
import { ColorHelper } from "../../../utils/Helper/colorHelper";
import { DetalleTransporteGrupo } from "./detalleTransporteGrupo";
import RepoteBaseGenerico from "../../../components/Generic/ReporteBaseGenerico";

export const ListarTransporteAsignado = ({ programacionId = '' }) => {
    const navigate = useNavigate();
    const [hidden, setHidden] = useState(false);

    const [filter, setFilter] = useState<IFilterAsignacion>({
        ..._initialFilter(),
        programacionId: programacionId,
    });

    /* Data & Column */
    const [data, setData] = useState<IDataCollection<ITransporteRuta>>(_initialDataCollection);
    const [column, setColumn] = useState<IColumn[]>([]);

    /* Opciones Dialog */
    const [selAccesoBusId, setSelAccesoBusId] = useState<string | undefined>(undefined);
    const [selItemTransporte, setSelItemTransporte] = useState<ITransporteRuta | undefined>(undefined);

    /* Panel Asignar Ruta */
    const [isOpenAsignar, { setTrue: openAsignar, setFalse: dismissAsignar }] = useBoolean(false);
    const [isOpenDetalleGrupo, { setTrue: openDetalleGrupo, setFalse: dismissDetalleGrupo }] = useBoolean(false);

    const [isOpenTransporteMasivo, { setTrue: openTransporteMasivo, setFalse: dismissTransporteMasivo }] = useBoolean(false);

    /* Panel manifiesto */
    const [isOpenTelerik, { setTrue: openTelerik, setFalse: dismissTelerik }] = useBoolean(false);

    /* Modal Registrar Transporte */
    const [transporte, setTransporte] = useState<IItemComboBaseDTO[]>([]);
    const [isOpenAsignarTransporte, setIsOpenAsignarTransporte] = useState(false);
    const [modelTransporte, setModelTransporte] = useState<IRegistrarRuta>(null!);

    /* Modal Eliminar Transporte */
    const [isEliminarTransporte, { setTrue: showEliminarTransporte, setFalse: dismissEliminarTransporte },] = useBoolean(false);
    const [motivoEliminacion, setMotivoEliminacion] = useState<IMotivoEliminacion[]>([]);
    const [formEliminar, setFormEliminar] = useState<IEliminarProgramacionBus>(null!);
    const [isLoadingEliminar, { setTrue: loadingEliminar, setFalse: dismissEliminar }] = useBoolean(false);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    /* Hook */
    useEffect(() => {
        if (programacionId != null) {
            reloadInitial();

            setModelTransporte({
                ...modelTransporte,
                programacionId: programacionId
            })
        }
    }, []);

    useEffect(() => {
        if (isEliminarTransporte) {
            dataMotivoEliminacion();
        }
    }, [isEliminarTransporte]);

    useEffect(() => {
        if (isOpenAsignarTransporte) {
            comboTransporte();
        }
    }, [isOpenAsignarTransporte]);

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);

    /* Api */
    const dataTransporte = (param = filter) => {
        setHidden(true);

        PersonaService.getTableTransporteRuta(param)
            .then((res) => {
                setData(res.data);
                setTotal(res.data.total);
                setHidden(false);
            })
            .catch((e: IException) => {
                console.log(splitExceptionDetail(e));
                setHidden(false);
            });
    }

    const comboTransporte = () => {
        PersonaService.getComboTransportes()
            .then((res) => {
                if (res.status == 200) {
                    let d: IBase[] = res.data.data;
                    let t: IItemComboBaseDTO[] = [];

                    t = d.map((x) => {
                        return {
                            key: x.id,
                            text: x.nombre,
                        };
                    });

                    t.unshift(ItemNoneSelect());

                    setTransporte(t);
                }
            })
            .catch((e) => {
                alert(e.message);
            });
    };

    const dataMotivoEliminacion = () => {
        ProgramacionService.allMotivoEliminacion(ModuloHelper.programacionBus)
            .then((res: any) => {
                if (res.status == 200) {
                    setMotivoEliminacion(res.data);

                    let t: IMotivoEliminacion[] = res.data;
                    let d = t.find(x => x.defecto);

                    setFormEliminar({
                        ...formEliminar,
                        motivoEliminacionId: d ? d.key : '',
                    })
                }
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    const asignarTransporte = () => {
        PersonaService.postTransporteParadero(modelTransporte)
            .then((response) => {
                if (response.status == 200) {
                    reloadInitial();
                    setIsOpenAsignarTransporte(false);
                    alert('Se registró correctamente');
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const eliminarRuta = () => {
        loadingEliminar();
        TransporteService.EliminarRuta(formEliminar)
            .then((response) => {
                if (response.status == 200) {
                    reloadInitial();
                    dismissEliminarTransporte();
                    dismissEliminar();
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
                dismissEliminar();
            });
    };

    /* Function */
    const reloadInitial = (param = filter) => {
        setHidden(false);
        setTitleColumn();
        dataMotivoEliminacion();
        dataTransporte(param);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_BACK,
                onClick: () => {
                    navigate(`${ConfigHelper.urlBase()}/empresa.transporte`);
                },
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial(),
            },
            // {
            //     ...IconHelper.ICON_ADD,
            //     onClick: () => {
            //         setIsOpenAsignarTransporte(true);
            //     },
            // },
            {
                ...IconHelper.ICON_ADD,
                text: 'Agregar',
                onClick: () => {
                    openTransporteMasivo();
                },
            },
        ];

        return _items;
    };

    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: "column1",
                name: "Placa",
                fieldName: "transporte",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                isPadded: true,
                onRender: (item: ITransporteRuta) => {
                    return (
                        <span style={{ textDecorationLine: item.deleted == "" ? "none" : "line-through" }}>{item.transporte}</span>
                    );
                },
            },
            {
                key: "column2",
                name: "Cód. Interno",
                fieldName: "codigoInterno",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column3",
                name: "Tipo Vehículo",
                fieldName: "tipo",
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column4",
                name: "Categoría",
                fieldName: "categoria",
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column5",
                name: "Marca",
                fieldName: "marca",
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column6",
                name: "Modelo",
                fieldName: "modelo",
                minWidth: 100,
                maxWidth: 110,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                isPadded: true,
            },
            // {
            //     key: "column6",
            //     name: "Año Fabricación",
            //     fieldName: "anioFabricacion",
            //     minWidth: 100,
            //     maxWidth: 110,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: "string",
            //     isPadded: true,
            // },
            {
                key: "column7",
                name: "Cupos",
                fieldName: "pasajeros",
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column8",
                name: "Asignados / Embarcados",
                fieldName: "embarcado",
                minWidth: 150,
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                isPadded: true,
                onRender: (item: ITransporteRuta) => {
                    return (
                        <span style={{ color: ColorHelper.DANGER, ...ThemeBase.justifiedStyle }}>{`${item.asignado} / ${item.embarcado}`}</span>
                    );
                },
            },
            {
                key: "column9",
                name: "Destinos",
                fieldName: "rutaDestino",
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                isPadded: true,
            },
            // {
            //     key: "column8",
            //     name: "F. Registro",
            //     fieldName: "fecha",
            //     minWidth: 100,
            //     maxWidth: 110,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: "string",
            //     isPadded: true,
            // },
            // {
            //     key: "column9",
            //     name: "Registrado Por",
            //     fieldName: "usuarioRegistra",
            //     minWidth: 100,
            //     maxWidth: 110,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: "string",
            //     isPadded: true,
            // },
            // {
            //     key: "column10",
            //     name: "Motivo Eliminación",
            //     fieldName: "motivoEliminacion",
            //     minWidth: 160,
            //     maxWidth: 170,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: "string",
            //     isPadded: true,
            // },
            // {
            //     key: "column10",
            //     name: "Comentario",
            //     fieldName: "comentario",
            //     minWidth: 160,
            //     maxWidth: 170,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: "string",
            //     isPadded: true,
            // },
            // {
            //     key: "column11",
            //     name: "F. Eliminación",
            //     fieldName: "deleted",
            //     minWidth: 100,
            //     maxWidth: 110,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: "string",
            //     isPadded: true,
            // },
            // {
            //     key: "column12",
            //     name: "Eliminado Por",
            //     fieldName: "usuarioElimina",
            //     minWidth: 100,
            //     maxWidth: 110,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: "string",
            //     isPadded: true,
            // },
            {
                key: "column14",
                name: "Empresa Transportista",
                fieldName: "empresa",
                minWidth: 140,
                maxWidth: 150,
                isResizable: true,
                isCollapsible: true,
                data: "string",
                isPadded: true,
            },
            {
                key: "column15",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 100,
                maxWidth: 120,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: ITransporteRuta) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            <TooltipHost content='Grupos Tranporte'>
                                <Icon
                                    iconName={IconHelper.ICON_EDIT.iconProps?.iconName}
                                    style={{ cursor: 'pointer', color: `${ColorHelper.WARNING}` }}
                                    className={ThemeBase.classes.iconFont}
                                    onClick={() => {
                                        setSelAccesoBusId(item.programacionBusId);
                                        openDetalleGrupo();
                                    }}
                                />
                            </TooltipHost>

                            <TooltipHost content='Asignación Rutas'>
                                <Icon
                                    iconName={'IssueSolid'}
                                    style={{ cursor: 'pointer', color: `${item.isRutaDestino ? ColorHelper.SUCCESS : ColorHelper.DANGER}` }}
                                    className={ThemeBase.classes.iconFont}
                                    onClick={() => {
                                        setSelAccesoBusId(item.programacionBusId);
                                        setSelItemTransporte(item);
                                        openAsignar();
                                    }}
                                />
                            </TooltipHost>

                            {/* <TooltipHost content="Manifiesto">
                                <Icon
                                    iconName={IconHelper.ICON_REPORT_DOCUMENT.iconProps?.iconName}
                                    style={{ cursor: "pointer" }}
                                    className={ThemeBase.classes.iconFont}
                                    onClick={() => {
                                        setSelAccesoBusId(item.programacionBusId);
                                        openTelerik();
                                    }}
                                />
                            </TooltipHost> */}

                            <TooltipHost content="Embarcados">
                                <Icon
                                    iconName={IconHelper.ICON_DETAIL.iconProps?.iconName}
                                    style={{ cursor: "pointer" }}
                                    className={ThemeBase.classes.iconFont}
                                    onClick={() =>
                                        navigate(
                                            `${ConfigHelper.urlBase()}/empresa.transporte/asignado/${item.programacionBusId}`
                                        )
                                    }
                                />
                            </TooltipHost>

                            {item.deleted == "" && (
                                <TooltipHost content={`Eliminar`}>
                                    <Icon
                                        iconName="Trash"
                                        style={ThemeBase.iconRemove}
                                        className={ThemeBase.classes.iconFont}
                                        onClick={() => {
                                            showEliminarTransporte();

                                            setFormEliminar({
                                                ...formEliminar,
                                                programacionBusId: item.programacionBusId,
                                                comentario: '',
                                            });
                                        }}
                                    />
                                </TooltipHost>
                            )}
                        </Stack>
                    </>
                ),
            },
        ];

        setColumn(col);
    };

    /* Event */
    const _onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                switch (type) {
                    case "motivoEliminar":
                        console.log(option);
                        setFormEliminar({
                            ...formEliminar,
                            motivoEliminacionId: String(option.key),
                        });
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const _onChangeText = (type: string, event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (event != undefined) {
            if (newValue != undefined) {
                switch (type) {
                    case "comentario":
                        setFormEliminar({
                            ...formEliminar,
                            comentario: newValue,
                        });
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const onChangeModalBus = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event != undefined) {
            if (option != undefined) {
                setModelTransporte({
                    ...modelTransporte,
                    transporteId: option.key.toString(),
                });
            }
        }
    };

    /* Event Pagination */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: IFilterAsignacion = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    return (
        <>
            <TableBase leftBar={_leftBar()} column={column} data={data.items} style={ThemeBase.notPaddingTableBase} isLoading={hidden} pagination={pager}></TableBase>
            <CrearRutaProgramacionBus programacionBusId={selItemTransporte?.programacionBusId} transporte={selItemTransporte?.transporte} isOpen={isOpenAsignar} dismiss={dismissAsignar} reloadParent={reloadInitial}></CrearRutaProgramacionBus>

            {/* Modal Transporte Ruta */}
            <Modal
                isOpen={isOpenAsignarTransporte}
                onDismiss={() => {
                    setIsOpenAsignarTransporte(false);
                }}
                isBlocking={false}
                containerClassName={modalStyle.container}
            >
                <form>
                    <div className={modalStyle.header}>
                        <span id={""}>Asignar nuevo Vehiculo</span>
                        <IconButton
                            styles={modalIconButtonStyles}
                            iconProps={modalCancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={() => {
                                setIsOpenAsignarTransporte(false);
                            }}
                        />
                    </div>

                    <div className={modalStyle.body} style={{ minWidth: 500 }}>
                        <Stack tokens={stackTokens}>
                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                        <ComboBox
                                            label="Transporte"
                                            autoComplete="off"
                                            useComboBoxAsMenuWidth
                                            calloutProps={{ directionalHintFixed: true }}
                                            options={transporte}
                                            onChange={onChangeModalBus}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            <ButtonForm
                                back={() => {
                                    setIsOpenAsignarTransporte(false);
                                }}
                                register={asignarTransporte}
                            ></ButtonForm>
                        </Stack>
                    </div>
                </form>
            </Modal>

            {/* Modal Eliminar Transporte */}
            <Modal
                isOpen={isEliminarTransporte}
                onDismiss={dismissEliminarTransporte}
                isBlocking={false}
                containerClassName={modalStyle.container}
            >
                <form>
                    <div className={modalStyle.header}>
                        <span>Eliminar Transporte Asignado</span>
                        <IconButton
                            styles={modalIconButtonStyles}
                            iconProps={modalCancelIcon}
                            ariaLabel="Close popup modal"
                            onClick={dismissEliminarTransporte}
                        />
                    </div>

                    <div className={modalStyle.body} style={{ minWidth: 500 }}>
                        <Stack tokens={stackTokens}>
                            {isLoadingEliminar &&
                                <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} />
                            }

                            <div className="ms-Grid" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <ComboBox
                                            label="Motivo de retiro"
                                            autoComplete="off"
                                            useComboBoxAsMenuWidth
                                            calloutProps={{ directionalHintFixed: true }}
                                            options={motivoEliminacion}
                                            selectedKey={formEliminar ? formEliminar.motivoEliminacionId : ''}
                                            onChange={(e, o) => _onChangeCombo("motivoEliminar", e, o)}
                                            required
                                        />
                                    </div>

                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <TextField
                                            label="Comentario"
                                            multiline rows={5}
                                            value={formEliminar ? formEliminar.comentario : ''}
                                            onChange={(e, o) => _onChangeText("comentario", e, o)}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>

                            <ButtonForm
                                back={dismissEliminarTransporte}
                                register={eliminarRuta}
                                disabledRegister={isLoadingEliminar}
                            ></ButtonForm>
                        </Stack>
                    </div>
                </form>
            </Modal>

            {/* Panel - Reporte - Telerik */}
            <RepoteBaseGenerico
                headerName='Manifiesto'
                NameReport='MOVReporteManifiesto.trdx'
                Parameters={{
                    ProgramacionBusId: selAccesoBusId,
                }}
                openTelerik={isOpenTelerik}
                dismissTelerik={dismissTelerik}
            />

            <AsignacionMultipleTransporte programacionId={programacionId} isOpen={isOpenTransporteMasivo} dissmiss={dismissTransporteMasivo} reloadInitial={reloadInitial}></AsignacionMultipleTransporte>
            <DetalleTransporteGrupo programacionBusId={selAccesoBusId} isOpen={isOpenDetalleGrupo} dissmiss={dismissDetalleGrupo} reloadInitial={reloadInitial}></DetalleTransporteGrupo>
        </>
    )
}