import { Alignment, CommandBar, ICommandBarItemProps, Stack, getTheme } from '@fluentui/react';
import React, { FunctionComponent } from 'react'
import themeBase from '../../utils/Theme/themeBase';
import { helperFont } from '../../utils/Helper/helper';
import COLORES from '../../utils/Theme/themeBase';

const theme = getTheme();
const _rigthBar: ICommandBarItemProps[] = [];
const _leftBar: ICommandBarItemProps[] = [];

interface TableReportsProps {
    hidenIcon?: Boolean
    title: string
    subTitle?: string
    subTitle2?: String | undefined;
    showSubTitle?: Boolean
    iconName?: string
    horizontalAlign?: Alignment
    rigthBar: ICommandBarItemProps[]
    leftBar: ICommandBarItemProps[]
}

const Initial_Header: TableReportsProps = {
    rigthBar: _rigthBar,
    leftBar: _leftBar,
    title: "Titulo",
    hidenIcon: false,
    iconName: "Dialpad",
    showSubTitle: true,
    horizontalAlign: "start"
}

const TablaReporte: FunctionComponent<TableReportsProps> = (props = Initial_Header) => {

    const PositionT = () => {
        if (!props.hidenIcon)
            switch (props.horizontalAlign) {
                case "start": return <>{props.title}</>
                case "end": return <>{props.title}</>
                default: return <>{props.title}</>
            }
        else
            return <> <span>{props.title}</span>  </>
    }
    return (
        <>
            <div>

                <Stack
                    // style={{ ...helperFont, color: COLORES.Bases.neutralSecondaryAlt, }} verticalAlign="center" horizontal horizontalAlign={props.horizontalAlign}>
                    style={{ ...helperFont, color: theme.palette.neutralPrimary, }} verticalAlign="center" horizontal horizontalAlign={props.horizontalAlign}>
                    {/* <FontIcon iconName={props.iconName} className="ms-fontWeight-bold" /> */}
                    {PositionT()}
                </Stack>
                <Stack style={{ ...helperFont, fontSize: '14px', color: COLORES.Bases.neutralSecondaryAlt, }} horizontal horizontalAlign={props.horizontalAlign}>
                    <span>{props.subTitle}</span>
                </Stack>
                <Stack style={{ ...helperFont, fontSize: '10px', color: COLORES.Bases.neutralSecondaryAlt, }} horizontal horizontalAlign={props.horizontalAlign}>
                    <span>{props.subTitle2}</span>
                </Stack>
                <br />
                {(props.rigthBar?.length > 0 || props.leftBar?.length > 0) && (
                    <div className="ms-Grid-col ms-sm12 ms-lg12">
                        <CommandBar
                            items={props.leftBar}
                            farItems={props.rigthBar}
                            styles={themeBase.commadStyles}
                        />
                    </div>
                )}
            </div>


        </>

    )
}

export default TablaReporte