import axios from "axios";
import AuthService from "./AuthService";
import TokenService from "./TokenService";
import { CLAIM_COD_CONT, CLAIM_TOKEN_SESION_C } from "../utils/Helper/roleHelper";
import { RedireccionUrlContraseniaIdentidad, RedireccionUrlIdentidad, cambiarUrl, keyAccess } from "../utils/Helper/helper";
// import { access } from "fs";
// import { ConfigHelper } from "../utils/Helper/configHelper";


let type = { "Content-type": "application/json" };
// const { signout } = useContext(AuthContext);

// //Ambiente local
// let adminitracionUrl = "http://localhost:5084/api/v1/";
// let movilizacionUrl = "http://localhost:30248/api/";
//let identidadUrl = "http://localhost:5286/api/v1";
//let contratoUrl = "http://localhost:25531/api/v1";
//export let dispositivoUrl = "https://localhost:7260/api/v1/";

//UrlBaseIdentidad
//const urlBaseIdentidad = `http://localhost:3000/identidad`;
//let urlLogin = 'http://localhost:3001/login'

//Ambiente QAS
//  let adminitracionUrl = "https://mmg-bso-dev-web21-e3.azurewebsites.net/api/v1/";
//  let movilizacionUrl = "https://mmg-bso-dev-web22-e3.azurewebsites.net/api/";
//  let identidadUrl = "https://mmg-bso-dev-web16-e3.azurewebsites.net/api/v1";
//  let contratoUrl = "https://mmg-bso-dev-web23-e3.azurewebsites.net/api/v1";
//  export let dispositivoUrl = "https://mmg-bso-dev-web27-e3.azurewebsites.net/api/v1/";



//UrlBaseIdentidad
//  const urlBaseIdentidad = `https://mmg-bso-dev-web58-e3.azurewebsites.net/identidad`
//  let urlLogin = 'https://mmg-bso-dev-web25e3.azurewebsites.net/login'
// let adminitracionUrl = "http://localhost:5084/api/v1/";
// let movilizacionUrl = "https://localhost:30248/api/";
// let identidadUrl = "http://localhost:5286/api/v1";
// let contratoUrl = "http://localhost:25531/api/v1";
// export let dispositivoUrl = "https://localhost:7260/api/v1/";

// //UrlBaseIdentidad
// const urlBaseIdentidad = `http://localhost:3000/identidad`;
// let urlLogin = 'http://localhost:3001/login'

//Ambiente QAS
// let adminitracionUrl = "https://mmg-bso-dev-web21-e3.azurewebsites.net/api/v1/";
// let movilizacionUrl = "https://mmg-bso-dev-web22-e3.azurewebsites.net/api/";
// let identidadUrl = "https://mmg-bso-dev-web16-e3.azurewebsites.net/api/v1";
// let contratoUrl = "https://mmg-bso-dev-web23-e3.azurewebsites.net/api/v1";
// export let dispositivoUrl = "https://mmg-bso-dev-web27-e3.azurewebsites.net/api/v1/";
// UrlBaseIdentidad
// const urlBaseIdentidad = `https://mmg-bso-dev-web25e3.azurewebsites.net/identidad`
// let urlLogin = 'https://mmg-bso-dev-web25e3.azurewebsites.net/login'

/* Telerik Reporting */
// QAS
// let urlTelerik = "https://mmg-bso-dev-web26e3.azurewebsites.net/api/reports";
// PROD
// let urlTelerik = "https://mmg-bso-dev-web26-e1.azurewebsites.net/api/reports";


// let urlTelerik = "https://web17-e1.api.lasbambas.com/api/reports";

//Ambiente Prod
// let adminitracionUrl = "https://web21-e1.api.lasbambas.com/api/v1/";
// let movilizacionUrl = "https://mmg-bso-dev-web22-e1.azurewebsites.net/api/";
// let identidadUrl = "https://mmg-bso-dev-web16-e1.azurewebsites.net/api/v1";
// let contratoUrl = "https://mmg-bso-dev-web23-e1.azurewebsites.net/api/v1";
// export let dispositivoUrl = "https://mmg-bso-dev-web27-e1.azurewebsites.net/api/v1/";

//UrlBaseIdentidad Prod
// const urlBaseIdentidad = `https://miviaje.lasbambas.com/identidad`
// let urlLogin = 'https://miviaje.lasbambas.com/login'

// prod dns
let adminitracionUrl =   "https://web21-e1.api.lasbambas.com/api/v1/";
let movilizacionUrl =    "https://web22-e1.api.lasbambas.com/api/";
let identidadUrl =       "https://web16-e1.api.lasbambas.com/api/v1";
let contratoUrl =        "https://web23-e1.api.lasbambas.com/api/v1";
export let dispositivoUrl =     "https://web27-e1.api.lasbambas.com/api/v1/";
// let urlTelerik =         "https://web26-e1.api.lasbambas.com/api/reports";
let urlLogin =           "https://miviaje.lasbambas.com/login";
let urlBaseIdentidad = "https://miviaje.lasbambas.com/identidad";

// URL Recuperación Contrasenia
export let urlRecuperarContraseniaIdentidad = `${urlBaseIdentidad}/recuperarContraseniaEmail/${cambiarUrl(urlLogin)}`;

/* Telerik Reporting */
// QAS
// let urlTelerik = "https://mmg-bso-dev-web26e3.azurewebsites.net/api/reports";
//
let urlTelerikDescarga = "https://mmg-bso-dev-web26-e1.azurewebsites.net/api";
// PROD
let urlTelerik = "https://mmg-bso-dev-web26-e1.azurewebsites.net/api/reports";


/* Axios */
const fetchIdentidad = axios.create({
  baseURL: identidadUrl,
  headers: { "Content-type": "application/json", Authorization: "Bearer " },
});

const fetchAdministracion = axios.create({
  baseURL: adminitracionUrl,
  headers: type,
});

const fetchMovilizacion = axios.create({
  baseURL: movilizacionUrl,
  headers: type,
});

const fetchContrato = axios.create({
  baseURL: contratoUrl,
  headers: type,
});
const fetchDispositivo = axios.create({
  baseURL: dispositivoUrl,
  headers: { "Content-type": "application/json", Authorization: "Bearer " },
});

const fetchUrlTelerik = axios.create({
  baseURL: urlTelerik,
  headers: { "Content-type": "application/json", Authorization: "Bearer " },
});

const fetchUrlTelerikDescarga = axios.create({
  baseURL: urlTelerikDescarga,
  headers: type,
});

/* Interceptor */
fetchIdentidad.interceptors.request.use(
  (config) => {
    if (config.headers) {
      let isExp = AuthService.verifiedExp();
      if (isExp) {
        localStorage.removeItem(keyAccess);
        AuthService.removeToken();
      }
      let t = TokenService.getToken();
      if (t == null) {
        localStorage.removeItem(keyAccess);
      }
      config.headers["Authorization"] = `Bearer ${t}`;
    }
    return config;
  },
  (error) => {
    //alert(error);
    return Promise.reject(error);
  }
);

fetchIdentidad.interceptors.response.use(response => {
  let t = TokenService.getToken();
  if (t) {
    const decodedToken = AuthService.getInfoToken(CLAIM_COD_CONT);
    const decodedTokenSec = AuthService.getInfoToken(CLAIM_TOKEN_SESION_C);
    if (decodedToken === "01") {
      //alert("Primer inicio de sesión: Debe cambiar su contraseña.")
      alert("Por favor, cambie su contraseña para continuar")
      //let infoUrl = `http:@localhost:3001!login/cambiarContrasenia/${decodedToken}/${t}/http:@localhost:3001!login`
      //let urlLogin = {ConfigHelper.urlLogin()}
      //let urlLogin = 'http:@localhost:3001!login'
      //let urlLogin = 'http://localhost:3001/login'
      var urlLogFinal = cambiarUrl(urlLogin);
      let email = ''
      const userDataString = localStorage.getItem('Access');
      if (userDataString) {
        const userData = JSON.parse(userDataString);
        const userEmail = userData.email;
        email = userEmail
      } else {
        alert("No fue posible obtener el email")
      }

      //limpiar storage
      //localStorage.clear();

      //`https://teseoqas.idslatam.com/identidad/cambiarContrasenia/${email}/${t}/https:@mmg-bso-dev-web25e3.azurewebsites.net!login`);
      window.location.assign(RedireccionUrlIdentidad(urlBaseIdentidad, email, decodedTokenSec, urlLogFinal));
      //window.location.assign('http://localhost:3000/identidad/cambiarContrasenia/http:@localhost:3001!login');
    }
    if (decodedToken === "02") {
      alert("Contraseña expirada, debe actualizar su contraseña.")
      //let urlLogin = 'http://localhost:3001/login'
      var urlLogFinal = cambiarUrl(urlLogin);
      // let urlLogin = 'http:@localhost:3001!login'
      let email = ''
      const userDataString = localStorage.getItem('Access');
      if (userDataString) {
        const userData = JSON.parse(userDataString);
        const userEmail = userData.email;
        email = userEmail
      } else {
        alert("No fue posible obtener el email")
      }

      //limpiar storage
      //localStorage.clear();

      window.location.assign(RedireccionUrlContraseniaIdentidad(urlBaseIdentidad, email, decodedTokenSec, urlLogFinal));
    }
  }
  return response;
}, error => {
  if (error.response?.status === 401 || error.response?.status === 403) {
    localStorage.removeItem(keyAccess);
    AuthService.removeToken();
    window.location.assign('/login');
    //alert("Sesión expirada...")
  } else {
    return Promise.reject(error);
  }
});

fetchAdministracion.interceptors.request.use(
  (config) => {
    if (config.headers) {
      let isExp = AuthService.verifiedExp();

      if (isExp) {
        localStorage.removeItem(keyAccess);
        return AuthService.removeToken();
        //return Promise.reject(new Error("Token expirado"));
      }

      let t = TokenService.getToken();
      if (t == null) {
        localStorage.removeItem(keyAccess);
      }
      config.headers["Authorization"] = `Bearer ${t}`;
    }
    return config;
  },
  (error) => {
    //alert(error);
    return Promise.reject(error);
  }
);
fetchAdministracion.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      localStorage.removeItem(keyAccess);
      AuthService.removeToken();
      window.location.assign("/login");
    } else {
      return Promise.reject(error);
    }
  }
);

fetchMovilizacion.interceptors.request.use(
  (config) => {
    if (config.headers) {
      let isExp = AuthService.verifiedExp();

      if (isExp) {
        localStorage.removeItem(keyAccess);
        AuthService.removeToken();
      }
      let t = TokenService.getToken();
      if (t == null) {
        localStorage.removeItem(keyAccess);
      }
      config.headers["Authorization"] = `Bearer ${t}`;
    }

    return config;
  },

  (error) => {
    //alert(error);
    return Promise.reject(error);
  }
);
fetchMovilizacion.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    //const status = error.response?.status || 500;
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      localStorage.removeItem(keyAccess);
      AuthService.removeToken();
      window.location.assign("/login");
    } else {
      return Promise.reject(error);
    }
  }
);

fetchContrato.interceptors.request.use(
  (config) => {
    if (config.headers) {
      let isExp = AuthService.verifiedExp();

      if (isExp) {
        localStorage.removeItem(keyAccess);
        AuthService.removeToken();
      }

      let t = TokenService.getToken();
      if (t == null) {
        localStorage.removeItem(keyAccess);
      }
      config.headers["Authorization"] = `Bearer ${t}`;
    }

    return config;
  },

  (error) => {
    //alert(error);

    return Promise.reject(error);
  }
);
fetchContrato.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      localStorage.removeItem(keyAccess);
      AuthService.removeToken();
      window.location.assign("/login");
    } else {
      return Promise.reject(error);
    }
  }
);

fetchDispositivo.interceptors.request.use(
  (config) => {
    if (config.headers) {
      let isExp = AuthService.verifiedExp();

      if (isExp) {
        localStorage.removeItem(keyAccess);
        AuthService.removeToken();
      }

      let t = TokenService.getToken();
      if (t == null) {
        localStorage.removeItem(keyAccess);
      }
      config.headers["Authorization"] = `Bearer ${t}`;
    }

    return config;
  },

  (error) => {
    //alert(error);

    return Promise.reject(error);
  }
);
fetchDispositivo.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      localStorage.removeItem(keyAccess);
      AuthService.removeToken();
      window.location.assign("/login");
    } else {
      return Promise.reject(error);
    }
  }
);

fetchUrlTelerik.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      window.location.assign("/login");
      localStorage.removeItem(keyAccess);
    } else {
      return Promise.reject(error);
    }
  }
);

fetchUrlTelerikDescarga.interceptors.request.use(
  (config) => {
    if (config.headers) {
      let isExp = AuthService.verifiedExp();
      if (isExp) {
        localStorage.removeItem(keyAccess);
        AuthService.removeToken();
      }
      let t = TokenService.getToken();
      if (t == null) {
        localStorage.removeItem(keyAccess);
      }
      config.headers["Authorization"] = `Bearer ${t}`;
    }
    return config;
  },
  (error) => {
    //alert(error);
    return Promise.reject(error);
  }
);
export {
  fetchIdentidad,
  fetchAdministracion,
  fetchMovilizacion,
  fetchContrato,
  fetchDispositivo,
  /* Teseo */
  urlTelerik,
  fetchUrlTelerik,
  fetchUrlTelerikDescarga,
};