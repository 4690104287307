import {
  ComboBox,
  DatePicker,
  Stack,
  TextField,
  IComboBoxOption,
  IComboBox,
  Toggle,
  ProgressIndicator,
  SelectableOptionMenuItemType,
} from "@fluentui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { ButtonForm } from "../../components/Generic/buttonForm";
import { IComboOptions } from "../../components/Generic/combo";
import { PanelGenerico } from "../../components/Generic/PanelGenerico";
import {
  CustomMessageBar,
  typeMessage,
} from "../../components/MessageBar/customMessageBar";
import { IException, IItemComboBaseDTO } from "../../interfaces/base/IBase";
import { Ifilter } from "../../interfaces/IFilter";
import { ICreateProgramacion } from "../../interfaces/IProgramacion";
import { IDetalleNivelLevel } from "../../interfaces/ITemporada";
import ConfiguracionService from "../../services/configuracionService";
import PersonaService from "../../services/PersonaService";
import ProgramacionService from "../../services/ProgramacionService";
import TemporadaService from "../../services/temporadaService";
import {
  _formatDate,
  _formatDatePrefix,
} from "../../utils/Helper/datetimeHelper";
import {
  ItemSelectAll,
  splitExceptionDetail,
  stackTokens,
  _initialFilter,
  _selectionItemsKeys,
} from "../../utils/Helper/helper";
import ThemeBase from "../../utils/Theme/themeBase";
import { AccesoGeneric } from "./../../pages/accesoPersona/generic/accesoGeneric";
import { useBoolean } from '@fluentui/react-hooks';

let newKey = 1;

export function NewProgramming({
  onDismiss = () => { },
  isOpen = false,
  setup = () => { },
}) {
  const [model, setmodel] = useState<ICreateProgramacion>(null!);
  const [guardia, setComboGuardia] = useState<IComboOptions[]>([]);
  const [tipoProgramacion, setTipoProgramacion] = useState<IItemComboBaseDTO[]>(
    []
  );

  /* V2 */
  const [inicio, setInicio] = useState<Date | undefined>(undefined);
  const [fin, setFin] = useState<Date | undefined>(undefined);
  const [filterGuardia, setFilterGuardia] = useState<Ifilter>(_initialFilter);
  const [isBajada, setIsBajada] = useState(false);
  const [isSubida, setIsSubida] = useState(false);
  const [turno, setTurno] = useState<IItemComboBaseDTO[]>(
    AccesoGeneric.initialTurno
  );
  const [validaEmpresa, setValidaEmpresa] = useState(false);
  const [isSSEE, setIsSSEE] = useState(false);
  const [isMMG, setIsMMG] = useState(false);

  const [isParadaPlanta, setIsParadaPlanta] = useState(false);

  /* Message Error & Choice */
  const [isSend, setIsSend] = useState(false);
  const [choice, setChoice] = React.useState<string | undefined>(undefined);
  const resetChoice = React.useCallback(() => setChoice(undefined), []);
  const [textError, settextError] = useState("Error");

  const [filterEmpresa, setFilterEmpresa] = useState<Ifilter>(_initialFilter);
  const [empresaCombo, setEmpresaCombo] = useState<IItemComboBaseDTO[]>([]);
  const [keysEmpresas, setKeysEmpresas] = useState<string[]>([]);
  const comboBoxRefEmpresa = React.useRef<IComboBox>(null);
  const _openOptionEmpresa = React.useCallback(
    () => comboBoxRefEmpresa.current?.focus(true),
    []
  );

  // LEVELS
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [detalleLevel, setDetalleLevel] = useState<IComboBoxOption[]>([]);
  const [allowFreeform, { toggle: toggleAllowFreeform }] = useBoolean(true);
  const [tieneRestriccion, setTieneRestriccion] = useState(false);

  /* Hook */
  useEffect(() => {
    if (isOpen) {
      setIsBajada(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if(isOpen === true){
      dataComboGuardia();

      dataTipoProgramacion();

    }
  }, [isOpen]);

  useEffect(() => {
    if (inicio != undefined) {
      setmodel({
        ...model,
        acceso: _formatDate(inicio, ","),
      });
    }
  }, [inicio]);

  useEffect(() => {
    if (fin != undefined) {
      setmodel({
        ...model,
        recurrencia: _formatDate(fin, ","),
      });
    }
  }, [fin]);

  useEffect(() => {
    if(isOpen === true){
    dataEmpresa();

    }
  }, [filterEmpresa,isOpen]);

  useEffect(() => {
    setmodel({ ...model, LevelsId: selectedKeys })
  }, [selectedKeys]);
  /* Api */
  const crearProgramacion = () => {
    setIsSend(true);

    let modelProg: ICreateProgramacion = {
      ...model,
      validaEmpresa: validaEmpresa == true ? true : false,
      listEmpresaId: keysEmpresas.filter((key) => key !== "selectAll"),
      soloSubida: isSubida,
      soloBajada: isBajada,
    };

    console.log("------------ post model PROGRAMACION --------------");
    console.log(modelProg);

    if (!isBajada) {
      PersonaService.postPrograma(modelProg)
        .then((response) => {
          if (response.status == 200) {
            setup();
            onDismiss();
            setIsSend(false);
          }
        })
        .catch((e: IException) => {
          setIsSend(false);
          setChoice("error");
          settextError(splitExceptionDetail(e));
        });
    } else {
      // Programacion Bajada con y sin recurrencia
      ProgramacionService.crearBajada(modelProg)
        .then((response) => {
          if (response.status == 200) {
            setup();
            onDismiss();
            setIsSend(false);
          }
        })
        .catch((e: IException) => {
          setIsSend(false);
          setChoice("error");
          settextError(splitExceptionDetail(e));
        });
    }
  };

  /**API LEVELS */
  const dataLevels = () => {
    TemporadaService.detalleNivelLevel().then((res) => {
      console.log(res.data)
      let data: IDetalleNivelLevel[] = res.data
      let op: IComboBoxOption[] = []
      let ls: string[] = []
      data.map((item: IDetalleNivelLevel) => {
        if (
          item.nivel.trim().toLowerCase() === "LEVEL 2".trim().toLowerCase()
          || item.nivel.trim().toLowerCase() === "LEVEL 3".trim().toLowerCase()
          || item.nivel.trim().toLowerCase() === "LEVEL 4".trim().toLowerCase()
          || item.nivel.trim().toLowerCase() === "LEVEL 5".trim().toLowerCase()) {

          ls.push(item.nivelId.toString().trim())
          // itemType: SelectableOptionMenuItemType.Header

          op.push({
            key: item.nivelId,
            text: item.nivel,
            disabled: (
              item.nivel.toString().trim().toLowerCase() === "LEVEL 1".trim().toLowerCase()
              // || item.nivel.toString().trim().toLowerCase() === "LEVEL 2".trim().toLowerCase()
              || item.nivel.toString().trim().toLowerCase() === "LEVEL T".trim().toLowerCase()
              || item.nivel.toString().trim().toLowerCase() === "TRAINEE".trim().toLowerCase()
            ) ? true : false,
          })
        }
        /**Colocar op.push Aqui si se quiere mostarar todos lo niveles  */
        // let lv: IItemDetalleLevel[] = item.level
        // lv.map((l: any) => {
        //   op.push({
        //     key: l.levelId,
        //     text: l.level,
        //   })
        // })
      })
      // setSelectedKeys(ls) seleccionados por defecto
      setDetalleLevel(op)
    }).catch((e: Error) => {
      console.log(e.message)
    })
    // setDetalleLevel()
    return
  }
  const onChange = React.useCallback(
    (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string): void => {
      let selected = option?.selected;
      if (allowFreeform && !option && value) {
        // If allowFreeform is true, the newly selected option might be something the user typed that
        // doesn't exist in the options list yet. So there's extra work to manually add it.
        selected = true;
        option = { key: `${newKey++}`, text: value };
        setDetalleLevel(prevOptions => [...prevOptions, option!]);
      }

      if (option) {
        setSelectedKeys(prevSelectedKeys =>
          selected ? [...prevSelectedKeys, option!.key as string] : prevSelectedKeys.filter(k => k !== option!.key),
        );
      }
    },
    [allowFreeform],
  );
  const dataComboGuardia = () => {
    PersonaService.comboGuardiaProgramacion(filterGuardia)
      .then((response: any) => {
        if (response.status == 200) {
          setComboGuardia(response.data);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataTipoProgramacion = () => {
    ProgramacionService.allTipoProgramacion()
      .then((res: any) => {
        if (res.status == 200) {
          setTipoProgramacion(res.data);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  function dataEmpresa() {
    ConfiguracionService.allEmpresa(filterEmpresa)
      .then((res) => {
        if (res.status == 200) {
          /* let d: IEmpresaCombo[] = res.data
          let t: IItemComboBaseDTO[] = d.map(x => {
            return {
              key: x.empresaId,
              text: x.nombre
            }
          })
          t.unshift(ItemSelectAll()) */

          let t: IItemComboBaseDTO[] = res.data;
          t.unshift(ItemSelectAll());

          setEmpresaCombo(t);
        }
      })
      .catch(function (e: IException) {
        alert(e.response.data.detail);
      });
  }

  function _onChangeTooggle(
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) {
    setmodel({
      ...model,
      tieneCuarentena: checked ? true : false,
    });
  }

  const _onTooggleBajada = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    if (checked != null) {
      setIsBajada(checked);
    }
  };
  const _onTooggleSubida = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    if (checked != null) {
      setIsSubida(checked);
    }
  };

  const _onTooggleRestricciones = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    if (checked != null) {
      console.log("Restriccion de Levels")
      setTieneRestriccion(true)
      if (checked) {
        dataLevels()
      } else {
        setSelectedKeys([])
        setDetalleLevel([])
        setTieneRestriccion(false)
      }
    }
  };

  const _onTooggleEmpresa = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    if (checked != null) {
      setValidaEmpresa(checked);
      setmodel({ ...model, validaEmpresa: checked });
    }
  };

  const _onTooggleParadaPlanta = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    if (checked != null) {
      setIsParadaPlanta(checked);
      setmodel({ ...model, isParadaPlanta: checked });
    }
  };

  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        switch (type) {
          case "tipo":
            setmodel({
              ...model,
              tipoProgramacionId: String(option.key),
            });

            if (option.text == "SSEE") {
              setSelectedKeys([])
              setIsSSEE(true);
            } else {
              setIsSSEE(false);
            }
            if (option.text == "MMG") {
              setIsMMG(true);
            } else {
              setIsMMG(false);
            }
            break;
          case "guardia":
            setmodel({
              ...model,
              guardiaId: String(option.key),
            });
            break;
          case "turno":
            setmodel({
              ...model,
              turno: String(option.key),
            });
            break;
          case "empresa":
            _selectionItemsKeys(
              option,
              setKeysEmpresas,
              setEmpresaCombo,
              empresaCombo,
              keysEmpresas
            );

            break;
          default:
            break;
        }
      }
    }
  };

  const _onUpEmpresa = (ev?: React.KeyboardEvent<IComboBox>) => {
    _openOptionEmpresa();

    let { value } = ev?.target as HTMLInputElement;

    setFilterEmpresa({
      ...filterEmpresa,
      search: value,
    });
  };

  const _onChangeText = (
    type: string,
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (event != undefined) {
      if (newValue != undefined) {
        switch (type) {
          case "titulo":
            setmodel({
              ...model,
              titulo: newValue,
            });
            break;
          case "diaCierre":
            setmodel({
              ...model,
              diasCierre: parseInt(newValue),
            });
            break;
          case "cupo":
            setmodel({
              ...model,
              cupos: newValue,
            });
            break;
          default:
            break;
        }
      }
    }
  };

  const onRendeFooter = React.useCallback(
    () => (
      <div>
        <ButtonForm
          back={onBackForm}
          register={crearProgramacion}
          disabledRegister={isSend}
        ></ButtonForm>
      </div>
    ),
    [model, onDismiss, isSend, inicio, fin, isBajada, isOpen, keysEmpresas, isSubida, selectedKeys, tieneRestriccion]
  );

  const onBackForm = () => {
    onDismiss();
    setmodel(null!);
    setInicio(undefined);
    setFin(undefined);
    setIsSubida(false)
    setIsMMG(false);
    setSelectedKeys([])
  };

  /* Callback reload */
  const refForm = React.useCallback(() => {
    setmodel(null!);
    setInicio(undefined);
    setFin(undefined);
    resetChoice();
    setIsSubida(false)
    setIsMMG(false);
    setSelectedKeys([])
  }, [onDismiss]);

  const _onDismiss = () => {
    setmodel(null!);
    onDismiss()
    setIsSubida(false)
    setIsMMG(false);
    setSelectedKeys([])
    return
  }

  return (
    <PanelGenerico
      isOpen={isOpen}
      onDismiss={_onDismiss}
      isOnRenderHeader
      disabledFooter
      iconHeaderName="DependencyAdd"
      title="Nueva Programación"
      subTitle="Complete el formulario para crear programación"
      onRendeFooter={onRendeFooter}
    >
      <div>
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
              <ProgressIndicator
                description="Enviando espere un momento"
                barHeight={3}
              />
            </div>

            <div
              className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
              style={ThemeBase.notPaddingSide}
            >
              {choice === "error" && (
                <CustomMessageBar
                  type={typeMessage.TYPE_ERROR}
                  message={textError}
                  dissmiss={resetChoice}
                ></CustomMessageBar>
              )}
              {choice === "send" && (
                <CustomMessageBar
                  dissmiss={() => {
                    refForm();
                    resetChoice();
                  }}
                  message="Registro Correcto"
                ></CustomMessageBar>
              )}
            </div>
          </div>
        </div>

        <form
          ref={refForm}
          hidden={
            choice === "error" || choice === "send" || isSend ? true : false
          }
        >
          <div className="ms-Grid" dir="ltr">
            <Stack tokens={stackTokens}>
              <br />
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                  <TextField
                    label="Titulo de programación"
                    name="titulo"
                    placeholder="Ingrese titulo de la programación"
                    value={model ? model.titulo : ""}
                    //value={model?.titulo}
                    onChange={(e, o) => _onChangeText("titulo", e, o)}
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <ComboBox
                    label="Tipo"
                    autoComplete="off"
                    useComboBoxAsMenuWidth
                    calloutProps={{ directionalHintFixed: true }}
                    options={tipoProgramacion}
                    selectedKey={model ? model.tipoProgramacionId : ""}
                    onChange={(e, o) => _onChangeCombo("tipo", e, o)}
                    required
                  />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <ComboBox
                    label="Guardia"
                    autoComplete="off"
                    useComboBoxAsMenuWidth
                    calloutProps={{ directionalHintFixed: true }}
                    options={guardia}
                    selectedKey={model ? model.guardiaId : ""}
                    onChange={(e, o) => _onChangeCombo("guardia", e, o)}
                    required
                  />
                </div>
              </div>

              {isMMG && (
                <div className="ms-Grid-row">
                  <div hidden={false} className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                    <Toggle
                      label="Restriccion Levels"
                      onText="Si"
                      offText="No"
                      onChange={_onTooggleRestricciones}
                    />
                  </div>
                  {tieneRestriccion && (
                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                      <ComboBox
                        label="Levels Permitidos"
                        multiSelect
                        options={detalleLevel}
                        useComboBoxAsMenuWidth
                        allowFreeform={allowFreeform}
                        selectedKey={selectedKeys}
                        onChange={onChange}
                      // styles={comboBoxStyles}
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <DatePicker
                    label="Fecha Acceso"
                    placeholder="Fecha Acceso"
                    allowTextInput
                    ariaLabel="Select a date. Input format is day slash month slash year."
                    value={inicio}
                    minDate={new Date()}
                    onSelectDate={setInicio as (date?: any) => void}
                    formatDate={(d) => _formatDatePrefix("F. Acceso", d)}
                    style={{ paddingTop: "4px" }}
                    isRequired
                  />
                </div>

                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <DatePicker
                    label="Fecha Recurrencia"
                    placeholder="Fecha Recurrencia"
                    allowTextInput
                    ariaLabel="Select a date. Input format is day slash month slash year."
                    value={fin}
                    onSelectDate={setFin as (date?: any) => void}
                    formatDate={(d) => _formatDatePrefix("F. Recurrencia", d)}
                    style={{ paddingTop: "4px" }}
                    isRequired
                    // disabled={isBajada}
                  />
                </div>
              </div>

              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <TextField
                    label="Días Cierre"
                    name="diaCierre"
                    placeholder="Días Cierre"
                    min={0}
                    onChange={(e, o) => _onChangeText("diaCierre", e, o)}
                    type="number"
                  />
                </div>

                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <TextField
                    label="Cupos"
                    name="cupo"
                    placeholder="Cupos"
                    min={0}
                    onChange={(e, o) => _onChangeText("cupo", e, o)}
                    type="number"
                  />
                </div>
              </div>
              <br />
              <div className="ms-Grid-row">
                <div hidden={(isBajada)} className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <Toggle
                    label="Solo Subida"
                    onText="Si"
                    offText="No"
                    onChange={_onTooggleSubida}
                  />
                </div>
                <div hidden={isSubida} className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <Toggle
                    label="Solo Bajada"
                    onText="Si"
                    offText="No"
                    onChange={_onTooggleBajada}
                  />
                </div>
                {isBajada && (
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                    <ComboBox
                      label="Seleccionar Turno"
                      autoComplete="off"
                      useComboBoxAsMenuWidth
                      calloutProps={{ directionalHintFixed: true }}
                      options={turno}
                      openOnKeyboardFocus
                      selectedKey={model ? model.turno : ""}
                      onChange={(e, o) => _onChangeCombo("turno", e, o)}
                      required
                    />
                  </div>
                )}
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <Toggle
                    label="¿Es Parada Planta?"
                    placeholder="¿Validar Empresa?"
                    onText="Si"
                    offText="No"
                    onChange={_onTooggleParadaPlanta}
                  />
                </div>
                {isSSEE && (
                  <>
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                      <Toggle
                        label="¿Validar Empresa?"
                        placeholder="¿Validar Empresa?"
                        onText="Si"
                        offText="No"
                        onChange={_onTooggleEmpresa}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="ms-Grid-row">
                {validaEmpresa && (
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <ComboBox
                      label="Seleccionar Empresas"
                      useComboBoxAsMenuWidth
                      calloutProps={{ directionalHintFixed: true }}
                      options={empresaCombo}
                      openOnKeyboardFocus
                      selectedKey={keysEmpresas}
                      onChange={(e, o) => _onChangeCombo("empresa", e, o)}
                      required
                      multiSelect
                      allowFreeform={true}
                    //componentRef={comboBoxRefEmpresa}
                    //onKeyUp={_onUpEmpresa}
                    />
                  </div>
                )}
              </div>
              {/* <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <Toggle
                    label="Acuartelamiento"
                    placeholder="Tiene cuarentena"
                    onText="Tiene Acuartelamiento"
                    offText="No Tiene Acuartelamiento"
                    onChange={_onChangeTooggle}
                  />
                </div>
              </div> */}
            </Stack>
          </div>
        </form>
      </div>
    </PanelGenerico>
  );
}