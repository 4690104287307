import { ActivityItem, DefaultButton, FontIcon, ICommandBarItemProps, Link, Panel, PrimaryButton, setVirtualParent } from "@fluentui/react"
import React from "react";
import { IFile } from "../../interfaces/base/IBase";
import { IPanelUpload } from "../../interfaces/base/IPanel"
import { downloadBlob } from "../../utils/Helper/helper";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ThemeBase from "../../utils/Theme/themeBase";
import { CustomCommandBar } from "../Generic/customCommandBar"
import blobStorageService from "../../services/blobStorageService";

export const PanelUpload = ({ header, isOpen, dissmiss, fileDownload, fileDetail, file, updateFileDetail, updateFile, upload }: IPanelUpload) => {
    
    const obtenerblob = (listUrlBase: any) => {
        blobStorageService.cargarblobstorage(listUrlBase.url)
        .then((res) => {
          const blobUrl = res.data;
    
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = `${listUrlBase.nombre}.${listUrlBase.extension}`;
          link.click();
        })
        .catch((e) => {
          console.log("Esto es error")
        });
    };
    
    const downloadBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_DOWNLOAD,
                text: "Descargar Plantilla",
                onClick: () => obtenerblob(fileDownload),
            },
        ];

        return _items;
    };

    const uploadBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_UPLOAD,
                text: "Cargar Plantilla",
                preferMenuTargetAsEventTarget: true,
                onClick: (
                    ev?:
                        | React.MouseEvent<HTMLElement, MouseEvent>
                        | React.KeyboardEvent<HTMLElement>
                        | undefined
                ) => {
                    ev?.persist();

                    Promise.resolve().then(() => {
                        const inputElement = document.createElement("input");
                        inputElement.style.visibility = "hidden";
                        inputElement.setAttribute("type", "file");
                        inputElement.setAttribute(
                            "accept",
                            ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        );
                        inputElement.onchange = function (ev) {
                            let { files } = ev.target as HTMLInputElement;

                            if (files != undefined) {
                                const file: File = files[0];

                                var formData = new FormData();
                                formData.append("file", file, file.name);

                                updateFile(formData);

                                updateFileDetail({
                                    nombre: file.name,
                                    tamanio: file.size,
                                    tipo: file.type,
                                });
                            }
                        };

                        document.body.appendChild(inputElement);

                        const target = ev?.target as HTMLElement | undefined;

                        if (target) {
                            setVirtualParent(inputElement, target);
                        }

                        inputElement.click();

                        if (target) {
                            setVirtualParent(inputElement, null);
                        }

                        setTimeout(() => {
                            inputElement.remove();
                        }, 10000);
                    });
                },
            },
        ];

        return _items;
    };

    const onRenderFooter = React.useCallback(
        () => (
            <div>
            <PrimaryButton
              onClick={upload}
              styles={ThemeBase.panelButtonStyle}
              disabled={!fileDetail}
            >
              Confirmar
            </PrimaryButton>
            <DefaultButton onClick={dissmiss}>Cancelar</DefaultButton>
          </div>
        ),
        [header, dissmiss, fileDetail, file]
      );

    return (
        <Panel
            isOpen={isOpen}
            onDismiss={dissmiss}
            headerText={header}
            closeButtonAriaLabel='Close'
            onRenderFooterContent={onRenderFooter}
            isFooterAtBottom={true}
        >
            <div style={{ paddingTop: "20%", paddingLeft: "0px" }}>
                <CustomCommandBar
                    item={downloadBar()}
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                ></CustomCommandBar>
            </div>

            <div style={{ paddingTop: "30%", paddingLeft: "0px" }}>
                <CustomCommandBar
                    item={uploadBar()}
                    style={{ paddingRight: "0px", paddingLeft: "0px" }}
                ></CustomCommandBar>
            </div>

            <div style={{ paddingTop: "40%", paddingLeft: "0px" }}>
                {fileDetail && (
                    <>
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div
                                    className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <FontIcon
                                        aria-label={IconHelper.ICON_EXCEL.iconProps?.iconName}
                                        iconName={IconHelper.ICON_EXCEL.iconProps?.iconName}
                                        style={{
                                            color: ThemeBase.theme.palette.blackTranslucent40,
                                            fontSize: 40,
                                            height: 40,
                                            width: 40,
                                            margin: "0 25px",
                                        }}
                                    />
                                </div>

                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <ActivityItem
                                        activityDescription={[
                                            <Link className={ThemeBase.activityClass.nameText}>
                                                {fileDetail.nombre}
                                            </Link>,
                                            <span> </span>,
                                            <span className={ThemeBase.activityClass.nameText}>
                                                {fileDetail.tipo}
                                            </span>,
                                        ]}
                                        timeStamp={`${(fileDetail.tamanio / 1048576).toFixed(
                                            2
                                        )} mb`}
                                        className={ThemeBase.activityClass.exampleRoot}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Panel>
    )
}