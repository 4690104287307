import {
  ICommandBarItemProps,
  TooltipHost,
  Persona,
  PersonaSize,
  Stack,
  Icon,
  ComboBox,
  IComboBox,
  IComboBoxOption,
  PanelType,
  Panel,
  FontIcon,
  MessageBar,
  MessageBarButton,
  ProgressIndicator,
  TextField,
  MessageBarType,
  IContextualMenuItem,
  IStackStyles,
} from "@fluentui/react";
import React, { useContext, useEffect, useState } from "react";
import { IColumn } from "@fluentui/react/lib/DetailsList";
import PersonaService from "../../services/PersonaService";
import {
  baseImageUrl,
  baseSearch,
  IndexNone,
  isObjectEmpty,
  ItemNoneSelect,
  splitExceptionDetail,
  stackTokensHorizontal,
  _initialFilter,
  _initialPagination,
} from "../../utils/Helper/helper";
import { HeadingBar } from "../../components/Generic/HeadingBar";
import {
  IBlob,
  IException,
  IFile,
  IItemComboBaseDTO,
  IReportTelerik,
  ISearch,
} from "../../interfaces/base/IBase";
import { IPersona } from "../../interfaces/IPersona";
import { TableBase } from "../../components/Table/TableBase";
import ThemeBase from "../../utils/Theme/themeBase";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ConfiguracionService from "../../services/configuracionService";
import OrganigramaService from "../../services/organigramaService";
import { IFilterPersona } from "../../interfaces/filter/IFilterPersona";
import RepoteBase from "../../components/Generic/ReporteBase";
import { useBoolean } from "@fluentui/react-hooks";
import { IPagination } from "../../interfaces/base/ITable";
import { PanelUpload } from "../../components/PanelUpload/panelUpload";
import { BlobHelper } from "../../utils/Helper/blobHelper";
import { AuthContext } from "../../auth/context/auth-context";
import {
  CLAIM_EMPRESA_USUARIO_ID,
  CLAIM_PERSONA_ID,
  isRol,
  isRoleAccessAll,
  roleAdmin,
  ROLE_ADMIN,
  ROLE_AREA_TRANSPORTE,
  ROLE_AREA_TRANSPORTE_ADMIN,
  ROLE_CONTRATISTA,
  ROLE_KEY_USER_EMPLEADO,
  ROLE_KEY_USER_SOCIO,
  ROLE_TRANSPORTE,
  TEXT_MASIVO_PERSONA_ADM_TRANSPORTE,
  TEXT_MASIVO_PERSONA_KEY_USER,
  TEXT_MASIVO_PERSONA_SSEE,
  ROLE_USRADMIN,
} from "../../utils/Helper/roleHelper";
import EditarPersonaKeyUser from "./editarPersonaKeyUser";
import EditarPersonaEmpTrans from "./editarPersonaEmpTransp";
import EditarPersonaSEE from "./editarPersonaSEE";
import { IResultadoCargaMasiva } from "../../interfaces/IProgramacion";
import { PanelGenerico } from "../../components/Generic/PanelGenerico";
import { IUserAuthenticate } from "../../auth/interface/IAuth";
import AuthService from "../../services/AuthService";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { ColorHelper } from "../../utils/Helper/colorHelper";
import { PanelActualizarPersona } from "./PanelActualizarPersona";
import { PanelAgregarPersona } from "./panelAgregarPersona";
import RepoteBaseGenerico from "../../components/Generic/ReporteBaseGenerico";

// A. ADMIN
// B. KeyUser
// C. SSEE
const reportByRole = (access: IUserAuthenticate | null) => {
  let role = access?.role ?? [];

  let reportName = "";
  let param = {};

  let isRolAdmin = isRoleAccessAll(role, roleAdmin());

  if (isRolAdmin) {
    reportName = "MOVMasterPersona.trdx";
  } else {
    let isRolSSEE = isRoleAccessAll(role, [ROLE_CONTRATISTA]);

    if (isRolSSEE) {
      reportName = "MOVPersonalSSEE.trdx";
      param = {
        EmpresasId: AuthService.getInfoToken(CLAIM_EMPRESA_USUARIO_ID),
      };
    } else if (
      isRoleAccessAll(role, [ROLE_KEY_USER_EMPLEADO, ROLE_KEY_USER_SOCIO])
    ) {
      reportName = "MOVPersonalKeyUser.trdx";
      param = {
        KeyUserId: AuthService.getInfoToken(CLAIM_PERSONA_ID),
      };
    }
  }

  let report: IReportTelerik = {
    reportName: reportName,
    param: param,
  };

  return report;
};

const estadoPersona = (codEstado: string, text: string) => {
  let color = "";

  switch (codEstado) {
    case "01":
      color = ColorHelper.SUCCESS;
      break;
    case "02":
      color = ColorHelper.DANGER;
      break;
    default:
      break;
  }

  const stackStyles: IStackStyles = {
    root: {
      // background: "yellow",
      background: "#ffff",
    },
  };

  return (
    <>
      {/* <Stack horizontal styles={ThemeBase.estadoStackStyle} tokens={ThemeBase.estadoStackToken}>
        <span style={ThemeBase.estadoStyle}>
          <FontIcon
            iconName="LocationDot"
            style={{ fontSize: 18, color: color }}
          />
          <span style={ThemeBase.estadoStyle}>{text}</span>
        </span>

      </Stack> */}
      <Stack horizontal horizontalAlign="start" styles={stackStyles}>
        <FontIcon
          iconName="LocationDot"
          style={{ fontSize: 18, color: color, marginLeft: "0px" }}
        />
        <span style={{ marginLeft: "4px" }}>{text}</span>
      </Stack>
    </>
  );
};

export const TablaPersona: React.FunctionComponent = () => {
  const navigate = useNavigate();

  const [hidden, setHidden] = useState(false);
  const [data, setData] = useState<IPersona[]>([]);
  const [column, setColumn] = useState<IColumn[]>([]);
  const [filter, setFilter] = useState<IFilterPersona>(_initialFilter);
  const [searchParam, setSearchParam] = useSearchParams();

  /* Combo */
  const [empresa, setEmpresa] = useState<IItemComboBaseDTO[]>([]);
  const [area, setArea] = useState<IItemComboBaseDTO[]>([]);

  /* Filter */
  const [filterEmpresa, setFilterEmpresa] = useState<ISearch>(_initialFilter);
  const [filterGerencia, setFilterGerencia] = useState<ISearch>(_initialFilter);

  /* Filter - Combo Ref */
  const refComboEmpresa = React.useRef<IComboBox>(null);
  const _openComboEmpresa = React.useCallback(
    () => refComboEmpresa.current?.focus(true),
    []
  );

  const refComboGerencia = React.useRef<IComboBox>(null);
  const _openComboGerencia = React.useCallback(
    () => refComboGerencia.current?.focus(true),
    []
  );

  /* Panel Upload - Actualizacion masiva*/
  const [isOpenUpload, { setTrue: openUpload, setFalse: dismissUpload }] =
    useBoolean(false);
  const [fileDetail, setfileDetail] = React.useState<IFile>(null!);
  const [excel, setExcel] = React.useState<FormData>(null!);

  /* Actualizacion manual */
  const [role, setRole] = useState<string[]>([]);
  const [isOpenEdit, { setTrue: openEdit, setFalse: dismissEdit }] =
    useBoolean(false);
  const [personaDetail, setpersonaDetail] = useState<IPersona>(null!);

  /**Panel de busquedas */
  const [
    isOpenPanelSearch,
    { setTrue: OpenPanelSearch, setFalse: dismissPanelSearch },
  ] = useBoolean(false);

  const [isSearch, setisSearch] = useState(false);

  const [blob, setBlob] = useState<IBlob>({
    url: "",
    nombre: "",
    extension: "",
  });

  const [textBlob, setTextBlob] = useState<string>("");

  /* Telerik */
  const [isOpenTelerik, { setTrue: openTelerik, setFalse: dismissTelerik }] =
    useBoolean(false);

  /* Access & Role */
  const { access } = useContext(AuthContext);
  let allRole = access?.role ?? [];
  // console.log("----------------------------------------")
  // console.log(allRole)

  let isRoleActualizarPersonaAdmin = isRoleAccessAll(allRole, roleAdmin());
  let reportTelerik = reportByRole(access as IUserAuthenticate);

  const [isKeyUser, setIskeyUser] = useState(false);
  const [isTransporte, setIsTransporte] = useState(false);
  const [isSSEE, setIsSSEE] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isUsrAdmin, setIsUsrAdmin] = useState(false);

  /* Opcion seleccionada - Rol Admin - 3 submenus */
  const [optionMasivo, setOptionMasivo] = useState<number>(0);

  /* Log actualizacion masiva */
  const [columnLog, setColumnLog] = useState<IColumn[]>([]);
  const [detalleLog, setDetalleLog] = useState<IResultadoCargaMasiva>(null!);
  const [isOpenLog, { setTrue: openLog, setFalse: dismissLog }] =
    useBoolean(false);
  const [
    isOpenReportLog,
    { setTrue: openReportLog, setFalse: dismissReportLog },
  ] = useBoolean(false);

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(
    _initialPagination().currentPage
  );
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);

  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => dataByPager(page),
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });

  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [personaId, setPersonaId] = useState("");
  const [personaTipo, setPersonaTipo] = useState("");

  //panel
  const [
    isOpenPersonaEditar,
    { setTrue: openPersonaEditar, setFalse: dismissPersonaEditar },
  ] = useBoolean(false);
  const [
    isOpenPersonaAdd,
    { setTrue: openPersonaAdd, setFalse: dismissPersonaAdd },
  ] = useBoolean(false);

  /* Hook */
  useEffect(() => {
    if(isOpenPanelSearch === true){
      setupCombo();
    }
    
    reloadInitial();
  }, [isOpenPanelSearch]);

  useEffect(() => {
    if (access) {
      let isRolTransporte = isRol(access, ROLE_AREA_TRANSPORTE);
      let isRolAdmin = isRol(access, ROLE_ADMIN);
      let isRolUsrAdmin = isRol(access, ROLE_USRADMIN);

      let isRolTransporteAdmin = isRol(access, ROLE_AREA_TRANSPORTE_ADMIN);
      // let isRolKeyUser = isRol(access, ROLE_KEY_USER);
      // let isRolSSEE = isRol(access, ROLE_CONTRATISTA);

      if (isRolTransporte) {
        setBlob(BlobHelper.urlActualizacionPersonaTransporte);
        setTextBlob(TEXT_MASIVO_PERSONA_ADM_TRANSPORTE);
      }

      // if (isRolKeyUser) {
      //   setBlob(BlobHelper.urlActualizacionPersonaKeyUser);
      //   setTextBlob(TEXT_MASIVO_PERSONA_KEY_USER);
      // } else if (isRolTransporte) {
      //   setBlob(BlobHelper.urlActualizacionPersonaTransporte);
      //   setTextBlob(TEXT_MASIVO_PERSONA_ADM_TRANSPORTE);
      // } else if (isRolSSEE) {
      //   setBlob(BlobHelper.urlActualizacionPersonaSSEE);
      //   setTextBlob(TEXT_MASIVO_PERSONA_SSEE);
      // }

      setIsTransporte(isRolTransporte);
      setIsUsrAdmin(isRolUsrAdmin);
      // setIskeyUser(isRolKeyUser);
      // setIsSSEE(isRolSSEE);
      setIsAdmin(isRolAdmin);

      if (access.role != undefined) {
        setRole(access.role);
      }

      setIsEdit(isRolTransporte);
    }
  }, [access]);

  useEffect(() => {
    if (isEdit) {
      setTitleColum();
    }
  }, [isEdit]);

  useEffect(() => {
    if (filterEmpresa.search != null) {
      dataEmpresa();
    }
  }, [filterEmpresa]);

  useEffect(() => {
    if (filterGerencia.search != null) {
      dataArea();
    }
  }, [filterGerencia]);

  useEffect(() => {
    if (!isOpenUpload) {
      setfileDetail(null!);
    }
  }, [isOpenUpload]);

  /* Hook Pagination */
  useEffect(() => {
    setPager({
      ...pager,
      currentPage: currentPage,
    });

    if (filter) {
      setFilter({
        ...filter,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setPager({
      ...pager,
      onPageChange: (page) => dataByPager(page),
      onSizeChange: (pageSize) => setPageSize(pageSize),
      total: total,
    });
  }, [total]);

  /* Api */
  const dataPersona = (param = filter) => {
    setData([]);
    setTitleColum();
    // PersonaService.getListaPersonas(param)
    PersonaService.getListaPersonasShort(param)
      .then((res) => {
        if (res.status == 200) {
          setData(res.data.data);
          setTotal(res.data.total);
        }
        setHidden(true);
      })
      .catch((e: IException) => {
        setHidden(true);
        alert(splitExceptionDetail(e));
      });
  };

  const dataEmpresa = () => {
    setEmpresa([]);
    ConfiguracionService.allEmpresa(filterEmpresa)
      .then((res) => {
        if (res.status == 200) {
          let t: IItemComboBaseDTO[] = res.data;
          t.unshift(ItemNoneSelect());

          setEmpresa(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataArea = () => {
    setArea([]);
    OrganigramaService.organigramaByTipo("01", filterGerencia)
      .then((res) => {
        if (res.status == 200) {
          let t: IItemComboBaseDTO[] = res.data;
          t.unshift(ItemNoneSelect());

          setArea(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  /* Api - Actualizacion masiva */
  const actualizarMasivoKeyUser = () => {
    PersonaService.actualizarMasivoKeyUser(excel)
      .then((res) => {
        if (res.status == 200) {
          validateLog(res);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const actualizarMasivoTransporte = () => {
    PersonaService.actualizarMasivoTransporte(excel)
      .then((res) => {
        if (res.status == 200) {
          reloadInitial();
          dismissUpload();
          alert("Se actualizó correctamente");
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const actualizarMasivoSSEE = () => {
    PersonaService.actualizarMasivoSSEE(excel)
      .then((res) => {
        if (res.status == 200) {
          validateLog(res);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  /* Function */
  const reloadInitial = (param = filter) => {
    setHidden(false);
    dataPersona(param);
    setTitleColum();
  };

  const setupCombo = () => {

    dataEmpresa();
    dataArea();
  };

  const _leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_RELOAD,
        onClick: () => reloadInitial(),
      },
    ];

    if (isAdmin) {
      _items.push({
        ...IconHelper.ICON_UPLOAD,
        text: "Cargar",
        subMenuProps: {
          items: [
            {
              ...IconHelper.ICON_FILE_BULK_UPLOAD,
              key: "1",
              text: TEXT_MASIVO_PERSONA_KEY_USER,
              onClick: () => {
                _openMasivoPersona(1);
              },
            },
            {
              ...IconHelper.ICON_FILE_BULK_UPLOAD,
              key: "2",
              text: TEXT_MASIVO_PERSONA_SSEE,
              onClick: () => {
                _openMasivoPersona(2);
              },
            },
            {
              ...IconHelper.ICON_FILE_BULK_UPLOAD,
              key: "3",
              text: TEXT_MASIVO_PERSONA_ADM_TRANSPORTE,
              onClick: () => {
                _openMasivoPersona(3);
              },
            },
          ],
        },
      });
    }
    if (isAdmin || isTransporte || isUsrAdmin) {
      _items.push({
        ...IconHelper.ICON_ADD,
        onClick: () => {
          _openMasivoPersona(4);
        },
      });
    }

    if (isTransporte) {
      _items.push({
        ...IconHelper.ICON_UPLOAD,
        text: textBlob,
        onClick: openUpload,
      });
    }

    return _items;
  };

  const _rigthBar = () => {
    const i: ICommandBarItemProps =
      filter.empresaId != null || filter.areaReporteId != null
        ? { ...IconHelper.ICON_CLFILTER, onClick: OpenPanelSearch }
        : { ...IconHelper.ICON_FILTER, onClick: OpenPanelSearch };

    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_EXCEL,
        onClick: openTelerik,
      },
      { ...i },
    ];

    return _items;
  };

  const actualizarMasivo = () => {
    if (isKeyUser) {
      actualizarMasivoKeyUser();
    } else if (isTransporte) {
      actualizarMasivoTransporte();
    } else if (isSSEE) {
      actualizarMasivoSSEE();
    } else {
      if (isAdmin) {
        switch (optionMasivo) {
          case 1:
            actualizarMasivoKeyUser();
            break;
          case 2:
            actualizarMasivoSSEE();
            break;
          case 3:
            actualizarMasivoTransporte();
            break;
          default:
            break;
        }
      }
    }
  };

  const validateLog = (res) => {
    let d: IResultadoCargaMasiva = res.data;

    dismissUpload();
    reloadInitial();

    if (d.detalle.length == 0) {
      alert("Se actualizó correctamente");
    } else {
      /* Panel resultado expcepciones */
      alert(d.respuesta);

      setTitleDetalleCarga();
      setDetalleLog(d);
      openLog();
    }
  };

  const setTitleDetalleCarga = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Tipo DOI",
        fieldName: "tipoDoi",
        minWidth: 80,
        maxWidth: 100,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column1",
        name: "N. DOI",
        fieldName: "doi",
        minWidth: 80,
        maxWidth: 100,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Observación",
        fieldName: "log",
        minWidth: 1000,
        maxWidth: 1000,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
    ];

    setColumnLog(col);
  };

  const _rigthBarDetalleLog = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_EXCEL,
        onClick: openReportLog,
      },
    ];

    return _items;
  };

  const _openMasivoPersona = (type: number) => {
    setOptionMasivo(type);

    switch (type) {
      case 1:
        setBlob(BlobHelper.urlActualizacionPersonaKeyUser);
        setTextBlob(TEXT_MASIVO_PERSONA_KEY_USER);
        openUpload();
        break;
      case 2:
        setBlob(BlobHelper.urlActualizacionPersonaSSEE);
        setTextBlob(TEXT_MASIVO_PERSONA_SSEE);
        openUpload();
        break;
      case 3:
        setBlob(BlobHelper.urlActualizacionPersonaTransporte);
        setTextBlob(TEXT_MASIVO_PERSONA_ADM_TRANSPORTE);
        openUpload();
        break;

      case 4:
        openPersonaAdd();
        break;
      default:
        break;
    }
  };

  /* Event */
  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        let k: string | null = null;
        switch (type) {
          case "empresa":
            if (option.key != IndexNone) {
              k = String(option.key);
            }

            let fEmp: IFilterPersona = {
              ...filter,
              empresaId: k,
            };

            setFilter(fEmp);
            reloadInitial(fEmp);
            break;
          case "areaReporte":
            if (option.key != IndexNone) {
              k = String(option.key);
            }

            let fGer: IFilterPersona = {
              ...filter,
              areaReporteId: k,
            };

            setFilter(fGer);
            reloadInitial(fGer);
            break;
          default:
            break;
        }
      }
    }
  };

  const setTitleColum = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Foto",
        ariaLabel:
          "Column operations for File type, Press to sort on File type",
        iconName: "-",
        isIconOnly: true,
        fieldName: "foto",
        minWidth: 20,
        maxWidth: 20,
        onRender: (item: IPersona) => (
          <TooltipHost content={`Foto`}>
            <Persona
              text=""
              size={PersonaSize.size24}
              imageUrl={baseImageUrl}
            />
          </TooltipHost>
        ),
      },
      {
        key: "column2",
        name: "Persona",
        fieldName: "persona",
        minWidth: 180,
        maxWidth: 220,
        isRowHeader: true,
        isResizable: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
        onRender: (item: IPersona) => {
          return <span style={{ fontSize: "90%" }}>{item.persona}</span>;
        },
      },
      {
        key: "column3",
        name: "Identificacion",
        fieldName: "nroDOI",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        data: "number",
        isPadded: true,
        onRender: (item: IPersona) => {
          return (
            <span>
              {item.tipoDocumentoIdentidad}: {item.nroDOI}
            </span>
          );
        },
      },
      {
        key: "column4",
        name: "Tipo",
        fieldName: "tipo",
        minWidth: 30,
        maxWidth: 30,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column5",
        name: "Sexo",
        fieldName: "sexo",
        minWidth: 40,
        maxWidth: 40,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column7",
        name: "Empresa",
        fieldName: "empresa",
        minWidth: 140,
        maxWidth: 150,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column6",
        name: "Puesto",
        fieldName: "puesto",
        minWidth: 190,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column8",
        name: "Gerencia",
        fieldName: "gerencia",
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      // {
      //   key: "column9",
      //   name: "Superintendencia",
      //   fieldName: "organigramaDos",
      //   minWidth: 120,
      //   maxWidth: 130,
      //   isResizable: true,
      //   isCollapsible: true,
      //   data: "number",
      // },
      // {
      //   key: "column10",
      //   name: "Email",
      //   fieldName: "email",
      //   minWidth: 170,
      //   maxWidth: 180,
      //   isResizable: true,
      //   isCollapsible: true,
      //   data: "number",
      // },
      {
        key: "estado",
        name: "Estado",
        fieldName: "estado",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isMultiline: true,
        data: "string",
        isPadded: true,
        onRender: (item: IPersona) => (
          <>{estadoPersona(item.codEstado, item.estado)}</>
        ),
      },
      {
        key: "column11",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 90,
        maxWidth: 120,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: IPersona) => (
          <>
            <Stack horizontal tokens={stackTokensHorizontal}>
              <>
                {isRoleActualizarPersonaAdmin && (
                  <TooltipHost content={`Editar`}>
                    <Icon
                      iconName={IconHelper.ICON_EDIT.iconProps?.iconName}
                      style={{ cursor: "pointer" }}
                      className={ThemeBase.classes.iconFont}
                      onClick={() => {
                        setPersonaId(item.personaId);
                        setPersonaTipo(item.tipo);
                        openPersonaEditar();
                      }}
                    />
                  </TooltipHost>
                )}

                <TooltipHost content={`Detalle`}>
                  <Icon
                    iconName={IconHelper.ICON_DETAIL.iconProps?.iconName}
                    style={{ cursor: "pointer" }}
                    className={ThemeBase.classes.iconFont}
                    onClick={() => {
                      navigate(
                        `${ConfigHelper.urlBase()}/personas/${item.personaId}`
                      );
                    }}
                  />
                </TooltipHost>

                {/* {(isKeyUser || isSSEE || isTransporte) && (
                  <TooltipHost content={`Editar`}>
                    <Icon
                      iconName="Edit"
                      style={{ cursor: "pointer" }}
                      className={ThemeBase.classes.iconFont}
                      onClick={() => {
                        setpersonaDetail(item);
                        openEdit();
                      }}
                    />
                  </TooltipHost>
                )} */}
              </>
            </Stack>
          </>
        ),
      },
    ];

    setColumn(col);
  };

  const _onUpCombo = (type: string, ev?: React.KeyboardEvent<IComboBox>) => {
    let { value } = ev?.target as HTMLInputElement;

    switch (type) {
      case "empresa":
        _openComboEmpresa();

        if (filter.empresaId != null) {
          setFilter({
            ...filter,
            empresaId: null,
          });
        }

        setFilterEmpresa({
          ...filterEmpresa,
          search: value,
        });
        break;
      case "areaReporte":
        _openComboGerencia();

        if (filter.areaReporteId != null) {
          setFilter({
            ...filter,
            areaReporteId: null,
          });
        }

        setFilterGerencia({
          ...filterGerencia,
          search: value,
        });
        break;
      default:
        break;
    }
  };

  const _onBlurCombo = (type: string) => {
    switch (type) {
      case "empresa":
        if (filter.empresaId == "" || filter.empresaId == null) {
          setFilterEmpresa({
            ...filterEmpresa,
            search: "",
          });
        }
        break;
      case "areaReporte":
        if (filter.areaReporteId == "" || filter.areaReporteId == null) {
          setFilterGerencia({
            ...filterGerencia,
            search: "",
          });
        }
        break;
      default:
        break;
    }
  };

  /* Event Filter */
  const _onClearSearch = () => {
    setFilter({
      ...filter,
      search: "",
      areaReporteId: null,
      empresaId: null,
    });

    setSearchParam({
      [baseSearch]: "",
    });
  };

  /* Event Pagination */
  const dataByPager = (page) => {
    if (true) {
      setTotal(0);

      setPager({
        ...pager,
        currentPage: page,
      });

      let f: IFilterPersona = {
        ...filter,
        skip: (page - 1) * pageSize,
        take: pageSize,
      };

      setFilter(f);
      setCurrentPage(page);

      reloadInitial(f);
    }
  };

  /* Event Filter */
  const _onChangeTextFilter = (
    ev?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string
  ) => {
    if (newValue != undefined) {
      if (newValue === "") {
        _onClearFilter();
      } else {
        setFilter({
          ...filter,
          search: newValue,
        });
      }
    }
  };

  const _onPressFilter = (newValue: string) => {
    let f: IFilterPersona = {
      ..._initialFilter(),
      search: newValue,
    };

    setFilter(f);
    setCurrentPage(_initialPagination().currentPage);
    reloadInitial(f);
  };

  const _onClearFilter = () => {
    let f: IFilterPersona = {
      ..._initialFilter(),
    };

    setFilter(f);
    reloadInitial(f);
  };

  useEffect(() => {
    filter.empresaId != null || filter.areaReporteId != null
      ? setisSearch(true)
      : setisSearch(false);
  }, [isOpenPanelSearch]);

  const clearPanelSearch = () => {
    dismissPanelSearch();
    _onClearFilter();
  };

  return (
    <>
      <HeadingBar
        title="Lista de Personas"
        subTitle="Gestión de Personas"
        searchChange={_onChangeTextFilter}
        searchHandler={_onPressFilter}
        searchValue={filter.search ?? ""}
        onClearHandler={_onClearFilter}
      ></HeadingBar>

      {/* FILTRO PARA LISTA PERSONAS */}
      <PanelGenerico
        isOpen={isOpenPanelSearch}
        onDismiss={dismissPanelSearch}
        isOnRenderHeader
        disabledFooter={false}
        iconHeaderName={IconHelper.ICON_FILTERS.filterPanel}
        title="Filtro Personalizado"
        subTitle=" Elija las condiciones para filtro personalizado"
        textRegister="Buscar"
        cancelar={clearPanelSearch}
        registrar={dismissPanelSearch}
        disabledIconHeader={false}
      >
        <div
          className="ms-Grid"
          dir="ltr"
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
            paddingTop: "5%",
          }}
        >
          {role[0] != ROLE_CONTRATISTA && (
            <>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                  <ComboBox
                    componentRef={refComboEmpresa}
                    label="Empresa"
                    allowFreeform
                    autoComplete="off"
                    useComboBoxAsMenuWidth
                    calloutProps={{ directionalHintFixed: true }}
                    options={empresa}
                    selectedKey={filter ? filter.empresaId : ""}
                    onChange={(e, o) => _onChangeCombo("empresa", e, o)}
                    onKeyUp={(e) => _onUpCombo("empresa", e)}
                    onBlur={() => _onBlurCombo("empresa")}
                  />
                </div>
              </div>
              <br />
            </>
          )}

          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              {role[0] != ROLE_KEY_USER_EMPLEADO && (
                <ComboBox
                  componentRef={refComboGerencia}
                  label="Gerencia"
                  allowFreeform
                  autoComplete="off"
                  useComboBoxAsMenuWidth
                  calloutProps={{ directionalHintFixed: true }}
                  options={area}
                  selectedKey={filter ? filter.areaReporteId : ""}
                  onChange={(e, o) => _onChangeCombo("areaReporte", e, o)}
                  onKeyUp={(e) => _onUpCombo("areaReporte", e)}
                  onBlur={() => _onBlurCombo("areaReporte")}
                />
              )}
            </div>
          </div>
        </div>
      </PanelGenerico>

      <TableBase
        leftBar={_leftBar()}
        rigthBar={_rigthBar()}
        data={data}
        column={column}
        isLoading={!hidden}
        pagination={pager}
      ></TableBase>

      {role[0] == ROLE_KEY_USER_EMPLEADO ||
        (role[0] == ROLE_KEY_USER_SOCIO && (
          <EditarPersonaKeyUser
            openPanel={isOpenEdit}
            dismissPanel={dismissEdit}
            dataDetail={personaDetail}
            setup={dataPersona}
          ></EditarPersonaKeyUser>
        ))}
      {role[0] == ROLE_TRANSPORTE && (
        <EditarPersonaEmpTrans
          openPanel={isOpenEdit}
          dismissPanel={dismissEdit}
          dataDetail={personaDetail}
          setup={reloadInitial}
        ></EditarPersonaEmpTrans>
      )}

      {role[0] == ROLE_CONTRATISTA && (
        <EditarPersonaSEE
          openPanel={isOpenEdit}
          dismissPanel={dismissEdit}
          dataDetail={personaDetail}
          setup={dataPersona}
        ></EditarPersonaSEE>
      )}

      {/* Panel - Reporte - Telerik */}
      <RepoteBaseGenerico
        headerName="Personas"
        NameReport={reportTelerik.reportName}
        Parameters={reportTelerik.param}
        type={PanelType.large}
        openTelerik={isOpenTelerik}
        dismissTelerik={dismissTelerik}
      ></RepoteBaseGenerico>

      {/* Actualizacion masiva */}
      <PanelUpload
        header={textBlob}
        isOpen={isOpenUpload}
        dissmiss={dismissUpload}
        fileDownload={blob}
        fileDetail={fileDetail}
        file={excel}
        updateFileDetail={(detail) => setfileDetail(detail)}
        updateFile={(file) => setExcel(file)}
        upload={actualizarMasivo}
      ></PanelUpload>

      {/* Panel Detalle Log actualizacion masiva */}
      <Panel
        isOpen={isOpenLog}
        onDismiss={dismissLog}
        headerText="Observaciones"
        closeButtonAriaLabel="Close"
        type={PanelType.extraLarge}
        isFooterAtBottom={true}
      >
        <div>
          <HeadingBar
            title="Datos no actualizados"
            subTitle="Resultado actualización masiva"
            searchShow={false}
          ></HeadingBar>
          <TableBase
            leftBar={_rigthBarDetalleLog()}
            column={columnLog}
            data={detalleLog ? detalleLog.detalle : []}
            isLoading={false}
          ></TableBase>
        </div>
      </Panel>

      {/* Report Log */}
      {detalleLog && (
        <RepoteBaseGenerico
          headerName={`Resultado actualización masiva`}
          NameReport="MOVDetalleLogPersona.trdx"
          Parameters={{ LogId: detalleLog.id }}
          openTelerik={isOpenReportLog}
          dismissTelerik={() => {
            openLog();
            dismissReportLog();
          }}
        ></RepoteBaseGenerico>
      )}

      <div id="panelEditarPersona">
        <>
          <PanelActualizarPersona
            personaId={personaId}
            personaTipo={personaTipo}
            isOpenPanel={isOpenPersonaEditar}
            dismissPanel={dismissPersonaEditar}
            reloadParent={reloadInitial}
          />
        </>
      </div>

      <div id="panelAgregarPersona">
        <>
          <PanelAgregarPersona
            isOpenPanel={isOpenPersonaAdd}
            dismissPanel={dismissPersonaAdd}
            reloadParent={reloadInitial}
          />
        </>
      </div>
    </>
  );
};
