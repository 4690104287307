import {
  Stack,
  CompoundButton,
  FontIcon,
  FontSizes,
  PrimaryButton,
} from "@fluentui/react";
import { useContext, useEffect, useRef, useState } from "react";
import MicrosoftLogin from "react-microsoft-login";
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../auth/context/auth-context";
import { IMicrosoft } from "../../auth/interface/IAuth";
import AuthService from "../../services/AuthService";
import { LOGO_TESEO, stackTokens } from "../../utils/Helper/helper";
import ThemeBase from "../../utils/Theme/themeBase";
import LOGO_MMG from "../../logo/LOGO_MMG.png";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import HCaptcha from "@hcaptcha/react-hcaptcha";

interface TokenClaims {
  [key: string]: any;
}

declare global {
  interface Window {
    grecaptcha: {
      enterprise: {
        execute: (
          siteKey: string,
          options: { action: string }
        ) => Promise<string>;
      };
    };
  }
}

export const Home = ({ toggleHome = () => {} }) => {

  const [isLoginInProgress, setIsLoginInProgress] = useState(false);

  const navigate = useNavigate();
  const { signin } = useContext(AuthContext);
  const [HChaptchaToken, setHChaptchaToken] = useState(null);
  const [loginDisabled, setLoginDisabled] = useState(false); // cambiar estado para habilitar botones de login

  const captchaRef = useRef<HCaptcha>(null);

  const onVerifyCaptcha = (token, ekey) => {
    console.log("Verified: " + token);
    setHChaptchaToken(token);
    console.log("Verified Key: " + ekey);
  };
  const onLoad = () => {
    // https://docs.hcaptcha.com/configuration#jsapi
    if (captchaRef != null) {
      captchaRef?.current?.execute();
    }
  };

  useEffect(() => {
    if (HChaptchaToken != null) {
      setLoginDisabled(false);
    }

    const hash = window.location.hash;
    const params = new URLSearchParams(hash.substring(1));
    const token = params.get('id_token');

    if (token) {
      //setIdToken(token);
      // Optionally, store the token in local storage
      //localStorage.setItem('id_token', token);
      console.log("Token: ", token);
      const result: TokenClaims = jwtDecode(token);
      console.log("decodedClaims: ", result);
      let tokenReceived: IMicrosoft = {
        appid: result.aud,
        exp: result.exp as number,
        unique_name: result.preferred_username,
      };

      if (exp != tokenReceived.exp) {
          setExp(tokenReceived.exp);
          _handleLoginMicrosoft(tokenReceived);
        }
        setExp(tokenReceived.exp);
    }

    

  }, [HChaptchaToken]);
  //#endregion

  const [exp, setExp] = useState(0);
  const [verLoading, setVerLoading] = useState(false);
  const [excepcion, setExcepcion] = useState(null);

  const handleLoginClick = () => {
    if (!isLoginInProgress) {
      setIsLoginInProgress(true);
      // Trigger the login process
    }
  };

  const responseMicrosoft = (err: any, result: any, instance: any) => {

    // if (!isLoginInProgress) {
    //setIsLoginInProgress(false);
    console.log("Muestrame los errores X")
    setIsLoginInProgress(false);
    /*     console.log("Muestrame los errores", err)
    console.log("Muestrame los result", result)
    console.log("Muestrame los instance", instance) */
    if (err) {
      setExcepcion(err);
      console.log("Muestrame los errores", err);
      return;
    }

    if (result) {
      console.log("Muestrame los result", result);
      if (result.idTokenClaims) {
        let tokenReceived: IMicrosoft = {
          appid: result.idTokenClaims.aud,
          exp: result.idTokenClaims.exp as number,
          unique_name: result.idTokenClaims.preferred_username,
        };

        if (exp != tokenReceived.exp) {
          setExp(tokenReceived.exp);
          _handleLoginMicrosoft(tokenReceived);
        }
        setExp(tokenReceived.exp);
      }
    }
  //}
  };

  const _handleLoginMicrosoft = (token: IMicrosoft) => {
    setIsLoginInProgress(false);
    setVerLoading(true);
    AuthService.loginMicrosoft(token)
      .then((response) => {
        if (response.status == 200) {
          let res = JSON.parse(response.request.response);
          if (res.succeeded) {
            setExcepcion(null);
            if (res.accessToken != undefined && res.accessToken !== "") {
              signin(
                {
                  username: token.unique_name,
                  token: res.accessToken,
                },
                () => navigate(ConfigHelper.urlBase(true), { replace: true })
              );
            }
          } else {
            setExcepcion(res.exception);
          }
        }
        setVerLoading(false);
      })
      .catch((e: Error) => {
        console.log(e);
        if (e != undefined) {
          setExcepcion(e.message as any);
        }
        setVerLoading(false);
      });
  };

  const styleInDisabled = {
    border: "1px solid #faf9f8",
    backgroundColor: "#ffffff",
    color: "#333333",
  };

  const [tReCaptcha, setTReCaptcha] = useState("");

  // const handleSubmit = (event: React.FormEvent) => {
  //   event.preventDefault();

  //   // Aquí puedes verificar la respuesta del reCaptcha y enviar el formulario
  //   window.grecaptcha.enterprise
  //     //.execute("6Lf5lCMpAAAAAEuGVqC2ET6DtxQ_siYhvjoT4zVM", { action: "submit" })
  //     .execute("6Le2AzMpAAAAAE1QA-tdoJxLiOH2Yz7CN-JtILrV", { action: "submit" })
  //     .then((token: string) => {
  //       // Aquí puedes verificar la respuesta del reCaptcha utilizando el token
  //       if (token) {
  //         // El reCaptcha se ha completado, puedes enviar el formulario
  //         // También puedes enviar el token a tu servidor para su verificación adicional si es necesario
  //         //console.log("Token de reCaptcha:", token);
  //         setTReCaptcha(token);
  //         // Aquí puedes enviar el formulario o realizar otras acciones
  //       } else {
  //         // El reCaptcha no se ha completado, puedes mostrar un mensaje de error
  //         console.log("El reCaptcha no se ha completado");
  //       }
  //     });
  // };

  useEffect(() => {
    const container = document.getElementById("recaptcha-container");
    if (container) {
      // Inicializar reCaptcha aquí
      const script = document.createElement("script");
      // script.src =
      //   "https://www.google.com/recaptcha/enterprise.js?render=6Lf5lCMpAAAAAEuGVqC2ET6DtxQ_siYhvjoT4zVM";
      script.src =
        "https://www.google.com/recaptcha/enterprise.js?render=6Le2AzMpAAAAAE1QA-tdoJxLiOH2Yz7CN-JtILrV";
      script.async = true;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return (
    <>
      {/* <div id="recaptcha-container">
        <form onSubmit={handleSubmit}>
          <br></br>
          <Stack tokens={stackTokens}>
            <PrimaryButton
              ariaDescription="Detailed description used for screen reader."
              type="submit"
              //onClick={_handleLogin}
            >
              Ingresar
            </PrimaryButton>
          </Stack>
        </form>
      </div> */}
      <div
        style={{
          height: "100vh",
          backgroundColor: ThemeBase.theme.palette.neutralLighter,
        }}
      >
        <div
          style={{
            display: "flex",
            paddingTop: "5%",
          }}
        >
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <div style={ThemeBase.parentFlexEnd}>
                  <div
                    style={{
                      marginLeft: "auto",
                      color: "red",
                    }}
                  >
                    <img
                      style={{
                        height: "50px",
                        width: "90px",
                      }}
                      src={LOGO_MMG}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <div
                  style={{
                    paddingRight: "10px",
                    paddingLeft: "15px",
                  }}
                >
                  <div style={{ fontSize: FontSizes.size24 }}>Bienvenido</div>
                  <br />
                  <br />
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2">
                        Cliente:
                      </div>
                      <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg10">
                        MMG Las Bambas
                      </div>
                    </div>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2">
                        Proceso:
                      </div>
                      <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg10">
                        Movilización Sanitaria
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                style={{
                  // ...ThemeBase.justifiedStyle,
                  paddingTop: "15%",
                }}
              >
                <Stack horizontal tokens={stackTokens}>
                  <div
                  //style={{ pointerEvents: loginDisabled ? "none" : "auto" }}
                  >
                    <MicrosoftLogin
                      //Produccion
                      //clientId="01144f97-a8ce-47ea-962c-5d71f18344cb"
                      clientId="b12c97d6-8697-43cd-bfd5-301c96d70f82"
                      //QAS
                      //clientId="09b7fdd4-0946-436d-9a7d-5196da53caf4"
                      //authCallback={responseMicrosoft}
                      authCallback={(err, data) => {
                        if (!isLoginInProgress) {
                          responseMicrosoft(err, data,null);
                        }
                      }}
                      // graphScopes={["User.Read", "Mail.Read"]}
                      withUserData={false}
                      //useLocalStorageCache={true}
                      // redirectUri="https://mmg-bso-dev-web25e3.azurewebsites.net" //qas
                      redirectUri="https://miviaje.lasbambas.com/login"
                      forceRedirectStrategy={true}
                      useLocalStorageCache={true}
                      //redirectUri="http://localhost:3000/login"
                      // tenantUrl="https://login.microsoftonline.com/1acabffb-702b-47dd-b73b-206d2a5234e0"
                      // prompt="login"
                    >
                      <CompoundButton
                        secondaryText=""
                        disabled={loginDisabled}
                        checked={false}
                        onClick={handleLoginClick}
                        //styles={!loginDisabled ? ThemeBase.buttonHome : {}}
                        styles={ThemeBase.buttonHome}
                        //style={loginDisabled ? styleInDisabled : {}}
                        style={{}}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <FontIcon
                              aria-label={"AddFriend"}
                              iconName={"AddFriend"}
                              className={ThemeBase.classes.icon}
                            />
                            <div>MMG</div>
                          </div>
                        </div>
                      </CompoundButton>
                    </MicrosoftLogin>
                  </div>

                  <div id="recaptcha-container">
                    <CompoundButton
                      secondaryText=""
                      //disabled={loginDisabled}
                      checked={false}
                      onClick={toggleHome}
                      // styles={!loginDisabled ? ThemeBase.buttonHome : {}}
                      styles={ThemeBase.buttonHome}
                      // style={loginDisabled ? styleInDisabled : {}}
                      style={{}}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <FontIcon
                            aria-label={"Group"}
                            iconName={"Group"}
                            className={ThemeBase.classes.icon}
                          />
                          <div>SSEE</div>
                        </div>
                      </div>
                    </CompoundButton>
                  </div>
                </Stack>
                <br />
                {/* <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg2">
                    <HCaptcha
                      sitekey="2134267e-63e8-46c6-bb2f-be36b137ae1e"
                      ref={captchaRef}
                      onLoad={onLoad}
                      onVerify={onVerifyCaptcha}
                      languageOverride="es"
                      size="normal"
                      theme="dark"
                    />
                  </div>
                </div> */}
              </div>
            </div>

            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <div className="ms-Grid-row">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <div style={ThemeBase.parentFlexEnd}>
                  <div
                    style={{
                      marginLeft: "auto",
                      color: "red",
                    }}
                  >
                    <img
                      style={{
                        height: "65px",
                        width: "65px",
                      }}
                      src={LOGO_TESEO}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
