import {
  FocusTrapZone,
  ICommandBarItemProps,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import { TelerikReportViewer } from "@progress/telerik-react-report-viewer";
import { CustomCommandBar } from "../../components/Generic/customCommandBar";
import HeaderGeneric from "../../components/Generic/headerPanel";
import { urlTelerik } from "../../services/http-common";
import { IconHelper } from "../../utils/Helper/iconHelper";
import ThemeBase from "../../utils/Theme/themeBase";
import TokenService from "../../services/TokenService";
import { useEffect, useRef, useState } from "react";
import TablaReporte from "../../components/Table/TableReporte";
import { IExportarReporte } from "../../interfaces/base/IBase";
import reporteServicio from "../../services/reporteService";
import AuthService from "../../services/AuthService";
import { keyAccess } from "../../utils/Helper/helper";

const NameReport = "MOVCuadroMandoTrasporte.trdx";

const CuadroMandoTransporte = () => {
  let t = TokenService.getToken();
  const viewerRef = useRef(); //equivalnete id de componente
  const _rigthBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_INFO,
        // onClick: ,
      },
    ];
    return _items;
  };

  const EnviarToken = () => {
    var { viewerObject } =
      viewerRef.current != null ? viewerRef.current : viewerObject;
    viewerObject.authenticationToken(t);
  };

  useEffect(() => {
    EnviarToken();
  }, [t]);

  const [key, setKey] = useState(0);
  
  const recargarComponente = () => {
    setKey(prevKey => prevKey + 1);
  };

  function descargarReporte() {
    const reporteExportar: IExportarReporte = {
      report: NameReport,
      Extension: "PDF"
    }

    reporteServicio.exportaReporte(reporteExportar)

      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          localStorage.removeItem(keyAccess);
          AuthService.removeToken();
          window.location.assign("/login");
        }
        if (response.status === 200) {
          console.log("Datos de la respuesta: Reporte generado ----");

          const dataUrl = response.data;

          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = NameReport.split('.')[0] + '.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

        }
        else {
          console.error("Status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const _items: ICommandBarItemProps[] = [
    {
      ...IconHelper.ICON_RELOAD,
      onClick: recargarComponente,
    }
  ];

  const _farItems: ICommandBarItemProps[] = [
    {
      ...IconHelper.ICON_PDF,
      onClick: () => {
        descargarReporte();
      },
    },
  ];

  useEffect(() => {
    const hideExportButton = () => {
        const exportButton: any = document.querySelector("#trv-main-menu-export-command");
        // console.log(exportButton)

        if (exportButton) {
            // console.log(exportButton)
            exportButton.remove();
            // exportButton.style.display = 'none';
            clearInterval(interval);
        }
    };

    // Usa un intervalo para asegurar que el botón sea encontrado y ocultado
    const interval = setInterval(hideExportButton, 1);

    // Limpia el intervalo al desmontar el componente
    return () => clearInterval(interval);
  }, [key]);

  return (
    <>
      <div className="ms-Grid" dir="ltr">
        <br />
        <div className="ms-Grid-row">
          <div style={{paddingLeft: "10px"}}>
            <TablaReporte
              title={"Cuadro Mando Transporte"}
              subTitle={"Gestión transporte"}
              rigthBar={_farItems}
              leftBar={_items}
              horizontalAlign="start"
              iconName="BarChartVerticalFill"
            />
          </div>
          </div>
        <br />
      </div>

      <FocusTrapZone
        disabled={false}
        isClickableOutsideFocusTrap
        forceFocusInsideTrap={true}>
        <TelerikReportViewer
          ref={viewerRef}
          serviceUrl={urlTelerik}
          reportSource={{
            parameters: { editors: { multiSelect: {} } },
            report: NameReport,
          }}
          viewerContainerStyle={ThemeBase.telerikStyle}
          viewMode="INTERACTIVE"
          scaleMode="FIT_PAGE_WIDTH"
          scale={1.0}
          enableAccessibility={false}
        />
      </FocusTrapZone>
    </>
  );
};

export default CuadroMandoTransporte;
