import {  ComboBox, DatePicker, IComboBox, IComboBoxOption, ICommandBarItemProps } from "@fluentui/react";
import { TelerikReportViewer } from "@progress/telerik-react-report-viewer";
import { useEffect, useRef, useState } from "react";
import { CustomCommandBar } from "../../components/Generic/customCommandBar";
import { urlTelerik } from "../../services/http-common";
import { _formatDatePrefix } from "../../utils/Helper/datetimeHelper";
import ThemeBase from "../../utils/Theme/themeBase";
import { IParametro, IReportTelerik } from "../../interfaces/base/IBase";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { dayBeforeReport, keyAccess } from "../../utils/Helper/helper";
import TokenService from "../../services/TokenService";
import reporteServicio from "../../services/reporteService";
import { PanelGenerico } from "../../components/Generic/PanelGenerico";
import { useBoolean } from '@fluentui/react-hooks';
import React from "react";
import { Combobox, Option } from "@fluentui/react-components";
import AuthService from "../../services/AuthService";

const NameReport = "MOVCuadroMandoProgramaciones.trdx";

const CuadroMandoProgramacion = () => {
  let t = TokenService.getToken();
  let viewer;

  let end = new Date();
  let start = new Date(new Date().setDate(end.getDate() - dayBeforeReport));

  let actividades = "Subida";
  let tipos = "MMG";
  let programados = "Progamados"

  const [inicio, setInicio] = useState<Date | undefined>(start);
  const [fin, setFin] = useState<Date | undefined>(end);
  // const [actividad, setActividad] = useState<string>("Subida"); 
  // const [tipo, setTipo] = useState<string>("MMG");
  // const [procesos, setProcesos] = useState<string>("Progamados");

  const [key, setKey] = useState(0);

  const [reportTelerik, setReportTelerik] = useState<IReportTelerik>({
    reportName: NameReport,
    param: {
      fechInicio: start,
      fechFin: end,
      actividad: ["Subida"], 
      tipo: ["MMG"],
      procesos: ["Progamados"]
    },
  });

  const [
    isOpenPanelFiltro,
    { setTrue: openPanelFiltro, setFalse: dismissPanelFiltro },
  ] = useBoolean(false);

  /* Hook */
  useEffect(() => {
    let d = reportTelerik;
    let p = {
      ...d.param,
      fechInicio: inicio,
    };

    setReportTelerik({
      ...reportTelerik,
      param: p,
    });
  }, [inicio]);

  useEffect(() => {
    let d = reportTelerik;
    let p = {
      ...d.param,
      fechFin: fin,
    };

    setReportTelerik({
      ...reportTelerik,
      param: p,
    });
  }, [fin]);

  const optionsActividad: IComboBoxOption[] = [
    { key: 'Subida', text: 'Subida' },
    { key: 'Bajada', text: 'Bajada' },
  ];

  const optionsTipo: IComboBoxOption[] = [
    { key: 'MMG', text: 'MMG' },
    { key: 'SSEE', text: 'SSEE' },
  ];

  const optionsProcesos: IComboBoxOption[] = [
    { key: 'Progamados', text: 'Progamados' },
    { key: 'Abordados', text: 'Abordados' },
  ];

  const _rigthBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_SEARCH,
        onClick: () => {

          let rs = {
            report: reportTelerik.reportName,
            parameters: reportTelerik.param,
          };

          viewer.setReportSource(rs);
          viewer.refreshReport();
        },
      },
      {
        ...IconHelper.ICON_PDF,
        onClick: () => {

          let rs = reportTelerik.param;
          
          descargarReporte(rs);

        },
      },
      {
        ...IconHelper.ICON_FILTER,
        onClick: () => {
            openPanelFiltro();
        },
      }
    ];

    return _items;
  };

  const transformarParametros = (parametros: Record<string, string>): IParametro[] => {
    return Object.entries(parametros).map(([key, value]) => ({
        Key: key,
        Value: value,
    }));
  };

  const _closePanel = () => {
    _back();
    dismissPanelFiltro();
  };

  const _back = () => {

    setReportTelerik({
      reportName: NameReport,
      param: {
        fechInicio: start,
        fechFin: end,
        actividad: ["Subida"], 
        tipo: ["MMG"],
        procesos: ["Progamados"]
      },
    })
    setKey(prevKey => prevKey + 1);
    dismissPanelFiltro();
  };

  function descargarReporte(rs) {
    const parametrosactualizar = transformarParametros(rs);
    const reporteExportar = {
      report: NameReport,
      Extension: "PDF",
      parametros: parametrosactualizar
    };
  
    reporteServicio.exportaReporte(reporteExportar)
      .then((response) => {
        if (response.status === 401 || response.status === 403) {
          localStorage.removeItem(keyAccess);
          AuthService.removeToken();
          window.location.assign("/login");
        }
        if (response.status === 200) {
          console.log("Datos de la respuesta: Reporte generado ----");
  
          const dataUrl = response.data;

          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = NameReport.split('.')[0] + '.pdf';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error("Status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  const EnviarToken = () => {
    if (viewer.current) {
      const viewerObject = viewer.current.viewerObject;
      viewerObject.authenticationToken(t);
    }
  };

  useEffect(() => {
    EnviarToken();
  }, [t]);

  useEffect(() => {
    const hideExportButton = () => {
        const exportButton: any = document.querySelector("#trv-main-menu-export-command");

        if (exportButton) {
            exportButton.remove();
            clearInterval(interval);
        }
    };
    const interval = setInterval(hideExportButton, 1);
    return () => clearInterval(interval);
  }, [key]);

  const changeFilter = () => {

    let rs = {
      report: reportTelerik.reportName,
      parameters: reportTelerik.param,
    };

    viewer.setReportSource(rs);
    viewer.refreshReport();
    setKey(prevKey => prevKey + 1);
    dismissPanelFiltro();
    
  };

  return (
    <>
      <div>
        <div className="ms-Grid" dir="ltr">
          <br />
          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
              <div
                className="ms-fontSize-18"
                style={{ color: ThemeBase.theme.palette.neutralSecondary }}
              >
                Cuadro Mando Programaciones
              </div>
            </div>
            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">{/* Nada */}</div>
          </div>

          <div className="ms-Grid-row">
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              <CustomCommandBar
                farItem={_rigthBar()}
                style={{
                  ...ThemeBase.notPaddingSide,
                  paddingTop: "10px",
                }}
              ></CustomCommandBar>
            </div>
          </div>
          <br />
        </div>

        <div className="ms-Grid" dir="ltr">
          <TelerikReportViewer
            ref={(el) => (viewer = el)}
            serviceUrl={urlTelerik}
            reportSource={{
              parameters: reportTelerik.param,
              report: reportTelerik.reportName,
            }}
            viewerContainerStyle={ThemeBase.telerikStyle}
            viewMode="INTERACTIVE"
            scaleMode="FIT_PAGE_WIDTH"
            scale={1.0}
            enableAccessibility={true}
            authenticationToken={t}
          />
        </div>
      </div>

      <PanelGenerico
        title={"Filtros"}
        isOpen={isOpenPanelFiltro}
        isOnRenderHeader={true}
        onDismiss={_closePanel}
        cancelar={_back}
        textRegister="Filtrar"
        registrar={changeFilter}
      >

        <div>
          <DatePicker
            placeholder="Fecha Inicio *"
            allowTextInput
            ariaLabel="Select a date. Input format is day slash month slash year."
            value={inicio}
            onSelectDate={setInicio as (date?: any) => void}
            formatDate={(d) => _formatDatePrefix("F. Inicio", d)}
          />
        </div>
        <br />
        <div>
          <DatePicker
            placeholder="Fecha Fin *"
            allowTextInput
            ariaLabel="Select a date. Input format is day slash month slash year."
            value={fin}
            onSelectDate={setFin as (date?: any) => void}
            formatDate={(d) => _formatDatePrefix("F. Fin", d)}
          />
        </div>
        <br />
        <div>
        <ComboBox
          defaultSelectedKey="C"
          label="Seleccione una o más Actividades"
          selectedKey={reportTelerik.param.actividad}
          multiSelect
          options={optionsActividad}
          allowFreeform
          onItemClick={(e, option) => {
            let reportTelerikModel = { ...reportTelerik };
            const isSelected = reportTelerikModel.param.actividad.includes(option?.key);
            if (isSelected) {
              reportTelerikModel.param.actividad = reportTelerikModel.param.actividad.filter(
                key => key !== option?.key
              );
            } else {
              reportTelerikModel.param.actividad = [
                ...reportTelerikModel.param.actividad,
                option?.key,
              ];
            }
            setReportTelerik(reportTelerikModel);
            console.log(reportTelerikModel.param.actividad);
          }}
        />
        </div>
        <br />
        <div>
          <ComboBox
            label="Seleccione una o más Procesos"
            selectedKey={reportTelerik.param.tipo}
            multiSelect
            options={optionsTipo}
            allowFreeform
            onItemClick={(e, option) => {
              let reportTelerikModel = { ...reportTelerik };
              const isSelected = reportTelerikModel.param.tipo.includes(option?.key);
              if (isSelected) {
                reportTelerikModel.param.tipo = reportTelerikModel.param.tipo.filter(
                  key => key !== option?.key
                );
              } else {
                reportTelerikModel.param.tipo = [
                  ...reportTelerikModel.param.tipo,
                  option?.key,
                ];
              }
              setReportTelerik(reportTelerikModel);
              console.log(reportTelerikModel.param.tipo);
            }}
          />
        </div>
        <br />
        <div>
          <ComboBox
            label="Seleccione una o más Procesos"
            selectedKey={reportTelerik.param.procesos}
            multiSelect
            options={optionsProcesos}
            allowFreeform
            onItemClick={(e, option) => {
              let reportTelerikModel = { ...reportTelerik };
              const isSelected = reportTelerikModel.param.procesos.includes(option?.key);
              if (isSelected) {
                reportTelerikModel.param.procesos = reportTelerikModel.param.procesos.filter(
                  key => key !== option?.key
                );
              } else {
                reportTelerikModel.param.procesos = [
                  ...reportTelerikModel.param.procesos,
                  option?.key,
                ];
              }
              setReportTelerik(reportTelerikModel);
              console.log(reportTelerikModel.param.procesos);
            }}
          />
        </div>
      </PanelGenerico>
    </>
  );
};

export default CuadroMandoProgramacion;
