import { IBlob } from "../../interfaces/base/IBase";

const urlCargaProgramacionPersona: IBlob = {
    url: 'PlantillaInscripcion.xlsx',
    nombre: 'CargaInscripcionPersona',
    extension: 'xlsx',
}

const urlRetiroProgramacionPersona: IBlob = {
    url: 'PlantillaRetiro.xlsx',
    nombre: 'RetiroMasivoPersona',
    extension: 'xlsx',
}

const urlCargaPruebaCovid: IBlob = {
    url: 'CargaPruebaCovid.xlsx',
    nombre: 'CargaPruebaCovid',
    extension: 'xlsx',
}

const urlActualizacionPersonaKeyUser: IBlob = {
    url: 'actualizacionPersonaKeyUser_U.xlsx',
    nombre: 'ActualizacionPersonaKeyUser',
    extension: 'xlsx',
}

const urlActualizacionPersonaTransporte: IBlob = {
    url: 'actualizacionPersonaTransporte_U.xlsx',
    nombre: 'ActualizacionPersonaTransporte',
    extension: 'xlsx',
}

const urlActualizacionPersonaSSEE: IBlob = {
    url: 'actualizacionPersonaSSEE_U.xlsx',
    nombre: 'ActualizacionPersonaSSEE',
    extension: 'xlsx',
}

const urlActualizarDatosHelo: IBlob = {
    url: 'actualizacionDatosHeloQAS.xlsx',
    nombre: 'ActualizacionDatosHelo',
    extension: 'xlsx',
}

export const BlobHelper = {
    urlCargaProgramacionPersona,
    urlCargaPruebaCovid,
    urlActualizacionPersonaKeyUser,
    urlActualizacionPersonaTransporte,
    urlActualizacionPersonaSSEE,
    urlActualizarDatosHelo,
    urlRetiroProgramacionPersona
}