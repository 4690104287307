import { MarqueeSelection } from "@fluentui/react/lib/MarqueeSelection";
import { ActivityItem, DialogType, FontIcon, IActivityItemProps, Label, Link, Panel, PanelType, Pivot, PivotItem, ProgressIndicator, Toggle, DatePicker, TextField, SharedColors } from '@fluentui/react';
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import { DetailsList, DetailsListLayoutMode, SelectionMode, Selection, IColumn, IDetailsListProps, IDetailsRowStyles, DetailsRow, IDetailsRowFieldsProps, DetailsRowFields, IDetailsRowCheckProps, DetailsRowCheck, IDetailsRowCheckStyles } from "@fluentui/react/lib/DetailsList";
import { createTheme, ITheme } from "@fluentui/react/lib/Styling";
import { ComboBox, IComboBox, IComboBoxOption, ICommandBarItemProps, Icon, IconButton, Modal, Persona, PersonaSize, Stack, TooltipHost } from "@fluentui/react";
import PersonaService from "../../services/PersonaService";
import { useParams } from "react-router";
import { useLocation } from "react-router-dom";
import SpinnerCenter from "../../components/Generic/SpinnerCenter";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "@fluentui/react-hooks";
import { HeadingBar } from "../../components/Generic/HeadingBar";
import React, { useContext, useEffect, useState } from "react";
import { IChartProgramacion, IFilterProgramacion, IProgramacionPersona, IResultadoCargaMasiva, IRetirarPersonaMasivo, ISharedProgramacionPersona, IValidarKeyUser } from "../../interfaces/IProgramacion";
import { baseImageUrl, downloadBlob, splitExceptionDetail, stackTokens, stackTokensHorizontal, _initialFilter, _initialPagination, _onRenderHeader, _onRenderRow } from "../../utils/Helper/helper";
import { ButtonForm } from "../../components/Generic/buttonForm";
import { IException, IFile, IIconChart, IItemComboBaseDTO, IReportTelerik, ISearch } from "../../interfaces/base/IBase";
import MotivoService from "../../services/motivoService";
import ProgramacionPersonaService from "../../services/programacionPersonaService";
import saveAs from "file-saver";
import { CustomCommandBar } from "../../components/Generic/customCommandBar";
import { CLAIM_EMPRESA_USUARIO_ID, CLAIM_PERSONA_ID, isRoleAccessAll, roleAdmin, ROLE_ADMIN, ROLE_AGENCIA_VUELO, ROLE_AREA_TRANSPORTE, ROLE_AREA_TRANSPORTE_ADMIN, ROLE_CONTRATISTA, ROLE_KEY_USER_EMPLEADO, ROLE_KEY_USER_SOCIO, ROLE_TRANSPORTE, ROLE_UNIDAD_MEDICA, ROLE_USUARIO } from "../../utils/Helper/roleHelper";
import { TableBase } from "../../components/Table/TableBase";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { setVirtualParent } from "@fluentui/dom-utilities";
import ThemeBase from "../../utils/Theme/themeBase";
import { IconCard } from "../../components/Graphic/IconCard/iconCard";
import ProgramacionService from "../../services/ProgramacionService";
import BasicBarChartObsModelo from "../../components/Graphic/D3Js/BasicBarChart/BasicBarChartObsModelo";
import { Types } from "../../components/Graphic/D3Js/BasicBarChart/types";
import { BlobHelper } from "../../utils/Helper/blobHelper";
import RepoteBase from "../../components/Generic/ReporteBase";
import { IDataCollection, IPagination } from "../../interfaces/base/ITable";
import { _initialDataCollection } from "../../utils/Helper/tableHelper";
import { AuthContext } from "../../auth/context/auth-context";
import { ModalHelper } from "../../utils/Helper/modalHelper";
import { NewAccessPerson } from "../persona/NewAccessPerson";
import IdicadorProgreso from "../../components/Generic/IndicadorProgreso";
import { PaginationFooter } from "../../components/PaginationFooter/PaginationFooter";
import { DialogBase } from "../../components/Dialog/DialogBase";
import { useId } from '@fluentui/react-hooks';
import { TabAccesoPersonaVuelo } from "./vuelo/tabAccesoPersonaVuelo";
import { CustomMessageBar, typeMessage } from "../../components/MessageBar/customMessageBar";
import { MessageHelper } from "../../utils/Helper/messageHelper";
import { ListarBusAndVuelo } from "./bus&vuelo/listarBus&Vuelo";
import { ConfigHelper } from "../../utils/Helper/configHelper";
import { IUserAuthenticate } from "../../auth/interface/IAuth";
import AuthService from "../../services/AuthService";
import { AccesoPersonaAdicional } from "./adicionales/accesoPersonaAdicional";
import { ActualizarAccesoPersona } from "./actualizarAccesoPersona";
import { PanelPlantillaFiltro } from "../../components/PanelUpload/PanelPlantillaFiltro";
import OrganigramaService from "../../services/organigramaService";
import EmpresaService from '../../services/empresaService';
import { Ifilter } from "../../interfaces/IFilter";
import { IEmpresa } from "../../interfaces/IEmpresa";
import { UbicacionHelper } from "../../utils/Helper/ubicacionHelper";
import { IFilterUbicacion } from "../../interfaces/filter/IFilterUbicacion";
import UbicacionService from '../../services/ubicacionesServices';
import ContratoService from '../../services/contratoService';
import { useRef } from 'react';
import { LogActividad } from "./logActividad/logActividad";
import { DialogBaseAlert } from "../../components/Dialog/DialogBaseAlert";
import { DialogBaseWait } from "../../components/Dialog/DialogBaseWait";
import { DialogBaseSuccess } from "../../components/Dialog/DialogBaseSuccess";
import { DialogBaseError } from "../../components/Dialog/DialogBaseError";
import { ListarAccesoHelo } from "./v2.helo/ListarAccesoHelo";
import { DialogBaseWarning } from "../../components/Dialog/DialogBaseWarning";
import { TableBase1 } from "../../components/Table/TableBase1";
import RepoteBaseGenerico from "../../components/Generic/ReporteBaseGenerico";
import blobStorageService from "../../services/blobStorageService";

const roleTabBus = () => {
  let a = [
    // ROLE_KEY_USER,
    ROLE_KEY_USER_EMPLEADO,
    ROLE_KEY_USER_SOCIO,
    ROLE_CONTRATISTA,
  ];

  return roleAdmin().concat(a);
}

const roleTabHelo = () => {
  let a = [
    // ROLE_KEY_USER,
    ROLE_KEY_USER_EMPLEADO,
    ROLE_KEY_USER_SOCIO
  ];

  return roleAdmin().concat(a);
}

const roleAreaTransporte =()=>{
  let a = [
    ROLE_AREA_TRANSPORTE
  ];
  return a;
}

const roleTabPersona = () => {
  let a = [
    // ROLE_KEY_USER
    ROLE_AGENCIA_VUELO,
  ];
  return roleAdmin().concat(a);
}

const roleTabAdicional = () => {
  let a = [
    // ROLE_KEY_USER,
    ROLE_KEY_USER_EMPLEADO,
    ROLE_KEY_USER_SOCIO,
    ROLE_CONTRATISTA,
  ];
  return roleAdmin().concat(a);
}

const roleBarGeneral = () => {
  let a = [
    // ROLE_KEY_USER,
    ROLE_KEY_USER_EMPLEADO,
    ROLE_KEY_USER_SOCIO,
    ROLE_CONTRATISTA,
  ];
  return roleAdmin().concat(a);
}

const roleAddAdicional = () => {
  let a = [
    // ROLE_KEY_USER,
    ROLE_KEY_USER_EMPLEADO,
    ROLE_KEY_USER_SOCIO
  ];

  return roleAdmin().concat(a);
}

// A. ADMIN
// B. KeyUser
// C. SSEE
const reportByRole = (access: IUserAuthenticate | null, id: string) => {
  let role = access?.role ?? [];

  let reportName = '';
  let param = {};

  let isRolAdmin = isRoleAccessAll(role, roleAdmin());

  if (isRolAdmin) {
    reportName = 'MOVProgramadoPersonaADMIN.trdx';
  } else {
    let isRolSSEE = isRoleAccessAll(role, [ROLE_CONTRATISTA]);

    if (isRolSSEE) {
      reportName = 'MOVProgramadoPersonaSSEE.trdx';
      param = {
        EmpresaId: AuthService.getInfoToken(CLAIM_EMPRESA_USUARIO_ID),
      }
    } else if (isRoleAccessAll(role, [ROLE_KEY_USER_EMPLEADO, ROLE_KEY_USER_SOCIO])) {
      reportName = 'MOVProgramadoPersonaKeyUser.trdx';
      param = {
        KeyUserId: AuthService.getInfoToken(CLAIM_PERSONA_ID)
      }
    }
  }

  let report: IReportTelerik = {
    reportName: reportName,
    param: {
      programacionId: id,
      ...param,
    }
  }

  return report;
}

export const ListarPersonaPorProgramacion = () => {
  let { id } = useParams();
  let navigate = useNavigate();
  const childRef: any = useRef(null);
  const state = useLocation()
  const [hidden, setHidden] = useState(false);
  const [columnNormal, setColumnNormal] = useState<IColumn[]>([]);
  const [columnAdicional, setColumnAdicional] = useState<IColumn[]>([]);
  const [data, setData] = useState<IDataCollection<IProgramacionPersona>>(_initialDataCollection);
  const [adicional, setAdicional] = useState<IDataCollection<IProgramacionPersona>>(_initialDataCollection);
  const [motivo, setMotivo] = useState<IItemComboBaseDTO[]>([]);
  const [detalleCarga, setDetalleCarga] = useState<IResultadoCargaMasiva>(null!);
  const [columnDetalleCarga, setColumnDetalleCarga] = useState<IColumn[]>([]);

  /* Panel Telerik */
  const [isOpenTelerik, { setTrue: openTelerik, setFalse: dismissTelerik }] = useBoolean(false);
  const [isOpenTelerikCM, { setTrue: openTelerikCM, setFalse: dismissTelerikCM }] = useBoolean(false);

  /* Modal */
  const [isOpenRetiro, setIsOpenRetiro] = useState(false);

  /* Panel */
  const [isOpenPanel, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
  const [sel, setSel] = useState<any[]>([]);
  const [loadingDetalleCarga, setLoadingDetalleCarga] = useState(true);
  const [isOpenCargaMasiva, { setTrue: openCargaMasiva, setFalse: dismissCargaMasiva },] = useBoolean(false);
  const [isDetalleCarga, { setTrue: openDetalleCarga, setFalse: dismissDetalleCarga },] = useBoolean(false);
  const [isOpenRetiroMasivo, { setTrue: openRetiroMasivo, setFalse: dismissRetiroMasivo },] = useBoolean(false);
  const [isOpenRetiroMasivoExcel, { setTrue: openRetiroMasivoExcel, setFalse: dismissRetiroMasivoExcel },] = useBoolean(false);
  const [isOpenPanelFiltro, { setTrue: openPanelFiltro, setFalse: dismissPanelFiltro },] = useBoolean(false);
  const [isOpenPanelFiltroAdicional, { setTrue: openPanelFiltroAdicional, setFalse: dismissPanelFiltroAdicional },] = useBoolean(false);

  /**Panel Nuevo Acceso programado */
  const [isOpenCrearAcceso, { setTrue: openCrearAcceso, setFalse: dismissCrearAcceso },] = useBoolean(false);
  const [isOpenActualizarAcceso, { setTrue: openActualizarAcceso, setFalse: dismissActualizarAcceso },] = useBoolean(false);
  const [selId, setSelId] = useState<string | undefined>(undefined);

  /* Panel Log */
  const [isOpenLog, { setTrue: openLog, setFalse: dismissLog },] = useBoolean(false);

  /* v1. Temporal - Panel - Chart */
  const [isChart, { setTrue: openChart, setFalse: dismissChart }] = useBoolean(false);
  const [dataCruzada, setDataCruzada] = useState<Types.Data[]>([]);

  /* Persona */
  const [selected, setSelected] = useState<ISharedProgramacionPersona>(null!);
  const [programacionPersonaId, setProgramacionPersonaId] = useState("");

  /* File */
  const [fileDetail, setfileDetail] = React.useState<IFile>(null!);
  const [excel, setExcel] = React.useState<FormData>();

  /* Chart */
  const [chartProgramacion, setChartProgramacion] = useState<IIconChart>(null!);

  const [selMotivoIdMasivoId, setSelMotivoIdMasivoId] = useState<string | null>(null);

  /**Validacion */
  const [isValidate, setisValidate] = useState(false);

  /* Message Error & Choice */
  const [isSendMasivo, setIsSendMasivo] = useState(false);
  const [choiceMasivo, setChoiceMasivo] = React.useState<string | undefined>(undefined);
  const resetChoiceMasivo = React.useCallback(() => setChoiceMasivo(undefined), []);
  const [textErrorMasivo, settextErrorMasivo] = useState('Error');

  /* Access & Role */
  const { access } = useContext(AuthContext);
  let role = access?.role ?? [];
  let isAdmin = isRoleAccessAll(role, roleAdmin());
  // let isRolKeyUser = isRoleAccessAll(role, [ROLE_KEY_USER]);
  let isRolKeyUser = isRoleAccessAll(role, [ROLE_KEY_USER_EMPLEADO, ROLE_KEY_USER_SOCIO]);
  let isAddAdicional = isRoleAccessAll(role, roleAddAdicional());
  let isReporteGeneral = isRoleAccessAll(role, roleBarGeneral());

  /* Report */
  let reportTelerik = reportByRole(access as IUserAuthenticate, id as string);

  /* Telerik - Personal Adicional */
  const [isReporteAdicional, { setTrue: openPanelReporteAdicional, setFalse: dismissReporteAdicional },] = useBoolean(false);

  /* Dialog Confirm aprobar todo */
  const [hideApproveAll, { toggle: toggleApproveAll }] = useBoolean(true);
  /* Dialog */
  const [hideAlertActualizarTransporte, { toggle: toggleHideAlertActualizarTransporte }] = useBoolean(true);
  const [hideWaitActualizarTransporte, { toggle: toggleHideWaitActualizarTransporte }] = useBoolean(true);
  const [hideSuccessActualizarTransporte, { toggle: toggleHideSuccessActualizarTransporte }] = useBoolean(true);
  const [hideErrorActualizarTransporte, { toggle: toggleHideErrorActualizarTransporte }] = useBoolean(true);
  const [hideErrorMensajeLog, { toggle: toggleHideErrorMensajeLog }] = useBoolean(true);

  const [mensajeTransaccion, setMensajeTransaccion] = useState("");

  /* Selection */
  const selection = new Selection({
    onSelectionChanged: () => {
      let d: any[] = selection.getSelection();

      let t: IActivityItemProps[] = d
        .filter((x) => x.isPendienteKeyUser && !x.isDisabled)
        .map((x) => {
          return {
            key: `1_${x.programacionPersonaId}`,
            activityDescription: [
              <Link
                key={`d_${x.programacionPersonaId}`}
                className={ThemeBase.activityClass.nameText}
              >
                {x.nombres}
              </Link>,
              <span key={`2_${x.programacionPersonaId}`}></span>,
              <span
                key={`3_${x.programacionPersonaId}`}
                className={ThemeBase.activityClass.nameText}
              ></span>,
            ],
            activityPersonas: [{ imageUrl: baseImageUrl }],
            timeStamp: `DNI: ${x.nroDOI}`,
            programacionPersonaId: `${x.programacionPersonaId}`,
          };
        });

      setSel(t);
    },
  });

  /* SearchParam */
  // const [searchParam, setSearchParam] = useSearchParams();
  const [filter, setFilter] = useState<IFilterProgramacion>({
    ..._initialFilter(),
    programacionId: id,

  });

  const [filteAdicional, setFilterAdicional] = useState<IFilterProgramacion>({
    ..._initialFilter(),
    programacionId: id,

  });

  /* Tabs */
  const [tabIndex, setTabIndex] = useState('0');

  /* Pagination */
  const [currentPage, setCurrentPage] = useState(
    _initialPagination().currentPage
  );
  const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
  const [total, setTotal] = useState(_initialPagination().total);

  const [pager, setPager] = useState<IPagination>({
    ..._initialPagination(),
    onPageChange: (page) => dataByPager(page),
    onSizeChange: (pageSize) => setPageSize(pageSize),
  });

  /* Loading Dialog - aprobacion masiva */
  const [isLoadingApproveAll, { setTrue: loadingApproveAll, setFalse: dismissLoadingApproveAll }] = useBoolean(false);

  /* Access Tab & Role */
  const [isShowTabBus, setIShowTabBus] = useState(isRoleAccessAll(role, roleTabBus()));
  const [isShowTabHelo, setIsShowTabHelo] = useState(isRoleAccessAll(role, roleTabHelo()));

  const [isShowTabPersona, setIsShowTabPersona] = useState(isRoleAccessAll(role, roleTabPersona()));

  const [isRolUsuario, setIsRolUsuario ] = useState(isRoleAccessAll(role, roleAreaTransporte()))

  const [isShowTabAdicional, setIsShowTabAdicional] = useState(isRoleAccessAll(role, roleTabAdicional()));
  const [isShowBarGeneral, setIsShowBarGeneral] = useState(isRoleAccessAll(role, roleBarGeneral()));

  const [cajaBusqueda, setCajaBusqueda] = useState("");

  /* V2. hooks */
  /* Solo cargar lo necesario en la carga inicial */
  useEffect(() => {
    if (id != undefined) {
      switch (tabIndex) {
        case '0':
          setup();
          break;
        case '1':
          // setupAdicional();
          break;
        default:
          break;
      }
    }
  }, [id]);

  useEffect(() => {
    if (isOpenRetiro) {
      dataMotivo();
    } else {
      setSelected(null!);
    }
  }, [isOpenRetiro]);

  useEffect(() => {
    if (!isOpenCargaMasiva) {
      setfileDetail(null!);
    }
  }, [isOpenCargaMasiva]);

  useEffect(() => {
    if (!isOpenRetiroMasivoExcel) {
      setfileDetail(null!);
    }
  }, [isOpenRetiroMasivoExcel]);


  useEffect(() => {
    if (isOpenRetiroMasivo) {
      dataMotivo();
    } else {
      setSelMotivoIdMasivoId(null);
    }
  }, [isOpenRetiroMasivo]);

  /* Hook Pagination */
  useEffect(() => {
    setPager({
      ...pager,
      currentPage: currentPage,
    });

    if (filter) {
      setFilter({
        ...filter,
        skip: (currentPage - 1) * pageSize,
        take: pageSize,
      });
    }
  }, [currentPage]);

  useEffect(() => {
    setPager({
      ...pager,
      onPageChange: (page) => dataByPager(page),
      onSizeChange: (pageSize) => setPageSize(pageSize),
      total: total,
    });
  }, [total]);



  const dataByPager = (page) => {
    if (true) {
      setTotal(0);

      setPager({
        ...pager,
        currentPage: page,
      });

      let f: IFilterProgramacion = {
        ...filter,
        skip: (page - 1) * pageSize,
        take: pageSize,
      }

      setFilter(f);
      setCurrentPage(page);

      eventTab(f);
    }
  }

  const eventTab = (param = filter) => {
    switch (tabIndex) {
      case '0':
        setup(param);
        break;
      case '1':
        if (childRef) {
          childRef.current.filterFromParent();
        }
        break;
      default:
        break;
    }
  }

  /* Api */
  const dataProgramacionPersona = (param = filter) => {
    setData(_initialDataCollection);
    if (id != undefined) {
      let acceptedRole = [
        ROLE_ADMIN,
        ROLE_AREA_TRANSPORTE,
        // ROLE_KEY_USER,
        ROLE_KEY_USER_EMPLEADO,
        ROLE_KEY_USER_SOCIO,
        ROLE_UNIDAD_MEDICA
      ];

      let isAll = false;

      acceptedRole.forEach(item => {
        let exis = role.find(x => x == item);

        if (exis) {
          isAll = true;
          return;
        }
      });

      if (isAll) {
        ProgramacionPersonaService.pendienteKeyUser(param)
          .then((res) => {

            setData(res.data);
            setTotal(res.data.total);
            setHidden(true);
          })
          .catch((err) => {
            alert(err.message);
            setHidden(true);
          });
      } else {
        let isSSEE = role.filter(x => x == ROLE_CONTRATISTA);
        if (isSSEE) {
          PersonaService.getAccesoPersona(id, param)
            .then((res) => {
              setData(res.data);
              setTotal(res.data.total);
              setHidden(true);
            })
            .catch((err) => {
              alert(err.message);
              setHidden(true);
            });
        }
      }
    }
  };

  const dataMotivo = () => {
    MotivoService.all()
      .then((res) => {
        setMotivo(res.data);
      })
      .catch((err) => {
        alert(err.message);
      });
  };


  const retirarPersona = () => {
    MotivoService.retirarPersona(selected)
      .then((res) => {
        setup();
        stateRetiro(false);
        alert("Se registró correctamente");
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const retirarPersonaMasivo = () => {
    if (id != undefined && selMotivoIdMasivoId) {
      let t: IRetirarPersonaMasivo = {
        programacionId: id,
        motivoId: selMotivoIdMasivoId,
        lista: sel.map((x) => x.programacionPersonaId),
      };

      MotivoService.retirarPersonaMasivo(t)
        .then((res) => {
          if (res.status == 200) {
            setup();
            dismissRetiroMasivo();
            alert("Se validó correctamente");
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
        });
    }
  };

  const validar = () => {
    if (id != undefined) {
      let t: IValidarKeyUser = {
        lista: sel.map((x) => x.programacionPersonaId),
        programacionId: id,
      };
      setisValidate(true)
      PersonaService.validarPersona(t)
        .then((res) => {
          if (res.status == 200) {
            setisValidate(false)
            dismissPanel();
            setup();
            // alert("Se validó correctamente");
          }
        })
        .catch((e) => {
          setisValidate(false)
          alert(splitExceptionDetail(e));
        });
    }
  };

  const validarProgramacion = () => {
    loadingApproveAll();

    if (id != undefined) {
      PersonaService.validarProgramacion(id)
        .then((res) => {
          if (res.status == 200) {
            setup();
            dismissLoadingApproveAll();
            toggleApproveAll();
            alert("Se validó correctamente");
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
          dismissLoadingApproveAll();
        });
    }
  };

  const obtenerblob = (listUrlBase: any) => {
    blobStorageService.cargarblobstorage(listUrlBase.url)
    .then((res) => {
      const blobUrl = res.data;

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = `${listUrlBase.nombre}.${listUrlBase.extension}`;
      link.click();
    })
    .catch((e) => {
      console.log("Esto es error")
    });
  };

  const descargarPlantilla = () => {
    //downloadBlob(BlobHelper.urlCargaProgramacionPersona);
    obtenerblob(BlobHelper.urlCargaProgramacionPersona);
  };

  const descargarPlantillaRetiro = () => {
    //downloadBlob(BlobHelper.urlRetiroProgramacionPersona);
    obtenerblob(BlobHelper.urlRetiroProgramacionPersona);
  };


  const enviarMasivoPersona = () => {
    setIsSendMasivo(true);
    setChoiceMasivo(undefined);

    PersonaService.postRegistroMasivo(excel, `${id}`)
      .then((res) => {
        setIsSendMasivo(false);

        if (res.status == 200) {
          let d: IResultadoCargaMasiva = res.data;

          if (d.detalle.length == 0) {
            alert("Se registró correctamente");
            dismissCargaMasiva();
            setup();
          } else {
            /* Panel resultado expcepciones */
            //alert(d.respuesta);
            toggleHideErrorMensajeLog()
            setMensajeTransaccion(d.respuesta)
            setup();
            setTitleDetalleCarga();
            dismissCargaMasiva();
            setDetalleCarga(d);
            //openDetalleCarga();
          }

          setLoadingDetalleCarga(false);
        }
      })
      .catch((e: IException) => {
        setIsSendMasivo(false);
        setChoiceMasivo('error')
        settextErrorMasivo(splitExceptionDetail(e));
      });
  };

  const enviarRetiroMasivoExcel  = () => {
    console.log("Mantenimiento")
    setIsSendMasivo(true);
    setChoiceMasivo(undefined);

    PersonaService.postRegistroRetiroMasivoExcel(excel, `${id}`)
      .then((res) => {
        setIsSendMasivo(false);

        if (res.status == 200) {
          let d: IResultadoCargaMasiva = res.data;

          if (d.respuesta == 'Se registró correctamente el retiro masivo') {
            alert("Se realizó el retiro masivo correctamente");
            dismissRetiroMasivoExcel();
            setup();
          } else {
            /* Panel resultado excepciones */
            //alert(d.respuesta);
            toggleHideErrorMensajeLog()
            setMensajeTransaccion(d.respuesta)
            setup();
            setTitleDetalleCarga();
            dismissRetiroMasivoExcel();
            dismissCargaMasiva();
            dismissDetalleCarga();
            setDetalleCarga(d);
            //openDetalleCarga();
          }

          setLoadingDetalleCarga(false);
        }
      })
      .catch((e: IException) => {
        setIsSendMasivo(false);
        setChoiceMasivo('error')
        console.log(e);
        //settextErrorMasivo(splitExceptionDetail(e));
      });
  };


  const reporteLogCargarMasiva = () => {
    if (detalleCarga) {
      ProgramacionPersonaService.reporteLogCargaMasiva(detalleCarga.id)
        .then((x) => {
          saveAs(x.data, "PersonasNoProgramadas.xlsx");
        })
        .catch((e) => {
          alert(splitExceptionDetail(e));
        });
    }
  };

  /* Adicionales */
  const dataAdicional = (param = filter) => {
    ProgramacionPersonaService.allTodoAdicional(param)
      .then((res) => {
        setAdicional(res.data);
        setTotal(res.data.total);
        setHidden(true);
      })
      .catch((err) => {
        alert(err.message);
        setHidden(true);
      });
  };

  /* Api Info Chart */
  const infoProgramacionChart = () => {
    if (id != undefined) {
      ProgramacionService.infoProgramacionChart(id)
        .then((res) => {
          if (res.status == 200) {
            let d: IChartProgramacion = res.data;
            let t: IIconChart = {
              card: d.card.map((i) => {
                return {
                  ...i,
                };
              }),
            };

            let c: Types.Data[] = [
              {
                framework: "Total",
                value: d.total,
              },
              {
                framework: "Ocupado",
                value: d.ocupado,
              },
              {
                framework: "Retirados",
                value: d.retiro,
              },
            ];

            setChartProgramacion(t);
            setDataCruzada(c);
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
        });
    }
  };

  /* Function */
  const setTitleColumnNormal = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Foto",
        ariaLabel:
          "Column operations for File type, Press to sort on File type",
        isIconOnly: true,
        fieldName: "foto",
        minWidth: 45,
        maxWidth: 45,
        onRender: (item: IProgramacionPersona) => (
          <TooltipHost content={`Foto`}>
            <Persona
              text=""
              size={PersonaSize.size24}
              imageUrl={baseImageUrl}
            />
          </TooltipHost>
        ),
      },
      {
        key: "column2",
        name: "Nombre",
        fieldName: "nombres",
        minWidth: 160,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
        onRender: (item: IProgramacionPersona) => {
          return (
            <span
              style={{
                fontSize: "90%",
                textDecorationLine:
                  item.motivoRetiro == "" ? "none" : "line-through",
              }}
            >
              {item.nombres}
            </span>
          );
        },
      },
      {
        key: "column3",
        name: "Identificación",
        fieldName: "nroDOI",
        minWidth: 80,
        maxWidth: 90,
        isResizable: true,
        data: "number",
        isPadded: true,
        onRender: (item: IProgramacionPersona) => {
          return (
            <span
              style={{ fontSize: "95%" }}
            >{`${item.tipoDocumento} ${item.nroDOI}`}</span>
          );
        },
      },
      {
        key: "column4",
        name: "Gerencia",
        fieldName: "gerencia",
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column5",
        name: "Celular",
        fieldName: "celular",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column6",
        name: "Empresa Persona",
        fieldName: "empresa",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column7",
        name: "Transporte",
        fieldName: "requiereTransporte",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        onRender: (item: IProgramacionPersona) => {
          return (
            <span
              style={{ fontSize: "95%", ...ThemeBase.justifiedStyle }}>{item.requiereTransporte ? 'Si' : 'No'}</span>
          );
        },
      },
      {
        key: "column8",
        name: "Comunidad",
        fieldName: "esComunidad",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        onRender: (item: IProgramacionPersona) => {
          return (
            <span
              style={{ fontSize: "95%", ...ThemeBase.justifiedStyle }}>{item.esComunidad ? 'Si' : 'No'}</span>
          );
        },
      },
      {
        key: "column9",
        name: "Visita",
        fieldName: "esVisita",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        onRender: (item: IProgramacionPersona) => {
          return (
            <span
              style={{ fontSize: "95%", ...ThemeBase.justifiedStyle }}>{item.esVisita ? 'Si' : 'No'}</span>
          );
        },
      },
      {
        key: "column10",
        name: "Empresa Transportista",
        fieldName: "transporte",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column11",
        name: "Turno",
        fieldName: "turno",
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column12",
        name: "Usuario Registra",
        fieldName: "nombreUsuarioRegistra",
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column13",
        name: "KeyUser Valida",
        fieldName: "nombreUsuarioValida",
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column14",
        name: "Es Adicional",
        fieldName: "esAdicional",
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column15",
        name: "Proceso - Estado",
        fieldName: "nivelActual",
        minWidth: 250,
        maxWidth: 250,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column16",
        name: "Lugar Mov.",
        fieldName: "lugarMovilizacion",
        minWidth: 100,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column17",
        name: "Origen",
        fieldName: "origen",
        minWidth: 90,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column18",
        name: "Destino",
        fieldName: "destino",
        minWidth: 90,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column19",
        name: "Centro Costo",
        fieldName: "centroCosto",
        minWidth: 80,
        maxWidth: 80,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column20",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 90,
        maxWidth: 110,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: IProgramacionPersona) => (
          <>
            <Stack horizontal tokens={stackTokensHorizontal}>
              {(item.isEdit || (isAddAdicional && item.motivoRetiro == '')) && (
                <>
                  <TooltipHost content={`Editar`}>
                    <Icon
                      iconName="Edit"
                      style={{ cursor: "pointer" }}
                      className={ThemeBase.classes.iconFont}
                      onClick={() => {
                        setSelId(item.programacionPersonaId);
                        openActualizarAcceso();
                      }}
                    // onClick={() => editPersona(item.programacionPersonaId)}
                    />
                  </TooltipHost>

                  {!item.requiereTransporte ?
                    <>
                      <TooltipHost content={`No tiene Transporte`}>
                        <Icon
                          iconName="BusSolid"
                          style={{ cursor: "pointer", color: 'rgb(209, 52, 56)' }}
                          className={`${ThemeBase.classes.iconFont}`}
                        />
                      </TooltipHost>
                    </>

                    :

                    <>

                      <TooltipHost content={`Tiene Transporte`}>
                        <Icon
                          iconName="Bus"
                          style={{ cursor: "pointer" }}
                          className={`${ThemeBase.classes.iconFont}`}
                        // onClick={() => {
                        //   setProgramacionPersonaId(item.programacionPersonaId);
                        //   toggleHideAlertActualizarTransporte()
                        // }
                        // }
                        />
                      </TooltipHost>


                    </>
                  }

                  <TooltipHost content={`Retirar`}>
                    <Icon
                      iconName="Cancel"
                      style={{ cursor: "pointer" }}
                      className={`${ThemeBase.classes.iconFont}`}
                      onClick={() => changeState(item, true)}
                    />
                  </TooltipHost>
                </>
              )}

              {/* Actividad Log */}
              <TooltipHost content={`Actividades`}>
                <Icon
                  iconName={IconHelper.ICON_INFO.iconProps?.iconName}
                  style={{ cursor: "pointer" }}
                  className={`${ThemeBase.classes.iconFont}`}
                  onClick={() => {
                    setSelId(item.programacionPersonaId);
                    openLog();
                  }}
                />
              </TooltipHost>
            </Stack>
          </>
        ),
      },
    ];

    return col;
  };

  const setTitleColumnAdicional = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Foto",
        ariaLabel:
          "Column operations for File type, Press to sort on File type",
        isIconOnly: true,
        fieldName: "foto",
        minWidth: 45,
        maxWidth: 45,
        onRender: (item: IProgramacionPersona) => (
          <TooltipHost content={`Foto`}>
            <Persona
              text=""
              size={PersonaSize.size24}
              imageUrl={baseImageUrl}
            />
          </TooltipHost>
        ),
      },
      {
        key: "column2",
        name: "Nombre",
        fieldName: "nombres",
        minWidth: 160,
        maxWidth: 200,
        isRowHeader: true,
        isResizable: true,
        isSorted: false,
        isSortedDescending: false,
        sortAscendingAriaLabel: "Sorted A to Z",
        sortDescendingAriaLabel: "Sorted Z to A",
        data: "string",
        isPadded: true,
        onRender: (item: IProgramacionPersona) => {
          return (
            <span
              style={{
                fontSize: "90%",
                textDecorationLine:
                  item.motivoRetiro == "" ? "none" : "line-through",
              }}
            >
              {item.nombres}
            </span>
          );
        },
      },
      {
        key: "column3",
        name: "Identificación",
        fieldName: "nroDOI",
        minWidth: 80,
        maxWidth: 90,
        isResizable: true,
        data: "number",
        isPadded: true,
        onRender: (item: IProgramacionPersona) => {
          return (
            <span
              style={{ fontSize: "95%" }}
            >{`${item.tipoDocumento} ${item.nroDOI}`}</span>
          );
        },
      },
      {
        key: "column4",
        name: "Gerencia",
        fieldName: "gerencia",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column5",
        name: "Origen",
        fieldName: "origen",
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: "string",
        isPadded: true,
      },
      {
        key: 'column6',
        name: 'Destino',
        fieldName: 'destino',
        minWidth: 70,
        maxWidth: 70,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        isPadded: true,
      },
      {
        key: "column7",
        name: "Empresa Transportista",
        fieldName: "transporte",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column8",
        name: "Turno",
        fieldName: "turno",
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column9",
        name: "Es Adicional",
        fieldName: "esAdicional",
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column10",
        name: "Clasificación",
        fieldName: "clasificacion",
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column11",
        name: "Nivel",
        fieldName: "nivelActual",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column12",
        name: "Lugar Movilizacion",
        fieldName: "lugarMovilizacion",
        minWidth: 200,
        maxWidth: 200,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column13",
        name: "Centro Costo",
        fieldName: "centroCosto",
        minWidth: 150,
        maxWidth: 160,
        isResizable: true,
        isCollapsible: true,
        data: "number",
      },
      {
        key: "column14",
        name: "Registrado por",
        fieldName: "nombreUsuarioRegistra",
        minWidth: 120,
        maxWidth: 120,
        isResizable: true,
        isCollapsible: true,
        data: "string",
      },
      {
        key: "column15",
        name: "Opciones",
        fieldName: "opciones",
        minWidth: 90,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: IProgramacionPersona) => (
          <>
            <Stack horizontal tokens={stackTokensHorizontal}>
              {/* {(item.isEdit || (isAddAdicional && item.motivoRetiro == '')) && (
                <>
                  <TooltipHost content={`Editar`}>
                    <Icon
                      iconName="Edit"
                      style={{ cursor: "pointer" }}
                      className={ThemeBase.classes.iconFont}
                      onClick={() => {
                        setSelId(item.programacionPersonaId);
                        openActualizarAcceso();
                      }}
                    // onClick={() => editPersona(item.programacionPersonaId)}
                    />
                  </TooltipHost>

                  <TooltipHost content={`Retirar`}>
                    <Icon
                      iconName="Cancel"
                      style={{ cursor: "pointer" }}
                      className={`${ThemeBase.classes.iconFont}`}
                      onClick={() => changeState(item, true)}
                    />
                  </TooltipHost>
                </>
              )} */}

              {/* Actividad Log */}
              <TooltipHost content={`Actividades`}>
                <Icon
                  iconName={IconHelper.ICON_INFO.iconProps?.iconName}
                  style={{ cursor: "pointer" }}
                  className={`${ThemeBase.classes.iconFont}`}
                  onClick={() => {
                    setSelId(item.programacionPersonaId);
                    openLog();
                  }}
                />
              </TooltipHost>
            </Stack>
          </>
        ),
      },
    ];

    return col;
  };

  const setup = (param = filter) => {
    setHidden(false);
    setData(_initialDataCollection);
    setColumnNormal(setTitleColumnNormal());
    dataProgramacionPersona(param);
  };

  const setupAdicional = (param = filter) => {
    setHidden(false);
    setColumnAdicional(setTitleColumnAdicional());
    dataAdicional(param);
  };

  const downbload = () => {
    openTelerik();
  };

  const goAdd = () => {
    navigate(`${ConfigHelper.urlBase()}/accesopersona/${id}`);
  };

  const goBack = () => {
    navigate(`${ConfigHelper.urlBase()}/programaciones`);
  };

  const editPersona = (id: string) => {
    navigate(`${ConfigHelper.urlBase()}/accesopersona/editar/${id}`);
  };

  /* 1. Proceso Normal */
  const _leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      // {
      //   ...IconHelper.ICON_BACK,
      //   onClick: goBack,
      // },
      {
        ...IconHelper.ICON_ADD,
        // onClick: goAdd,
        onClick: openCrearAcceso,
      },
      {
        ...IconHelper.ICON_RELOAD,
        onClick: () => setup(),
      },
      // {
      //   ...IconHelper.ICON_UPLOAD,
      //   text: "Cargar",
      //   onClick: openCargaMasiva,
      // },
      {
        ...IconHelper.ICON_CANCEL,
        text: "Retirar",
        onClick: () => {
          if (sel.length == 0) {
            return alert("Debe seleccionar al menos un item");
          }

          openRetiroMasivo();
        },
      },
      /* {
        ...IconHelper.ICON_CHART,
        onClick: () => {
          infoProgramacionChart();
          openChart();
        },
      } */
    ];

    return _items;
  };

  /* General */
  const _rightBarGeneral = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_BACK,
        onClick: goBack,
      },
      {
        ...IconHelper.ICON_UPLOAD,
        text: "Cargar",
        onClick: openCargaMasiva,
      },
      {
        ...IconHelper.ICON_EXCEL,
        text: "Retiro Masivo",
        onClick: openRetiroMasivoExcel,
      },
    ];
    return _items;
  };

  const _leftBarGeneral = () => {
    const _items: ICommandBarItemProps[] = [];

    if (isReporteGeneral) {
      _items.push(
        {
          ...IconHelper.ICON_EXCEL,
          onClick: openTelerik,
        },
        {
          ...IconHelper.ICON_CUADROMANDO,
          onClick: openTelerikCM,
        },
      )
    }

    return _items;
  }

  const _rightBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_EXCEL,
        onClick: downbload,
      },
      {
        ...IconHelper.ICON_INFO,
        onClick: () => {
          alert("info");
        },
      },
    ];

    return _items;
  };

  /* 2. Adicionales */
  const _leftBarAdicional = () => {
    const _items: ICommandBarItemProps[] = [];

    if (isAddAdicional) {
      _items.push(
        {
          ...IconHelper.ICON_ADD,
          // onClick: goAdd,
          onClick: openCrearAcceso,
        },
      )
    }
    _items.push(
      {
        ...IconHelper.ICON_RELOAD,
        onClick: () => setupAdicional(),
      },
    )

    return _items;
  };

  const _rightBarAdicional = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_EXCEL,
        onClick: openPanelReporteAdicional,
      },
      {
        ...IconHelper.ICON_FILTER,
        onClick: () => openPanelFiltro() /* openPanelFiltroAdicional() */
      },
    ];

    return _items;
  };

  const _rightNormal = () => {
    const _items: ICommandBarItemProps[] = [
      // {
      //   ...IconHelper.ICON_EXCEL,
      //   onClick: openTelerik,
      // },
      {
        ...IconHelper.ICON_FILTER,
        onClick: () => openPanelFiltro(),
      },
    ];

    return _items;
  };

  const _rigthBarDetalleCarga = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_EXCEL,
        onClick: reporteLogCargarMasiva,
      },
    ];

    return _items;
  };

  const panelCargaMasivaBarDownload = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_DOWNLOAD,
        text: "Descargar Plantilla",
        onClick: descargarPlantilla,
      },
    ];

    return _items;
  };

  const panelRetiroMasivoBarDownload = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_DOWNLOAD,
        text: "Descargar Plantilla Retiro",
        onClick: descargarPlantillaRetiro,
      },
    ];

    return _items;
  };

  const panelCargaMasivaBarUpload = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_UPLOAD,
        text: `Cargar Plantilla`,
        preferMenuTargetAsEventTarget: true,
        onClick: onClickUpload
      }
    ];

    return _items;
  };
  const panelCargaMasivaRightBarUpload = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_Folder,
        preferMenuTargetAsEventTarget: true,
        onClick: onClickUpload
      }
    ];

    return _items;
  };

  const onClickUpload = (
    ev?:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>
      | undefined
  ) => {
    Promise.resolve().then(() => {
      const inputElement = document.createElement("input");
      inputElement.style.visibility = "hidden";
      inputElement.setAttribute("type", "file");
      inputElement.setAttribute(
        "accept",
        ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      );
      inputElement.onchange = function (ev) {
        let { files } = ev.target as HTMLInputElement;

        if (files != undefined) {
          const file: File = files[0];

          var formData = new FormData();
          formData.append("file", file, file.name);

          setExcel(formData);

          setfileDetail({
            nombre: file.name,
            tamanio: file.size,
            tipo: file.type,
          });
        }
      };

      document.body.appendChild(inputElement);

      const target = ev?.target as HTMLElement | undefined;

      if (target) {
        setVirtualParent(inputElement, target);
      }

      inputElement.click();

      if (target) {
        setVirtualParent(inputElement, null);
      }

      setTimeout(() => {
        inputElement.remove();
      }, 10000);
    });
  }

  /* Solo debe de tener acceso a estas opciones el Rol KeyUser */
  const _leftBarKeyUser = () => {
    let _items: ICommandBarItemProps[] = [];

    let acceptedRole = [
      ROLE_ADMIN,
      ROLE_AREA_TRANSPORTE,
      // ROLE_KEY_USER,
      ROLE_KEY_USER_EMPLEADO,
      ROLE_KEY_USER_SOCIO
    ];

    let isAll = false;

    acceptedRole.forEach(item => {
      let exis = role.find(x => x == item);

      if (exis) {
        isAll = true;
        return;
      }
    });

    if (isAll) {
      _items = [
        {
          key: "CheckMark",
          text: "Validar",
          iconProps: { iconName: "CheckMark" },
          onClick: () => {
            if (sel.length == 0) {
              return alert("Debe seleccionar al menos un item");
            }

            openPanel();
          },
        },
      ];
    }

    // if (isAdmin) {
    //   _items.push(
    //     {
    //       key: "AllCheckMark",
    //       text: "Aprobar todo",
    //       iconProps: { iconName: "ConnectContacts" },
    //       onClick: () => {
    //         toggleApproveAll();
    //       },
    //     },
    //   )
    // }
    return _items;
  };

  /* Event Filter */
  const loadBase = (text: string | null = null) => {
    let p = _initialPagination().currentPage;

    let f: IFilterProgramacion = {
      ..._initialFilter(),
      programacionId: id,
      skip: (p - 1) * pageSize,
      take: pageSize,
      search: text,
    };

    setFilter(f);
    setCurrentPage(p);

    if (tabIndex == '0') {
      eventTab(f);
    }
  }
  const _onChangeText = (ev?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
    if (newValue != undefined) {
      if (newValue === '') {
        loadBase();
      } else {
        setFilter({
          ...filter,
          search: newValue,
        })
      }

      switch (tabIndex) {
        case '1':
        case '2':
        case 'z':
          if (newValue === '') {
            if (childRef) {
              childRef.current.filterFromParent(newValue);
            }
          }

          break;
        default:
          break;
      }
    }
  };

  const _onPress = (newValue: string) => {
    loadBase(newValue as string);

    switch (tabIndex) {
      case '1':
      case '2':
      case 'z':
        console.log(childRef);
        if (childRef) {
          childRef.current.filterFromParent(newValue);
        }
        break;
      default:
        break;
    }
  };

  const _onClearSearch = () => {
    switch (tabIndex) {
      case '1':
      case '2':
      case 'z':
        if (childRef) {
          childRef.current.filterFromParent('');
        }
        break;
      default:
        break;
    }

    loadBase();
  };

  const _onChangeMotivo = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption,
    index?: number,
    value?: string
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        setSelected({
          ...selected,
          motivoId: option.key.toString(),
        });
      }
    }
  };

  const stateRetiro = (state: boolean) => {
    setIsOpenRetiro(state);
  };

  const changeState = (item: IProgramacionPersona, state: Boolean) => {
    setSelected({
      programacionPersonaId: item.programacionPersonaId,
      imageUrl: item.foto,
      imageInitials: "",
      text: item.nombres,
      secondaryText: `${item.tipoDocumento}: ${item.nroDOI}`,
      tertiaryText: "",
      optionalText: "",
    });
    stateRetiro(true);
  };

  const setTitleDetalleCarga = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Tipo DOI",
        fieldName: "tipoDoi",
        minWidth: 80,
        maxWidth: 100,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column1",
        name: "Nº. DOI",
        fieldName: "doi",
        minWidth: 80,
        maxWidth: 100,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Observación",
        fieldName: "log",
        minWidth: 1500,
        maxWidth: 1500,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
    ];

    setColumnDetalleCarga(col);
  };

  /* Footer Panel */
  const onRenderFooterContent = React.useCallback(
    () => (
      <div>
        <PrimaryButton disabled={isValidate} onClick={validar} styles={ThemeBase.panelButtonStyle}>
          Aprobar
        </PrimaryButton>
        <DefaultButton onClick={dismissPanel}>Cancelar</DefaultButton>
      </div>
    ),
    [dismissPanel, sel, isValidate]
  );

  const _onRenderFooterCargaMasiva = React.useCallback(
    () => (
      <div>
        <PrimaryButton
          onClick={enviarMasivoPersona}
          styles={ThemeBase.panelButtonStyle}
          disabled={!fileDetail || isSendMasivo}
        >
          Confirmar
        </PrimaryButton>
        <DefaultButton onClick={dismissCargaMasiva}>Cancelar</DefaultButton>
      </div>
    ),
    [dismissCargaMasiva, fileDetail, excel, id, isSendMasivo]
  );

  const _onRenderFooterRetiroMasivoExcel = React.useCallback(
    () => (
      <div>
        <PrimaryButton
          onClick={enviarRetiroMasivoExcel}
          styles={ThemeBase.panelButtonStyle}
          disabled={!fileDetail || isSendMasivo}
        >
          Confirmar
        </PrimaryButton>
        <DefaultButton onClick={dismissRetiroMasivoExcel}>Cancelar</DefaultButton>
      </div>
    ),
    [dismissRetiroMasivoExcel, fileDetail, excel, id, isSendMasivo]
  );

  const _onRenderFooterDetalleCargaMasiva = React.useCallback(
    () => (
      <div>
        <DefaultButton onClick={dismissDetalleCarga}>Cancelar</DefaultButton>
      </div>
    ),
    [dismissDetalleCarga, detalleCarga]
  );

  const _onRenderFooterChart = React.useCallback(
    () => (
      <div>
        <DefaultButton onClick={dismissChart}>Cancelar</DefaultButton>
      </div>
    ),
    [dismissChart]
  );

  const onRenderPanelRetiroMasivo = React.useCallback(
    () => (
      <div>
        <Stack tokens={stackTokens}>
          <PrimaryButton
            onClick={retirarPersonaMasivo}
            styles={ThemeBase.panelButtonStyle}
            disabled={!selMotivoIdMasivoId}
          >
            Retirar
          </PrimaryButton>
        </Stack>
      </div>
    ),
    [sel, selMotivoIdMasivoId]
  );

  /* Render DetailList */
  const renderRowFields = (props: IDetailsRowFieldsProps) => {
    return (
      <span data-selection-disabled={true}>
        <DetailsRowFields {...props} />
      </span>
    );
  };

  const onRenderCheck = (
    props: IDetailsRowCheckProps,
    item: IProgramacionPersona
  ) => {
    const customStyles: Partial<IDetailsRowCheckStyles> = {};
    /* customStyles.root = { backgroundColor: 'rgba(255, 0, 0, 0.4)' }; */

    const warningButtonTheme: ITheme = createTheme({
      palette: {
        themePrimary: !item.isPendienteKeyUser
          ? ThemeBase.theme.palette.neutralQuaternaryAlt
          : ThemeBase.theme.palette.themePrimary,
      },
    });

    if (!item.isPendienteKeyUser) {
      /* console.log('separator');
            console.log(item.nombres + ' ' + item.key); */
      selection.setKeySelected(item.key, true, false);
    }

    /* console.log(item); */

    return (
      <DetailsRowCheck
        {...props}
        aria-label="custom label based on props"
        /* selected={item ? !item.isPendienteKeyUser : false} */
        data-selection-disabled={!item.isPendienteKeyUser || item.isDisabled}
        styles={customStyles}
        theme={warningButtonTheme}
      />
    );
  };

  const _onChangeTab = (item?: PivotItem, ev?: React.MouseEvent<HTMLElement>) => {
    if (item != undefined) {
      let { itemKey } = item.props;

      if (itemKey != undefined) {
        setTabIndex(itemKey);

        let p = _initialPagination().currentPage;

        let f: IFilterProgramacion = {
          ..._initialFilter(),
          programacionId: id,
          skip: (p - 1) * pageSize,
          take: pageSize,
          search: null,
        };

        setFilter(f);
        setCurrentPage(p);

        switch (itemKey) {
          case '0':
            setup(f);

            break;
          case '1':
            // setupAdicional(f);

            break;
          case '2':

            break;
          case 'z':

            break;
          default:
            break;
        }


      }
    }
  };

  /* Event v2 */
  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        switch (type) {
          case "motivo":
            setSelMotivoIdMasivoId(option.key as string);
            break;
          default:
            break;
        }
      }
    }
  };

  const [showTooltip, setShowTooltip] = React.useState(false);
  const tooltipId = useId('tooltipId');
  const buttonStyles = {
    root: {
      background: 'transparent',
      border: 'none',
      minWidth: '16px',
      padding: 0,
    },
  };
  const iconWithTooltip = (
    <>
      <TooltipHost content={showTooltip ? 'Elija un archico excel para cargar, Usted puede descargar una plantilla en la opción descarga plantilla' : undefined} id={tooltipId}>
        <DefaultButton
          aria-label={'more info'}
          aria-describedby={showTooltip ? tooltipId : undefined}
          onClick={() => setShowTooltip(!showTooltip)}
          styles={buttonStyles}
          iconProps={{ iconName: 'Info' }}
        />
      </TooltipHost>
    </>
  );

  const [gerenciaCombo, setGerenciaCombo] = useState<IItemComboBaseDTO[]>([]);
  const [filterGerencia, setFilterGerencia] = useState<ISearch>({
    search: ""
  });

  const [empresaCombo, setEmpresaCombo] = useState<IItemComboBaseDTO[]>([]);
  const [filterEmpresa, setFilterEmpresa] = useState<Ifilter>({
    ..._initialFilter()
  });

  const [empresaPersonaCombo, setEmpresaPersonaCombo] = useState<IItemComboBaseDTO[]>([]);
  const [filterEmpresaPersona, setFilterEmpresaPersona] = useState<Ifilter>({
    ..._initialFilter()
  });


  const empresaRef = React.useRef<IComboBox>(null);
  const _openOptionEmpresa = React.useCallback(() => empresaRef.current?.focus(true), []);

  const empresaPersonaRef = React.useRef<IComboBox>(null);
  const _openOptionPersona = React.useCallback(() => empresaPersonaRef.current?.focus(true), []);

  const [embarqueCombo, setEmbaqueCombo] = useState<IItemComboBaseDTO[]>([]);
  const [destinoCombo, setDestinoCombo] = useState<IItemComboBaseDTO[]>([]);

  const [centroCostoCombo, setCentroCostoCombo] = useState<IItemComboBaseDTO[]>([]);
  const [filterCentroCosto, setFilterCentroCosto] = useState<Ifilter>({
    ..._initialFilter()
  });
  const centroCostoRef = React.useRef<IComboBox>(null);
  const _openOptionCentroCosto = React.useCallback(() => centroCostoRef.current?.focus(true), []);

  const [lugarMovilizacionCombo, setLugarMovilizacionCombo] = useState<IItemComboBaseDTO[]>([]);
  const embarqueRef = React.useRef<IComboBox>(null);
  const campamentoRef = React.useRef<IComboBox>(null);
  const LugarMovilizacionRef = React.useRef<IComboBox>(null);

  const _openOptionEmbarque = React.useCallback(() => embarqueRef.current?.focus(true), []);
  const _openOptionCampamento = React.useCallback(() => campamentoRef.current?.focus(true), []);
  const _openOptionMovilizacion = React.useCallback(() => LugarMovilizacionRef.current?.focus(true), []);

  /*filtros */
  const [filterEmbarque, setFilterEmbarque] = useState<IFilterUbicacion>({
    tipo: UbicacionHelper.TIPO_ORIGEN
  });
  const [filterDestino, setFilterDestino] = useState<IFilterUbicacion>({
    tipo: UbicacionHelper.TIPO_DESTINO,
  });
  const [filterMovilizacion, setfilterMovilizacion] = useState<ISearch>(_initialFilter);


  useEffect(() => {
    dataGerencia();

  }, [filterGerencia]);

  useEffect(() => {
    dataEmpresaPersona();

  }, [filterEmpresaPersona]);

  useEffect(() => {

    dataEmpresaTransporte();

  }, [filterEmpresa]);

  useEffect(() => {
    dataEmpresaPersona();

  }, [filterEmpresaPersona]);

  useEffect(() => {

    dataUbicacionOrigen();
    dataUbicacionDestino();

  }, [filterEmbarque, filterDestino]);

  useEffect(() => {
    if (filterMovilizacion.search != null) {
      dataLugarMovilizacion();
    }
  }, [filterMovilizacion]);

  useEffect(() => {
    dataCentroCosto()
  }, [filterCentroCosto]);

  const dataGerencia = () => {
    setGerenciaCombo([]);
    OrganigramaService.organigramaByTipo('01', filterGerencia)
      .then((res) => {
        if (res.status == 200) {
          let t: IItemComboBaseDTO[] = res.data;
          // t.unshift(ItemNoneSelect());

          setGerenciaCombo(t);
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataEmpresaPersona = () => {

    dataEmpresa(filterEmpresaPersona, setEmpresaPersonaCombo);

  }

  const dataEmpresaTransporte = () => {

    dataEmpresa(filterEmpresa, setEmpresaCombo);

    // setFilterEmpresa()
  }

  const dataEmpresa = (filtro: Ifilter, setCombo: React.Dispatch<React.SetStateAction<any>>) => {

    EmpresaService.all(filtro)
      .then((res) => {
        if (res.status == 200) {

          // console.log("***** empresa *****");
          // console.table(res.data.items);
          let d: IEmpresa[] = res.data.items;
          let t: IItemComboBaseDTO[] = d.map((x) => {
            return {
              key: x.empresaId,
              text: x.nombre as string,
            };
          });

          setCombo(t);

        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));

      });
  };

  const dataUbicacionOrigen = () => {

    dataUbicacion(filterEmbarque, setEmbaqueCombo);

  }

  const dataUbicacionDestino = () => {

    dataUbicacion(filterDestino, setDestinoCombo);

  }

  const dataUbicacion = (filtro: any, setCombo: React.Dispatch<React.SetStateAction<any>>) => {
    UbicacionService.ubicacionPorFiltro(filtro)
      .then((res) => {
        setCombo(res.data);

      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataLugarMovilizacion = () => {
    ProgramacionService.allLugarMovilizacion()
      .then((res) => {
        // console.log("************** lugar **********");
        // console.log(res.data);
        setLugarMovilizacionCombo(res.data);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const dataCentroCosto = () => {
    ContratoService.allCentroCosto1(filterCentroCosto)
      .then((res) => {
        setCentroCostoCombo(res.data);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const
    _onChangeComboFiltro = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
      if (event != undefined) {
        if (option != undefined) {

          switch (type) {
            case 'empresaPersona':
              setFilter({
                ...filter,
                empresaPersonaId: option.key.toString(),
              })

              break;

            case 'empresaTransporte':
              setFilter({
                ...filter,
                empresaTransporteId: option.key.toString(),
              })

              break;

            case 'gerencia':
              setFilter({
                ...filter,
                gerenciaId: option.key.toString(),
              })

              break;
            case 'movilizacion':
              setFilter({
                ...filter,
                lugarMovilizacionId: option.key.toString()
              })

              break;
            case 'centroCosto':
              setFilter({
                ...filter,
                centroCostoId: option.key.toString()
              })

              break;
            case 'origen':

              //alert(option.key.toString())
              setFilter({
                ...filter,
                origenId: option.key.toString()
              })

              break;
            case 'destino':

              //alert(option.key.toString())
              setFilter({
                ...filter,
                destinoId: option.key.toString()
              })

              break;
            default:
              break;
          }
        }
      }
    };

  const _onKeyUpCombo = (e: React.FormEvent<IComboBox>) => {

    let { id } = e?.target as HTMLInputElement;
    let { value } = e?.target as HTMLInputElement;

    switch (id) {

      case "empresaPersona-input":
        _openOptionPersona()

        setFilterEmpresaPersona({
          ...filterEmpresaPersona,
          search: value,
        })

        break;
      case "empresaTransporte-input":
        _openOptionEmpresa()

        setFilterEmpresa({
          ...filterEmpresa,
          search: value,
        })

        break;
      case "movilizacion-input":
        _openOptionMovilizacion();

        setfilterMovilizacion({
          ...filterMovilizacion,
          search: value
        })

        break;

      case "centroCosto-input":
        _openOptionCentroCosto();

        setFilterCentroCosto({
          ...filterCentroCosto,
          search: value
        })

        break;
      default:
        break;
    }
  }

  /*  const _onBlurEmpresa = () => {
     setFilterEmpresa({ ...filterEmpresa, search: '' })
   } */

  const _onChangeTextFiltro = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (event != undefined) {
      if (newValue != undefined) {
        let nameInput = event.currentTarget.getAttribute("name");

        switch (nameInput) {
          case "doi":

            setFilter({
              ...filter,
              search: newValue.trim(),
            });

            break;
          case "nombres":
            setFilter({
              ...filter,
              search: newValue.trim(),
            });

            break;
          default:
            break;
        }
      }
    }
  };

  //filtro
  const _buscarFiltro = () => {

    switch (tabIndex) {
      case '0':
        setFilter(filter);
        setup(filter);
        break;

      case '1':
        dataAdicional(filter);

        break;
      default:
        break;
    }

    //dismissPanelFiltro();
  }

  const _limpiarFiltro = () => {

    let modelClear: IFilterProgramacion = {
      ...filter,
      search: "",
      empresaPersonaId: "",
      empresaTransporteId: "",
      gerenciaId: "",
      lugarMovilizacionId: "",
      centroCostoId: "",
      origenId: "",
      destinoId: "",

    }
    setFilter(modelClear);
    setup(modelClear);


    switch (tabIndex) {
      case '0':
        setFilter(modelClear);
        setup(modelClear);
        break;

      case '1':
        dataAdicional(modelClear);

        break;
      default:
        break;
    }

  }

  const _actualizarTransporte = (programacionPersonaId: string) => {

    console.log(programacionPersonaId)

    toggleHideWaitActualizarTransporte()
    PersonaService.putProgramacionPersonaTransporte(programacionPersonaId)
      .then((res) => {
        if (res.status == 200) {
          // alert("Se ha actualizado correctamente!")
          toggleHideWaitActualizarTransporte();
          toggleHideSuccessActualizarTransporte();
          setup();

        }
      })
      .catch(function (error) {
        if (error) {
          setMensajeTransaccion(error.response.data.detail);
          toggleHideWaitActualizarTransporte();
          toggleHideErrorActualizarTransporte();

        }

      });
  }


  return (
    <>
      <HeadingBar
        title={data.title}
        subTitle={data.subTitle}
        searchPlaceholder="Buscar por Nombres"
        searchChange={_onChangeText}
        searchHandler={_onPress}
        searchValue={filter.search ?? ''}
        onClearHandler={_onClearSearch}
      ></HeadingBar>

      <>
        <div style={{ padding: "20px" }}>
          {isShowBarGeneral &&
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <CustomCommandBar item={_rightBarGeneral()} farItem={_leftBarGeneral()} style={ThemeBase.notPaddingSide}></CustomCommandBar>
              </div>
            </div>
          }

          <Pivot
            aria-label="Pivot"
            selectedKey={tabIndex}
            onLinkClick={_onChangeTab}
          >
            {/* KeyUser & SSEE */}
            {isShowTabBus &&
              <PivotItem itemKey='0' headerText='Buses'>
                <TableBase leftBar={_leftBar().concat(_leftBarKeyUser())} rigthBar={_rightNormal()} column={columnNormal} data={data.items} selection={selection} pagination={pager} style={ThemeBase.notPaddingTableBase} isLoading={!hidden}></TableBase>
              </PivotItem>
            }

            {/* Admin (especificamente AreaTransporte que es el que aprueba los pendientes helo) */}
            {
              isShowTabHelo &&
              <PivotItem itemKey='2' headerText='Helo'>
                {/* v1. La validacion inscripcion helo + notificacion BCD */}
                {/* <TabAccesoPersonaVuelo ref={childRef} programacionId={id as string} isSubida={data.isSubida} ></TabAccesoPersonaVuelo> */}

                {/* v2. Listado general Helo + opciones edicion + opcion retirar */}
                <ListarAccesoHelo ref={childRef} programacionId={id as string} isSubida={data.isSubida}></ListarAccesoHelo>
              </PivotItem>
            }

            {isShowTabPersona && !isRolUsuario &&(
              <PivotItem itemKey='z' headerText='Personas'>
                <ListarBusAndVuelo ref={childRef} programacionId={id as string} tipoPersonal={state?.state?.tipoPersonal}></ListarBusAndVuelo>
              </PivotItem>)
            }

            {isShowTabAdicional && 

              <PivotItem itemKey='1' headerText='Adicionales'>
                <AccesoPersonaAdicional ref={childRef} programacionId={id as string} isAddAdicional={isAddAdicional} openForm={openCrearAcceso} openReporte={openPanelReporteAdicional} search={filter.search as string}></AccesoPersonaAdicional>
              </PivotItem>
            }
          </Pivot>

          {/*Panel agregar persona a programacion */}
          <NewAccessPerson programacionId={id} isOpen={isOpenCrearAcceso} onDismiss={dismissCrearAcceso} setup={eventTab} subTitle={`${data.title} | ${data.subTitle}`} isSubida={data.isSubida} codigoTipoProgramacion={data.codigoTipoProgramacion}></NewAccessPerson>

          {/* Panel Actualizar informacion */}
          <ActualizarAccesoPersona programacionPersonaId={selId} isOpen={isOpenActualizarAcceso} dismiss={dismissActualizarAcceso} reloadParent={eventTab}></ActualizarAccesoPersona>

        </div>

        {/* Panel aprobacion Key User */}
        <Panel
          isOpen={isOpenPanel}
          onDismiss={dismissPanel}
          headerText="Aprobar y enviar a Transportes"
          closeButtonAriaLabel="Close"
          onRenderFooterContent={onRenderFooterContent}
          isFooterAtBottom={true}
        >
          <IdicadorProgreso isSend={isValidate} />
          <div>
            {sel.map((item: { key: string | number }) => (
              <ActivityItem
                {...item}
                key={item.key}
                className={ThemeBase.activityClass.exampleRoot}
              />
            ))}
          </div>
        </Panel>

        {/* Modal - retirar persona con catalogo motivo */}
        <Modal
          isOpen={isOpenRetiro}
          onDismiss={() => stateRetiro(false)}
          isBlocking={false}
          containerClassName={ModalHelper.modalStyle.container}
        >
          <form>
            <div className={ModalHelper.modalStyle.header}>
              <span id={""}>Retirar Persona</span>
              <IconButton
                styles={ModalHelper.modalIconButtonStyles}
                iconProps={ModalHelper.modalCancelIcon}
                ariaLabel="Close popup modal"
                onClick={() => stateRetiro(false)}
              />
            </div>

            <div
              className={ModalHelper.modalStyle.body}
              style={{ minWidth: 500 }}
            >
              <Stack tokens={stackTokens}>
                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      <Persona
                        {...selected}
                        size={PersonaSize.size40}
                        hidePersonaDetails={false}
                        imageAlt="Annie Lindqvist, status is away"
                      />
                    </div>
                  </div>
                </div>

                <div className="ms-Grid" dir="ltr">
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                      <ComboBox
                        label="Motivo de retiro"
                        autoComplete="off"
                        useComboBoxAsMenuWidth
                        calloutProps={{ directionalHintFixed: true }}
                        options={motivo}
                        onChange={_onChangeMotivo}
                        required
                      />
                    </div>
                  </div>
                </div>

                <ButtonForm
                  back={() => stateRetiro(false)}
                  register={retirarPersona}
                ></ButtonForm>
              </Stack>
            </div>
          </form>
        </Modal>
        {/* Modal - retirar persona con catalogo motivo */}

        {/* v2. Panel carga masiva */}
        <Panel
          isOpen={isOpenCargaMasiva}
          onDismiss={dismissCargaMasiva}
          headerText="Carga Masiva"
          closeButtonAriaLabel="Close"
          onRenderFooterContent={_onRenderFooterCargaMasiva}
          isFooterAtBottom={true}
        >
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row">
              <div hidden={!isSendMasivo} style={ThemeBase.notPaddingSide}>
                <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} barHeight={3} />
              </div>

              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={ThemeBase.notPaddingSide}>
                {(choiceMasivo === 'error') && <CustomMessageBar type={typeMessage.TYPE_ERROR} message={textErrorMasivo} dissmiss={resetChoiceMasivo}></CustomMessageBar>}
                {(choiceMasivo === 'send') && <CustomMessageBar dissmiss={() => {
                  eventTab();
                }}></CustomMessageBar>}
              </div>
            </div>
          </div>

          <form hidden={(choiceMasivo === 'error' || choiceMasivo === 'send' || isSendMasivo) ? true : false}>
            <div style={{ paddingTop: "20%", paddingLeft: "0px" }}>

              <Label> Plantilla  </Label>
              <CustomCommandBar
                item={panelCargaMasivaBarDownload()}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              ></CustomCommandBar>
            </div>

            <div style={{ paddingTop: "30%", paddingLeft: "0px" }}>
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                <Label> Archivo  {iconWithTooltip} </Label>
                <CustomCommandBar
                  item={panelCargaMasivaBarUpload()}
                  style={{ paddingRight: "0px", paddingLeft: "0px" }}
                  farItem={panelCargaMasivaRightBarUpload()}
                ></CustomCommandBar>
              </div>

            </div>

            <div style={{ paddingTop: "40%", paddingLeft: "0px" }}>
              {fileDetail && (
                <>
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                      <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <FontIcon
                          aria-label={IconHelper.ICON_EXCEL.iconProps?.iconName}
                          iconName={IconHelper.ICON_EXCEL.iconProps?.iconName}
                          style={{
                            color: ThemeBase.theme.palette.blackTranslucent40,
                            fontSize: 40,
                            height: 40,
                            width: 40,
                            margin: "0 25px",
                          }}
                        />
                      </div>

                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <ActivityItem
                          activityDescription={[
                            <Link className={ThemeBase.activityClass.nameText}>
                              {fileDetail.nombre}
                            </Link>,
                            <span> </span>,
                            <span className={ThemeBase.activityClass.nameText}>
                              {fileDetail.tipo}
                            </span>,
                          ]}
                          timeStamp={`${(fileDetail.tamanio / 1048576).toFixed(
                            2
                          )} mb`}
                          className={ThemeBase.activityClass.exampleRoot}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>

        </Panel>

        {/* Panel Retiro Masivo Excel*/}
        <Panel
         isOpen={isOpenRetiroMasivoExcel}
         onDismiss={dismissRetiroMasivoExcel}
         headerText="Retiro Masivo por Excel"
         closeButtonAriaLabel="Close"
         //type={PanelType.extraLarge}
         onRenderFooterContent={_onRenderFooterRetiroMasivoExcel}
         isFooterAtBottom={true}
        > 
         <div hidden={!isSendMasivo} style={ThemeBase.notPaddingSide}>
                <ProgressIndicator description={MessageHelper.MESSAGE_LOADING} barHeight={3} />
              </div>

          <form hidden={(choiceMasivo === 'error' || choiceMasivo === 'send' || isSendMasivo) ? true : false}>
            <div style={{ paddingTop: "20%", paddingLeft: "0px" }}>

              <Label> Plantilla Retiro Masivo </Label>
              <CustomCommandBar
                item={panelRetiroMasivoBarDownload()}
                style={{ paddingRight: "0px", paddingLeft: "0px" }}
              ></CustomCommandBar>
            </div>

            <div style={{ paddingTop: "30%", paddingLeft: "0px" }}>
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" >
                <Label> Archivo Retiro Masivo {iconWithTooltip} </Label>
                <CustomCommandBar
                  item={panelCargaMasivaBarUpload()}
                  style={{ paddingRight: "0px", paddingLeft: "0px" }}
                  farItem={panelCargaMasivaRightBarUpload()}
                ></CustomCommandBar>
              </div>

            </div>

            <div style={{ paddingTop: "40%", paddingLeft: "0px" }}>
              {fileDetail && (
                <>
                  <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                      <div
                        className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <FontIcon
                          aria-label={IconHelper.ICON_EXCEL.iconProps?.iconName}
                          iconName={IconHelper.ICON_EXCEL.iconProps?.iconName}
                          style={{
                            color: ThemeBase.theme.palette.blackTranslucent40,
                            fontSize: 40,
                            height: 40,
                            width: 40,
                            margin: "0 25px",
                          }}
                        />
                      </div>

                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <ActivityItem
                          activityDescription={[
                            <Link className={ThemeBase.activityClass.nameText}>
                              {fileDetail.nombre}
                            </Link>,
                            <span> </span>,
                            <span className={ThemeBase.activityClass.nameText}>
                              {fileDetail.tipo}
                            </span>,
                          ]}
                          timeStamp={`${(fileDetail.tamanio / 1048576).toFixed(
                            2
                          )} mb`}
                          className={ThemeBase.activityClass.exampleRoot}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </form>
        </Panel>

        {/* v2. Panel detalle carga masiva */}
        <Panel
          isOpen={isDetalleCarga}
          onDismiss={dismissDetalleCarga}
          headerText=""
          closeButtonAriaLabel="Close"
          type={PanelType.custom}
          customWidth="888px"
          onRenderFooterContent={_onRenderFooterDetalleCargaMasiva}
          isFooterAtBottom={true}
        >
          <div>
            <HeadingBar
              title="Personas No Registradas"
              subTitle="Resultado Carga Masiva"
              searchShow={false}
            ></HeadingBar>
            <TableBase1
              leftBar={_rigthBarDetalleCarga()}
              column={columnDetalleCarga}
              data={detalleCarga ? detalleCarga.detalle : []}
              isLoading={loadingDetalleCarga}
            ></TableBase1>
          </div>
        </Panel>

        {/* v3. Panel retiro masivo */}
        <Panel
          isOpen={isOpenRetiroMasivo}
          onDismiss={dismissRetiroMasivo}
          headerText="Retirar personal de la programacion"
          closeButtonAriaLabel="Close"
          onRenderFooterContent={onRenderPanelRetiroMasivo}
          isFooterAtBottom={true}
        >
          <>
            <br />
            <ComboBox
              label="Motivo Retiro"
              autoComplete="off"
              useComboBoxAsMenuWidth
              calloutProps={{ directionalHintFixed: true }}
              options={motivo}
              openOnKeyboardFocus
              selectedKey={selMotivoIdMasivoId ?? ""}
              onChange={(e, o) => _onChangeCombo("motivo", e, o)}
              required
            />

            {sel.map((item: { key: string | number }) => (
              <ActivityItem
                {...item}
                key={item.key}
                className={ThemeBase.activityClass.exampleRoot}
              />
            ))}
          </>
        </Panel>

        {/* Panel Chart */}
        <Panel
          isOpen={isChart}
          onDismiss={dismissChart}
          headerText=""
          closeButtonAriaLabel="Close"
          type={PanelType.large}
          onRenderFooterContent={_onRenderFooterChart}
          isFooterAtBottom={true}
        >
          <div>
            <IconCard
              size=""
              style={{}}
              card={chartProgramacion ? chartProgramacion.card : []}
            ></IconCard>

            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">&nbsp;</div>
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                <span>Personas en la programación</span>
              </div>
              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">&nbsp;</div>

              <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                <BasicBarChartObsModelo
                  top={10}
                  right={5}
                  bottom={20}
                  left={25}
                  width={450}
                  height={300}
                  fill={ThemeBase.theme.palette.themeDarkAlt}
                  data={dataCruzada}
                ></BasicBarChartObsModelo>
              </div>
            </div>
          </div>
        </Panel>
      </>

      <div id='panelFiltro_Buses'>
        <>
          <PanelPlantillaFiltro
            textHeader='Filtro'
            subTextHeader='Elija las condiciones para el filtro personalizado'
            textBoton='Buscar'
            textBotonDefault='Limpiar'
            onclickBoton={_buscarFiltro}
            onclickBotonDefault={_limpiarFiltro}
            openPanel={isOpenPanelFiltro}
            closePanel={dismissPanelFiltro}
            // onRenderHeader={onRenderHeaderFilter}
            size={PanelType.medium}
            disabledBoton={false}
            estadoBarraEnvio={false}
            nameIconHeader={"Filter"}
            nameIconButton={"Search"}
          // dep={[filter, keysNivel]}
          >
            <>

              <div className="ms-Grid" dir="ltr">
                {/* <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg612" >
                    <TextField
                      // label='Nro Documento Identidad'
                      placeholder='Busque por nombres o DOI'
                      name='doi'
                      value={filter?.search != undefined ? filter?.search : ""}
                      onChange={(e, o) => _onChangeTextFiltro(e, o)}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <TextField
                      label='Apellidos y Nombres'
                      name='nombres'
                      value={filter?.search != undefined ? filter?.search : ""}
                      onChange={(e, o) => _onChangeTextFiltro(e, o)}
                    />
                  </div>
                </div>
                <br /> */}

                {tabIndex == "0" && (
                  <>
                    <div className="ms-Grid-row">
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <ComboBox
                          label="Empresa Persona:"
                          id="empresaPersona"
                          options={empresaPersonaCombo}
                          componentRef={empresaPersonaRef}
                          selectedKey={filter?.empresaPersonaId}
                          calloutProps={{ directionalHintFixed: true }}
                          useComboBoxAsMenuWidth
                          allowFreeform
                          onChange={(e, o) => _onChangeComboFiltro("empresaPersona", e, o)}
                          onKeyUp={_onKeyUpCombo}
                        // onBlur={_onBlurEmpresa}
                        />
                      </div>
                    </div>
                    <br />

                  </>
                )}
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <ComboBox
                      label="Empresa Transporte:"
                      id="empresaTransporte"
                      options={empresaCombo}
                      componentRef={empresaRef}
                      selectedKey={filter?.empresaTransporteId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) => _onChangeComboFiltro("empresaTransporte", e, o)}
                      onKeyUp={_onKeyUpCombo}
                    // onBlur={_onBlurEmpresa}
                    />
                  </div>
                </div>
                <br />
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <ComboBox
                      label="Gerencia:"
                      options={gerenciaCombo}
                      // componentRef={comboBoxRef}
                      selectedKey={filter?.gerenciaId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      // multiSelect
                      onChange={(e, o) => _onChangeComboFiltro("gerencia", e, o)}
                    />
                  </div>
                </div>
                <br />
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                    <ComboBox
                      label="Lugar Movilización:"
                      id="movilizacion"
                      options={lugarMovilizacionCombo}
                      componentRef={LugarMovilizacionRef}
                      selectedKey={filter?.lugarMovilizacionId}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      // placeholder='Gerencia'
                      autoComplete='off'
                      calloutProps={{ directionalHintFixed: true }}
                      onChange={(e, o) => _onChangeComboFiltro("movilizacion", e, o)}
                      onKeyUp={_onKeyUpCombo}
                    // onBlur={() => _onBlurCombo('gerencia')}
                    />

                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                    <ComboBox
                      label="Centro de Costo:"
                      id="centroCosto"
                      options={centroCostoCombo}
                      componentRef={centroCostoRef}
                      selectedKey={filter?.centroCostoId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) => _onChangeComboFiltro("centroCosto", e, o)}
                      onKeyUp={_onKeyUpCombo}

                    />
                  </div>
                </div>
                <br />
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                    <ComboBox
                      componentRef={embarqueRef}
                      label='Origen (Embarque)'
                      allowFreeform
                      autoComplete="off"
                      useComboBoxAsMenuWidth
                      calloutProps={{ directionalHintFixed: true }}
                      options={embarqueCombo}
                      // onKeyUp={_onUpEmbarque}
                      selectedKey={filter?.origenId}
                      onChange={(e, o) => _onChangeComboFiltro("origen", e, o)}
                    // onBlur={_onBlurEmbarque}

                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                    <ComboBox
                      componentRef={campamentoRef}
                      label='Destino (Desembarque)'
                      allowFreeform
                      autoComplete="off"
                      useComboBoxAsMenuWidth
                      calloutProps={{ directionalHintFixed: true }}
                      // onKeyUp={_onUpCampamento}
                      options={destinoCombo}
                      selectedKey={filter?.destinoId}
                      onChange={(e, o) => _onChangeComboFiltro("destino", e, o)}
                    // onBlur={_onBlurCampamento}
                    />
                  </div>
                </div>
              </div>
            </>
          </PanelPlantillaFiltro>

        </>

      </div>

      <div id='panelFiltro_Adicionales'>
        <>
          <PanelPlantillaFiltro
            textHeader='Filtro'
            subTextHeader='Elija las condiciones para el filtro personalizado'
            textBoton='Buscar'
            textBotonDefault='Limpiar'
            onclickBoton={_buscarFiltro}
            onclickBotonDefault={_limpiarFiltro}
            openPanel={isOpenPanelFiltroAdicional}
            closePanel={dismissPanelFiltroAdicional}
            // onRenderHeader={onRenderHeaderFilter}
            size={PanelType.medium}
            disabledBoton={false}
            estadoBarraEnvio={false}
            nameIconHeader={"Filter"}
            nameIconButton={"Search"}
          // dep={[filter, keysNivel]}
          >
            <>

              <div className="ms-Grid" dir="ltr">
                {/* <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg612" >
                    <TextField
                      // label='Nro Documento Identidad'
                      placeholder='Busque por nombres o DOI'
                      name='doi'
                      value={filter?.search != undefined ? filter?.search : ""}
                      onChange={(e, o) => _onChangeTextFiltro(e, o)}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <TextField
                      label='Apellidos y Nombres'
                      name='nombres'
                      value={filter?.search != undefined ? filter?.search : ""}
                      onChange={(e, o) => _onChangeTextFiltro(e, o)}
                    />
                  </div>
                </div>
                <br /> */}

                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <ComboBox
                      label="Empresa Transporte:"
                      id="empresaTransporte"
                      options={empresaCombo}
                      componentRef={empresaRef}
                      selectedKey={filter?.empresaTransporteId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) => _onChangeComboFiltro("empresaTransporte", e, o)}
                      onKeyUp={_onKeyUpCombo}
                    // onBlur={_onBlurEmpresa}
                    />
                  </div>
                </div>
                <br />
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                    <ComboBox
                      label="Gerencia:"
                      options={gerenciaCombo}
                      // componentRef={comboBoxRef}
                      selectedKey={filter?.gerenciaId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      // multiSelect
                      onChange={(e, o) => _onChangeComboFiltro("gerencia", e, o)}
                    />
                  </div>
                </div>
                <br />
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                    <ComboBox
                      label="Lugar Movilización:"
                      id="movilizacion"
                      options={lugarMovilizacionCombo}
                      componentRef={LugarMovilizacionRef}
                      selectedKey={filter?.lugarMovilizacionId}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      // placeholder='Gerencia'
                      autoComplete='off'
                      calloutProps={{ directionalHintFixed: true }}
                      onChange={(e, o) => _onChangeComboFiltro("movilizacion", e, o)}
                      onKeyUp={_onKeyUpCombo}
                    // onBlur={() => _onBlurCombo('gerencia')}
                    />

                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                    <ComboBox
                      label="Centro de Costo:"
                      id="centroCosto"
                      options={centroCostoCombo}
                      // componentRef={comboBoxRef}
                      selectedKey={filter?.centroCostoId}
                      calloutProps={{ directionalHintFixed: true }}
                      useComboBoxAsMenuWidth
                      allowFreeform
                      onChange={(e, o) => _onChangeComboFiltro("centroCosto", e, o)}

                    />
                  </div>
                </div>
                <br />
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                    <ComboBox
                      label='Origen (Embarque)'
                      componentRef={embarqueRef}
                      allowFreeform
                      autoComplete="off"
                      useComboBoxAsMenuWidth
                      calloutProps={{ directionalHintFixed: true }}
                      options={embarqueCombo}
                      // onKeyUp={_onUpEmbarque}
                      selectedKey={filter?.origenId}
                      onChange={(e, o) => _onChangeComboFiltro("origen", e, o)}
                    // onBlur={_onBlurEmbarque}

                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                    <ComboBox
                      label='Destino (Desembarque)'
                      options={[
                        { key: "1", text: "opcion 1" }
                      ]}
                    /* componentRef={campamentoRef}
                    allowFreeform
                    autoComplete="off"
                    useComboBoxAsMenuWidth
                    calloutProps={{ directionalHintFixed: true }}
                    // onKeyUp={_onUpCampamento}
                    options={destinoCombo}
                    selectedKey={filter?.destinoId}
                    onChange={(e, o) => _onChangeComboFiltro("destino", e, o)}
                // onBlur={_onBlurCampamento} */
                    />
                  </div>
                </div>
              </div>
            </>
          </PanelPlantillaFiltro>

        </>

      </div>

      {/* Panel - Reporte - Telerik */}
      <RepoteBaseGenerico
        headerName={`Programacion de Personal`}
        NameReport={reportTelerik.reportName}
        Parameters={reportTelerik.param}
        openTelerik={isOpenTelerik}
        dismissTelerik={dismissTelerik}
      ></RepoteBaseGenerico>

      {/* Panel - CuadroMandoProgramacion - Telerik */}
      <RepoteBaseGenerico
        headerName={`Reporte`}
        NameReport={"MovCmProgramciones.trdx"}
        Parameters={{ ProgramacionId: id }}
        openTelerik={isOpenTelerikCM}
        dismissTelerik={dismissTelerikCM}
      ></RepoteBaseGenerico>

      {/* Reporte Adicionales */}
      <RepoteBaseGenerico
        headerName={`Personal Adicional`}
        NameReport="AccesoPersonaAdicional.trdx"
        Parameters={{ ProgramacionId: id }}
        openTelerik={isReporteAdicional}
        dismissTelerik={dismissReporteAdicional}
      ></RepoteBaseGenerico>

      {/* Dialog Aprobar todo - Pasar aprobacion KeyUser */}
      <DialogBase
        isHidden={hideApproveAll}
        subTitle={'Todos los colaboradores inscritos en la programación pasaran la aprobación de KeyUser'}
        tipo={DialogType.normal}
        toggleHidden={() => {
          dismissLoadingApproveAll();
          toggleApproveAll();
        }}
        dependency={[]}
        confirm={validarProgramacion}
        isDisableConfirm={isLoadingApproveAll}
      ></DialogBase>

      {/* Panel Log */}
      <LogActividad programacionPersonaId={selId} isOpen={isOpenLog} dismiss={dismissLog}></LogActividad>

      <div id="dialogAlertActualizarT">
        <DialogBaseAlert
          hideDialog={hideAlertActualizarTransporte}
          toggleHideDialog={toggleHideAlertActualizarTransporte}
          subText={`¿Está seguro de realizar esta acción?, al aceptar no se tendrá opción de asignarse transporte nuevamente`}
          onclickButtonPrimary={() => _actualizarTransporte(programacionPersonaId)}
        />
      </div>
      <div id="dialogWaitActualizarT">
        <DialogBaseWait
          hideDialog={hideWaitActualizarTransporte}
          toggleHideDialog={toggleHideWaitActualizarTransporte}
        />
      </div>
      <div id="dialogSuccessActualizarT">
        <DialogBaseSuccess
          hideDialog={hideSuccessActualizarTransporte}
          toggleHideDialog={toggleHideSuccessActualizarTransporte}
          subText={`Se ha actualizado correctamente`}
        />
      </div>
      <div id="dialogErrorEActualizarT">
        <DialogBaseError
          hideDialog={hideErrorActualizarTransporte}
          toggleHideDialog={toggleHideErrorActualizarTransporte}
          subText={mensajeTransaccion}
        />
      </div>
      <div id="dialogErrorMensajeLog">
        <DialogBaseWarning
          hideDialog={hideErrorMensajeLog}
          toggleHideDialog={toggleHideErrorMensajeLog}
          subText={mensajeTransaccion}
          onclickButtonPrimary={() => openDetalleCarga()}

        />
      </div>
    </>
  );
};
