import {
  CommandBar,
  ICommandBarItemProps,
  Panel,
  PanelType,
  ComboBox,
  IComboBoxOption,
  IComboBox,
  Label,
  PrimaryButton
} from "@fluentui/react";
import { urlTelerik } from "../../services/http-common";
import { TelerikReportViewer } from "@progress/telerik-react-report-viewer";
import React, { useEffect, useState } from "react";
import TokenService from "../../services/TokenService";
import { IconHelper } from "../../utils/Helper/iconHelper";
import { IExportarReporte, IParametro } from "../../interfaces/base/IBase";
import reporteServicio from "../../services/reporteService";
import { keyAccess } from "../../utils/Helper/helper";
import AuthService from "../../services/AuthService";
import ThemeBase from "../../utils/Theme/themeBase";

let _nameReport: string;
let _parameters: any;
let _openTelerik: boolean;
let _headerName: string;
const _dismissTelerik = () => { };

function RepoteBaseGenerico({
  NameReport = _nameReport,
  Parameters = _parameters,
  openTelerik = _openTelerik,
  headerName = _headerName,
  dismissTelerik = _dismissTelerik,
  type = PanelType.large
}) {
  const [key, setKey] = useState(0);
  const [selectedFormat, setSelectedFormat] = useState<string>("PDF"); // Default to PDF

  const comboBoxOptions: IComboBoxOption[] = [
    { key: "PDF", text: "PDF" },
    { key: "XLSX", text: "Excel" },
  ];

  const handleFormatChange = (
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (option) {
      setSelectedFormat(option.key as string);
    }
  };

  function descargarReporte() {
    const parametrosactualizar =
      Parameters && Object.keys(Parameters).length > 0
        ? transformarParametros(Parameters)
        : [];

    const reporteExportar: IExportarReporte = {
      report: NameReport,
      Extension: selectedFormat, // Use el formato seleccionado (PDF o Excel)
      Parametros: parametrosactualizar,
    };

    reporteServicio
      .exportaReporte(reporteExportar)
      .then((response) => {
        if (response.status === 200) {
          const dataUrl = response.data;
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = NameReport.split(".")[0] + (selectedFormat === "XLSX" ? ".xlsx" : ".pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error("Status:", response.status);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

  }

  const transformarParametros = (parametros: Record<string, string>): IParametro[] => {
    return Object.entries(parametros).map(([key, value]) => ({
      Key: key,
      Value: value,
    }));
  };

  useEffect(() => {
    const hideExportButton = () => {
      const exportButton: any = document.querySelector(
        "#trv-main-menu-export-command"
      );
      if (exportButton) {
        exportButton.remove();
        clearInterval(interval);
      }
    };
    const interval = setInterval(hideExportButton, 1);
    return () => clearInterval(interval);
  }, [key]);

  return (
    <>
      <Panel
        isOpen={openTelerik}
        onDismiss={dismissTelerik}
        headerText={headerName || "Reporte"}
        type={type}
        closeButtonAriaLabel="Close"
      >
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "10px"
          }}
        >
          <Label>Seleccione formato</Label>
          <ComboBox
            selectedKey={selectedFormat}
            options={comboBoxOptions}
            onChange={handleFormatChange}
            styles={{ root: { minWidth: "150px" } }}
          />
          <PrimaryButton onClick={descargarReporte} styles={ThemeBase.panelButtonStyle}>
            Descargar
          </PrimaryButton>
        </div>

        <TelerikReportViewer
          serviceUrl={urlTelerik}
          reportSource={{
            report: NameReport,
            parameters: { ...Parameters },
          }}
          viewerContainerStyle={{
            position: "absolute",
            left: "5px",
            right: "5px",
            top: "95px",
            bottom: "5px",
            overflow: "hidden",
            clear: "both",
            fontFamily: "ms sans serif",
          }}
          viewMode="INTERACTIVE"
          scaleMode="SPECIFIC"
          scale={1.0}
          enableAccessibility={false}
          authenticationToken={TokenService.getToken()}
        />
      </Panel>
    </>
  );
}

export default RepoteBaseGenerico;
