import { FunctionComponent, useContext, useEffect, useState } from "react";
import { useBoolean, useId } from "@fluentui/react-hooks";
import {
  Callout,
  DefaultButton,
  FontWeights,
  IIconProps,
  IPersonaSharedProps,
  mergeStyleSets,
  Persona,
  PersonaInitialsColor,
  PersonaPresence,
  PersonaSize,
  Stack,
} from "@fluentui/react";
import { IListarPersonaById, IPersona } from "../../interfaces/IPersona";
import AuthService from "../../services/AuthService";
import {
  CLAIM_PERSONA_ID,
  CLAIM_USER_NAME,
} from "../../utils/Helper/roleHelper";
import {
  baseImageUrl,
  splitExceptionDetail,
  stringToUpperCamelCase,
  validarDominio,
} from "../../utils/Helper/helper";
import PersonaService from "../../services/PersonaService";
import { IException } from "../../interfaces/base/IBase";
import { CambiarContrasenia } from "../cambiarContrasenia/CambiarContrasenia";
import usuarioServicio from "../../services/UsuarioService";
import { AuthContext } from "../../auth/context/auth-context";
import { useNavigate } from "react-router-dom";

interface LogoutProps {
  /**NOMBRE DE USUARIO LOGEADO */
  userName?: string;
  /**EMAIL DE USUARIO LOGEADO */
  email?: string;
  /**LINK QUE TE LLEVA AL PERFIL DE USUARIO LOGEADO */
  linkPerfil?: string;
  /**EVENTO PARA MANEJAR EL LOGOUT, AL DAR CLICK EN El BOTON CERRAR SESION */
  handleSignout?:
  | React.MouseEventHandler<
    HTMLSpanElement | HTMLAnchorElement | HTMLButtonElement | HTMLDivElement
  >
  | undefined;
}

const Logout: FunctionComponent<LogoutProps> = (prop) => {
  /**LOGOUT */
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] =
    useBoolean(false);
  const buttonId = useId("callout-button");
  const labelId = useId("callout-label");
  const descriptionId = useId("callout-description");
  const [info, setInfo] = useState<IPersona>(null!);

  const { signout, access } = useContext(AuthContext);

  const navigate = useNavigate();
  const _handleSignout = () => {
    signout(() => navigate(`login`, { replace: true }));
  };

  const [usuarioDominio, setUsuarioDominio] = useState(true);

  const [
    isOpenPanelCambiar,
    { setTrue: openPanelCambiar, setFalse: dismissPanelCambiar },
  ] = useBoolean(false);

  const onRenderPrimaryText = () => (
    <p className={stylesLogout.onRenderPrimaryText}>
      <strong>
        {/* {JSON.stringify(obtenerUsuario(),null,1)} */}
        {stringToUpperCamelCase(
          info
            ? info.primerNombre + " " + info.apellidoPaterno
            : obtenerNombreUsuario() !== ""
              ? obtenerNombreUsuario()
              : prop.userName!
        )}
      </strong>
    </p>
  );
  const onRenderSecondaryText = () => (
    <p className={stylesLogout.onRenderSecondaryText}>
      {prop.email?.toLocaleLowerCase()}{" "}
    </p>
  );
  const onRenderTertiaryText = () => (
    <h5 className={stylesLogout.onRenderTertiaryText}>En linea</h5>
  );
  // const onRenderOptionalText = () => (
  //     <>
  //         <Link to={prop.linkPerfil ? prop.linkPerfil : ""}>Ver perfil</Link>
  //     </>
  // )

  /* api */
  useEffect(() => {
    dataPersona();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (prop.email !== undefined) {
      setUsuarioDominio(validarDominio(prop.email));
    }
  }, [prop.email]);

  // /**PersonaId de usuario */
  function obtenerUsuario() {
    return AuthService.getInfoToken(CLAIM_PERSONA_ID);
  }

  // /**Nombre  de usuario */
  function obtenerNombreUsuario() {
    return AuthService.getInfoToken(CLAIM_USER_NAME);
  }

  /* Api */
  const dataPersona = () => {
    if (obtenerUsuario() !== "") {
      let d: IListarPersonaById = {
        listaPersonas: [obtenerUsuario()],
      };

      // PersonaService.getInfoPersonaById(d)
      PersonaService.getListaDetalleUsuario()
        .then((res) => {
          if (res.status == 200) {
            setInfo(res.data[0]);
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
        });
    }
  };

  // const dataPersona = () => {
  // if (obtenerUsuario() !== "") {
  // personaServicio
  //     .personaByID(obtenerUsuario())
  //     .then((res) => {
  //         setInfo(res.data);
  //     })
  //     .catch((e: IException) => {
  //         alert(splitExceptionDetail(e));
  //     });
  // }
  // };

  const logoutUser = () => {
    usuarioServicio
      .logout({})
      .then((res) => {
        //setInfoLogout(res.data);
      })
      .catch((e: IException) => {
        alert(e);
      });
  };

  const examplePersona: IPersonaSharedProps = {
    // imageUrl: TestImages.personaMale,
    // text: `${prop.userName}`,
    // optionalText: 'En linea ',
    imageUrl: info ? info.foto : baseImageUrl,
    imageInitials: "?",
    secondaryText: "En linea",
    tertiaryText: "En linea",
  };
  return (
    <>
      <Persona
        {...examplePersona}
        id={buttonId}
        initialsColor={PersonaInitialsColor.warmGray}
        imageInitials={`${prop.userName?.substring(0, 1)}`}
        size={PersonaSize.size32}
        presence={PersonaPresence.online}
        imageAlt="status is online"
        onClick={toggleIsCalloutVisible}
        className={stylesLogout.persona1}
      />
      {isCalloutVisible && (
        <Callout
          className={stylesLogout.callout}
          ariaLabelledBy={labelId}
          ariaDescribedBy={descriptionId}
          role="dialog"
          gapSpace={0}
          target={`#${buttonId}`}
          onDismiss={toggleIsCalloutVisible}
          setInitialFocus>
          {/* <Stack verticalAlign="center" horizontal horizontalAlign="space-between" style={{ fontSize: '0.6875rem' }}>
                        <span>.</span>
                        <DefaultButton
                            text="Cerrar sesion"
                            onClick={prop.handleSignout}
                            className={stylesLogout.buttonSesion}
                        />
                    </Stack> */}
          <br />
          <Persona
            {...examplePersona}
            id={buttonId}
            initialsColor={PersonaInitialsColor.warmGray}
            imageInitials={`${prop.userName?.substring(0, 1)}`}
            onRenderPrimaryText={onRenderPrimaryText}
            onRenderSecondaryText={onRenderSecondaryText}
            onRenderTertiaryText={onRenderTertiaryText}
            size={PersonaSize.size72}
            presence={PersonaPresence.online}
            imageAlt="Annie Lindqvist, status is online"
            onClick={toggleIsCalloutVisible}
            className={stylesLogout.persona1}
          />
          <br />
          <div hidden={usuarioDominio}>
            <Stack verticalAlign="start" horizontal horizontalAlign="start">
              <DefaultButton
                onRenderText={(arg) => <span>Actualizar Contraseña</span>}
                // iconProps={{ iconName: 'Lock12', className: stylesLogout.iconPropsCerrarSesion }}
                className={stylesLogout.buttonOtherSesion}
                onClick={() => {
                  openPanelCambiar();
                }}
              />
            </Stack>
          </div>
          <br />
          <Stack verticalAlign="start" horizontal horizontalAlign="start">
            <DefaultButton
              key={"cerrar"}
              onRenderText={(arg) => (
                <span key={"cerrarSesion"}>Cerrar sesión</span>
              )}
              // iconProps={{ iconName: 'Lock12', className: stylesLogout.iconPropsCerrarSesion }}
              className={stylesLogout.buttonOtherSesion}
              //onClick={prop.handleSignout}
              onClick={() => {
                logoutUser();
                // props._handleSignout;
                if (prop.handleSignout) {
                  _handleSignout();
                }
              }}
            />
          </Stack>
        </Callout>
      )}
      <CambiarContrasenia
        isOpen={isOpenPanelCambiar}
        onDismiss={dismissPanelCambiar}
        email={prop.email?.toLocaleLowerCase()}
      />
    </>
  );
};

export default Logout;

const stylesLogout = mergeStyleSets({
  button: {
    width: 130,
  },
  callout: {
    width: 350,
    maxWidth: "90%",
    padding: "10px 14px",
  },
  title: {
    marginBottom: 12,
    fontWeight: FontWeights.semilight,
  },
  link: {
    display: "block",
    marginTop: 20,
  },
  persona1: {
    color: "#fff",
    cursor: "pointer",
  },
  buttonSesion: {
    border: "none",
    fontSize: "0.6875rem",
  },
  buttonOtherSesion: {
    padding: "1.2rem",
    border: "none",
    width: "100%",
    textAlign: "start",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: "none",
  },
  iconPropsCerrarSesion: {
    fontSize: "1.4rem",
    fontWeight: "lighter",
    padding: "0.3125rem",
  },
  onRenderPrimaryText: {
    padding: 0,
    margin: 2,
    fontSize: "1rem",
  },
  onRenderSecondaryText: {
    padding: 0,
    margin: 2,
    fontSize: "0.75rem",
  },
  onRenderTertiaryText: {
    padding: 0,
    margin: 2,
    fontSize: "0.75rem",
  },
});
