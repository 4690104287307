import {
  DefaultButton,
  DialogType,
  IColumn,
  IComboBox,
  IComboBoxOption,
  ICommandBarItemProps,
  Icon,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  TextField,
  Toggle,
  TooltipHost,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { HeadingBar } from "../../../components/Generic/HeadingBar";
import { TableBase } from "../../../components/Table/TableBase";
import { IException } from "../../../interfaces/base/IBase";
import { IGuardia, IGuardiaFilter } from "../../../interfaces/IGuadia";
import GuardiaService from "../../../services/guardiaService";
import {
  splitExceptionDetail,
  stackTokens,
  stackTokensHorizontal,
} from "../../../utils/Helper/helper";
import { IconHelper } from "../../../utils/Helper/iconHelper";
import ThemeBase from "../../../utils/Theme/themeBase";
import { useBoolean } from "@fluentui/react-hooks";
import { DialogBase } from "../../../components/Dialog/DialogBase";
import { ConfigHelper } from "../../../utils/Helper/configHelper";
import ListaPax from "./ListaPax";
import HeaderGeneric from "../../../components/Generic/headerPanel";

const initialProgramacionGuardia = () => {
  let t: IGuardia = {
    codigo: "",
    nombre: "",
    trabajo: 0,
    descanso: 0,
    dia: false,
    noche: false,
    isPax: false,
  };

  return t;
};

const buttonStyles = {
  root: {
    background: "transparent",
    border: "none",
    minWidth: "16px",
    padding: 0,
  },
};

export const ListarProgramacionGuardia = () => {
  const navigate = useNavigate();

  const [hidden, setHidden] = useState(false);
  const [data, setData] = useState<IGuardia[]>([]);
  const [column, setColumn] = useState<IColumn[]>([]);

  const [form, setForm] = useState<IGuardia>(initialProgramacionGuardia);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  let initialFilter: IGuardiaFilter = {
    skip: 0,
    take: 50,
    search: "",
  };

  /* Dialog */
  const [hideEliminarGuardia, { toggle: toggleEliminarGuardia }] =
    useBoolean(true);
  const [selId, setSelId] = useState<string | undefined>(undefined);

  /* Panel Form Registrar / Editar */
  const [isOpenPanel, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  /* Panel lista de personas Pax*/
  const [isOpenListaPax, { setTrue: openListaPax, setFalse: dismissListaPax }] =
    useBoolean(false);
  const [itemSelected, setItemSelected] = useState<IGuardia>();

  const [showTooltip, setShowTooltip] = React.useState(false);
  const [disableForm, setDisableForm] = React.useState(true);

  /* Hook */
  useEffect(() => {
    setTitleColumn();
    setup();
  }, []);

  useEffect(() => {
    validarForm();
  }, [form]);

  const validarForm = () => {
    if (!form) {
      setDisableForm(true);
      return;
    }

    const { codigo, nombre, trabajo, dia, noche, descanso } = form;

    const isFormValid =
      codigo && nombre && trabajo > 0 && descanso > 0 && (dia || noche);

    setDisableForm(!isFormValid);
  };

  /* Api */
  const dataGuardia = (filter: IGuardiaFilter) => {
    setData([]);
    GuardiaService.allGuardia(filter)
      .then((res) => {
        setData(res.data);
        setHidden(true);
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
        setHidden(true);
      });
  };

  const registrarGuardia = () => {
    GuardiaService.addGuardia(form)
      .then((res) => {
        if (res.status == 200) {
          setup();
          setup();
          dismissPanel();
          alert("Se registró correctamente");
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const actualizarGuardia = () => {
    GuardiaService.editGuardia(form)
      .then((res) => {
        if (res.status == 200) {
          setup();
          setup();
          dismissPanel();
          alert("Se actualizó correctamente");
        }
      })
      .catch((e: IException) => {
        alert(splitExceptionDetail(e));
      });
  };

  const eliminarGuardia = () => {
    if (selId != undefined) {
      GuardiaService.deleteGuardia(selId)
        .then((res) => {
          if (res.status == 200) {
            setup();
            setup();
            toggleEliminarGuardia();
            alert("Se eliminó correctamente");
          }
        })
        .catch((e: IException) => {
          alert(splitExceptionDetail(e));
        });
    }
  };

  /* Function */
  const setup = () => {
    dataGuardia(initialFilter);
  };

  const _leftBar = () => {
    const _items: ICommandBarItemProps[] = [
      {
        ...IconHelper.ICON_BACK,
        onClick: () => navigate(`${ConfigHelper.urlBase()}/configuraciones`),
      },
      {
        ...IconHelper.ICON_ADD,
        onClick: () => {
          setForm(initialProgramacionGuardia);
          setIsAdd(true);
          setIsEdit(false);
          openPanel();
        },
      },
      {
        ...IconHelper.ICON_RELOAD,
        onClick: setup,
      },
    ];
    return _items;
  };

  const setTitleColumn = () => {
    let col: IColumn[] = [
      {
        key: "column1",
        name: "Codigo",
        fieldName: "codigo",
        minWidth: 70,
        maxWidth: 80,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column2",
        name: "Nombre",
        fieldName: "nombre",
        minWidth: 90,
        maxWidth: 90,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column3",
        name: "Días Trabajo",
        fieldName: "trabajo",
        minWidth: 70,
        maxWidth: 80,
        isResizable: true,
        data: "number",
        isPadded: true,
      },
      {
        key: "column4",
        name: "Días Descanso",
        fieldName: "descanso",
        minWidth: 70,
        maxWidth: 80,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column5",
        name: "Turno Día",
        fieldName: "dia",
        minWidth: 80,
        maxWidth: 90,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (item: IGuardia) => {
          return <span>{`${item.dia ? "Si" : "No"}`}</span>;
        },
      },
      {
        key: "column6",
        name: "Turno Noche",
        fieldName: "noche",
        minWidth: 80,
        maxWidth: 90,
        isResizable: true,
        data: "string",
        isPadded: true,
        onRender: (item: IGuardia) => {
          return <span>{`${item.noche ? "Si" : "No"}`}</span>;
        },
      },
      {
        key: "column7",
        name: "Fecha Creación",
        fieldName: "creacion",
        minWidth: 90,
        maxWidth: 100,
        isResizable: true,
        data: "string",
        isPadded: true,
      },
      {
        key: "column8",
        name: "Opciones",
        fieldName: "",
        minWidth: 90,
        maxWidth: 100,
        isResizable: true,
        isCollapsible: true,
        data: "number",
        onRender: (item: IGuardia) => (
          <>
            <Stack
              horizontalAlign="end"
              horizontal
              tokens={stackTokensHorizontal}
            >
              <>
                <TooltipHost
                  content={`${item.isPax ? "Lista Staff" : "Desactivado"}`}
                >
                  <Icon
                    iconName="SecurityGroup"
                    style={{
                      cursor: "pointer",
                      color: item.isPax
                        ? ThemeBase.theme.palette.themePrimary
                        : "#e1dfdd",
                    }}
                    className={ThemeBase.classes.iconFont}
                    onClick={() => {
                      if (item.isPax) {
                        setItemSelected(item);
                        openListaPax();
                      }
                    }}
                  />
                </TooltipHost>
                <TooltipHost content={"Editar"}>
                  <Icon
                    iconName="Edit"
                    style={{ cursor: "pointer" }}
                    className={ThemeBase.classes.iconFont}
                    onClick={() => {
                      setForm({
                        ...item,
                      });
                      setIsAdd(false);
                      setIsEdit(true);
                      openPanel();
                    }}
                  />
                </TooltipHost>

                <TooltipHost content={`Eliminar`}>
                  <Icon
                    iconName="Delete"
                    style={{ cursor: "pointer" }}
                    className={ThemeBase.classes.iconFont}
                    onClick={() => {
                      setSelId(item.id?.toString());
                      toggleEliminarGuardia();
                    }}
                  />
                </TooltipHost>
              </>
            </Stack>
          </>
        ),
      },
    ];

    setColumn(col);
  };

  /* Event */
  const _onChangeText = (
    type: string,
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    if (event != undefined) {
      if (newValue != undefined) {
        switch (type) {
          case "codigo":
            setForm({
              ...form,
              codigo: newValue,
            });
            break;
          case "nombre":
            setForm({
              ...form,
              nombre: newValue,
            });
            break;
          case "trabajo":
            setForm({
              ...form,
              trabajo: !isNaN(parseInt(newValue)) ? parseInt(newValue) : 0,
            });
            break;
          case "descanso":
            setForm({
              ...form,
              descanso: !isNaN(parseInt(newValue)) ? parseInt(newValue) : 0,
            });
            break;
          default:
            break;
        }
      }
    }
  };

  const _onChangeToggle = (
    type: string,
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    if (checked != undefined) {
      switch (type) {
        case "dia":
          setForm({
            ...form,
            dia: checked,
          });
          break;
        case "noche":
          setForm({
            ...form,
            noche: checked,
          });
          break;
        case "pax":
          setForm({
            ...form,
            isPax: checked,
          });
          break;
        default:
          break;
      }
    }
  };

  const onRenderHeader = React.useCallback(
    () => (
      <HeaderGeneric
        title={`${isAdd ? "Registrar Guardia" : "Actualizar Guardia"}`}
        subTitle={`${
          isAdd
            ? "Complete el formulario para registrar una guardia"
            : "Complete el formulario para actualizar la guardia"
        }`}
      ></HeaderGeneric>
    ),
    [dismissPanel, isAdd]
  );

  /* Footer Panel */
  const onRenderFooterContent = React.useCallback(
    () => (
      <div>
        <PrimaryButton
          onClick={
            isAdd ? registrarGuardia : isEdit ? actualizarGuardia : () => {}
          }
          styles={ThemeBase.panelButtonStyle}
          disabled={disableForm}
        >
          Confirmar
        </PrimaryButton>
        <DefaultButton onClick={dismissPanel}>Cancelar</DefaultButton>
      </div>
    ),
    [dismissPanel, form, disableForm]
  );

  const iconWithTooltip = (
    <>
      <TooltipHost
        content={
          showTooltip
            ? "Solo personal en lista podra ser incrito en las programaciones"
            : undefined
        }
        id="InfPax"
      >
        <DefaultButton
          aria-label={"more info"}
          aria-describedby={showTooltip ? "InfPax" : undefined}
          onClick={() => setShowTooltip(!showTooltip)}
          styles={buttonStyles}
          iconProps={{ iconName: "Info" }}
        />
      </TooltipHost>
    </>
  );

  return (
    <>
      <HeadingBar
        title="Guardias"
        subTitle="Gestión de guardias"
        searchShow={false}
      ></HeadingBar>

      <TableBase
        leftBar={_leftBar()}
        column={column}
        data={data}
        isLoading={!hidden}
      ></TableBase>

      {/* Panel Crear / Actualizar */}
      <Panel
        isOpen={isOpenPanel}
        onDismiss={dismissPanel}
        type={PanelType.medium}
        //headerText={`${isAdd ? "Registrar Guardia" : "Actualizar Guardia"}`}
        closeButtonAriaLabel="Close"
        onRenderHeader={onRenderHeader}
        onRenderFooterContent={onRenderFooterContent}
        isFooterAtBottom={true}
      >
        <div style={{ paddingTop: "5%" }}>
          <form>
            <Stack tokens={stackTokens}>
              <div className="ms-Grid" dir="ltr">
                <Stack tokens={stackTokens}>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <TextField
                        label="Código"
                        placeholder="Código"
                        value={form ? form.codigo : ""}
                        onChange={(e, o) => _onChangeText("codigo", e, o)}
                        autoComplete="off"
                        required
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <TextField
                        label="Nombre"
                        placeholder="Nombre"
                        value={form ? form.nombre : ""}
                        onChange={(e, o) => _onChangeText("nombre", e, o)}
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>

                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <TextField
                        label="Días Trabajo"
                        placeholder="Días Trabajo"
                        value={
                          form
                            ? form.trabajo
                              ? form.trabajo.toString()
                              : ""
                            : ""
                        }
                        onChange={(e, o) => _onChangeText("trabajo", e, o)}
                        autoComplete="off"
                        required
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <TextField
                        label="Días Descanso"
                        placeholder="Días Descanso"
                        value={
                          form
                            ? form.descanso
                              ? form.descanso.toString()
                              : ""
                            : ""
                        }
                        onChange={(e, o) => _onChangeText("descanso", e, o)}
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>

                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <Toggle
                        label="Turno Día"
                        onText="Si"
                        offText="No"
                        onChange={(e, v) => _onChangeToggle("dia", e, v)}
                        checked={form ? form.dia : false}
                      />
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <Toggle
                        label="Turno Noche"
                        onText="Si"
                        offText="No"
                        defaultChecked
                        onChange={(e, v) => _onChangeToggle("noche", e, v)}
                        checked={form ? form.noche : false}
                        // disabled
                      />
                    </div>
                  </div>

                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                      <Toggle
                        label={<div>Solo Staff {iconWithTooltip}</div>}
                        onText="Si"
                        offText="No"
                        defaultChecked
                        onChange={(e, v) => _onChangeToggle("pax", e, v)}
                        checked={form ? form.isPax : false}
                      />
                    </div>
                  </div>
                </Stack>
              </div>
            </Stack>
          </form>
        </div>
      </Panel>

      <Panel
        isOpen={isOpenListaPax}
        onDismiss={dismissListaPax}
        type={PanelType.medium}
        headerText={`Lista Pax | ${itemSelected?.nombre!}`}
        closeButtonAriaLabel="Close"
      >
        <ListaPax GuardiaId={itemSelected?.id!}></ListaPax>
      </Panel>

      <DialogBase
        isHidden={hideEliminarGuardia}
        subTitle="La guardia será eliminado permanentemente"
        tipo={DialogType.normal}
        toggleHidden={toggleEliminarGuardia}
        dependency={[]}
        confirm={eliminarGuardia}
      ></DialogBase>
    </>
  );
};
