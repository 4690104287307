import {
  ComboBox,
  DatePicker,
  Stack,
  TextField,
  IComboBoxOption,
  IComboBox,
  Toggle,
  ProgressIndicator,
} from "@fluentui/react";
import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PersonaService from "../../services/PersonaService";
import { IComboOptions } from "../../components/Generic/combo";
import { IException, IItemComboBaseDTO } from "../../interfaces/base/IBase";
import ProgramacionService from "../../services/ProgramacionService";
import { ButtonForm } from "../../components/Generic/buttonForm";
import { _formatDatePrefix } from "../../utils/Helper/datetimeHelper";
import {
  ItemSelectAll,
  splitExceptionDetail,
  stackTokens,
  _initialFilter,
  _selectionItemsKeys,
} from "../../utils/Helper/helper";
import { PanelGenerico } from "../../components/Generic/PanelGenerico";
import ThemeBase from "../../utils/Theme/themeBase";
import { AccesoGeneric } from "../accesoPersona/generic/accesoGeneric";
import {
  CustomMessageBar,
  typeMessage,
} from "../../components/MessageBar/customMessageBar";
import ConfiguracionService from "../../services/configuracionService";
import { Ifilter } from "../../interfaces/IFilter";
import { DialogBaseWarning } from "../../components/Dialog/DialogBaseWarning";
import { useBoolean } from "@fluentui/react-hooks";
export interface inputsFormulario {
  titulo?: string;
  acceso?: Date;
  cupos?: number;
  tieneCuarentena?: boolean;
  diasCierre?: number;
  guardiaId?: any;
  fechaRecurrencia?: Date;
  programacionId?: any;
  tipoProgramacionId?: string | null;
  turno?: string | null;
  isBajada?: boolean | null;
  validaEmpresa?: boolean;
  listaEmpresa?: string[]
  isParadaPlanta?: boolean;
}

export function EditProgramming({
  dismiss = () => { },
  setup = () => { },
  id = "",
  isOpen = false,
}) {
  const [model, setmodel] = useState<inputsFormulario | undefined>(undefined);
  const [guardia, setComboGuardia] = useState<IComboOptions[]>([]);
  const [tipoProgramacion, setTipoProgramacion] = useState<IItemComboBaseDTO[]>(
    []
  );
  const [turno, setTurno] = useState<IItemComboBaseDTO[]>(
    AccesoGeneric.initialTurno
  );

  /* Message Error & Choice */
  const [isSend, setIsSend] = useState(false);
  const [choice, setChoice] = React.useState<string | undefined>(undefined);
  const resetChoice = React.useCallback(() => setChoice(undefined), []);
  const [textError, settextError] = useState("Error");

  const [isSSEE, setIsSSEE] = useState(false);
  const [validaEmpresa, setValidaEmpresa] = useState(false);
  const [paradaPlanta, setParadaPlanta] = useState(false);

         
  const [filterEmpresa, setFilterEmpresa] = useState<Ifilter>(_initialFilter);
  const [empresaCombo, setEmpresaCombo] = useState<IItemComboBaseDTO[]>([])
  const [keysEmpresas, setKeysEmpresas] = useState<string[]>([]);
  const comboBoxRefEmpresa = React.useRef<IComboBox>(null);
  const _openOptionEmpresa = React.useCallback(() => comboBoxRefEmpresa.current?.focus(true), []);

  const [hideErrorMensajeLog, { toggle: toggleHideErrorMensajeLog }] = useBoolean(true);
  const [mensajeTransaccion, setMensajeTransaccion] = useState("");

  useEffect(() => {
    if (isOpen) {
      if (id != "") {
        infoDataProgramacion();
        dataComboGuardia();
        dataTipoProgramacion();
      }
    }
  }, [isOpen]);


  useEffect(() => {
    if(isOpen === true){
      dataEmpresa();
    }
   

  }, [filterEmpresa,isOpen])

  //LimpiarKeyEmpresas
  useEffect(() => {

    if (isOpen === false) {
      console.log("------ limpia keyEmpresas -----")
      setKeysEmpresas([])
    }

  }, [isOpen])


  /* Callback reload */
  const refForm = React.useCallback(() => {
    setmodel(null!);
    resetChoice();
  }, [dismiss]);

  function _onChangeTextFild(e: any, newValue: any) {
    console.log(e.target.name);
    setmodel({ ...model, [e.target.name]: newValue });
    console.log(newValue, model);
  }
  function _onChangeTextFildCierre(e: any, newValue: any) {
    console.log(e.target.name);
    setmodel({ ...model, [e.target.name]: newValue });

    console.log(newValue, model);
  }

  const editProgramacion = () => {
    setIsSend(true);

    console.log(keysEmpresas);


    let editModelo: inputsFormulario = {
      ...model,
      listaEmpresa: validaEmpresa ? keysEmpresas.filter(key => key !== "selectAll") : []
    }

    console.log(editModelo);

    PersonaService.putProgramacion(editModelo)
      .then((response) => {
        if (response.status == 200) {

          setup();
          dismiss();
          setIsSend(false);
          setmodel(model);
        }

        setmodel(model);
      })
      .catch((e: IException) => {
        setIsSend(false);
        setChoice("error");
        settextError(splitExceptionDetail(e));
      });

    console.log(model);
  };

  const infoDataProgramacion = () => {
    PersonaService.getInfoProgramacion(id)
      .then((response: any) => {
        if (response.status == 200) {
          console.log(response.data.tipoPersonal);

          setmodel({
            ...model,
            titulo: response.data.titulo,
            guardiaId: response.data.guardiaPersonalId,
            tieneCuarentena: response.data.tieneCuarentena,
            cupos: response.data.cupos,
            acceso: new Date(response.data.acceso),
            fechaRecurrencia: new Date(response.data.fechaRecurrencia),
            diasCierre: response.data.diasCierre,
            tipoProgramacionId: response.data.tipoProgramacionId,
            programacionId: id,
            turno: response.data.turno,
            isBajada: response.data.isBajada,
            validaEmpresa: response.data.validaEmpresa,
          });

          setValidaEmpresa(response.data.validaEmpresa);
          setParadaPlanta(response.data.isParadaPlanta);

          if (response.data.tipoPersonal === "SSEE") {
            setIsSSEE(true);
          } else {
            setIsSSEE(false);
          }

          if (response.data.listaEmpresas.length !== 0) {

            setKeysEmpresas(response.data.listaEmpresas)
          }

        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataComboGuardia = () => {
    PersonaService.comboGuardiaProgramacion(_initialFilter())
      .then((response: any) => {
        if (response.status == 200) {
          setComboGuardia(response.data);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const dataTipoProgramacion = () => {
    ProgramacionService.allTipoProgramacion()
      .then((res: any) => {
        if (res.status == 200) {
          setTipoProgramacion(res.data);
        }
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  function dataEmpresa() {

    ConfiguracionService.allEmpresa(filterEmpresa)
      .then((res) => {

        if (res.status == 200) {

          let t: IItemComboBaseDTO[] = res.data;
          t.unshift(ItemSelectAll());

          setEmpresaCombo(t)
        }

      })
      .catch(function (e: IException) {
        alert(e.response.data.detail)
      })
  }

  function _onChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
    console.log("toggle is " + (checked ? "checked" : "not checked"));
    setmodel({
      ...model,
      tieneCuarentena: checked ? true : false,
    });
  }

  const _onChangeCombo = (
    type: string,
    event: React.FormEvent<IComboBox>,
    option?: IComboBoxOption
  ) => {
    if (event != undefined) {
      if (option != undefined) {
        switch (type) {
          case "tipo":
            setmodel({
              ...model,
              tipoProgramacionId: String(option.key),
            });

            if (option.text === "MMG") {
              setIsSSEE(false)
            }

            if (option.text === "SSEE") {
              setIsSSEE(true)
            }

            break;
          case "guardia":
            setmodel({
              ...model,
              guardiaId: String(option.key),
            });
            break;
          case "turno":
            setmodel({
              ...model,
              turno: String(option.key),
            });
            break;
          case "empresa":

            if (keysEmpresas.length - 1 == 0 && option.selected == false) {

              //alert("La validación está por empresa, debe seleccionar almenos una empresa");
              setMensajeTransaccion("La validación está por empresa, debe seleccionar al menos una empresa")
              console.log(option.selected);
              toggleHideErrorMensajeLog()

            }

            console.log(keysEmpresas);
            _selectionItemsKeys(option, setKeysEmpresas, setEmpresaCombo, empresaCombo, keysEmpresas)

            break;
          default:
            break;
        }
      }
    }
  };

  const _onTooggleEmpresa = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    if (checked != null) {
      setValidaEmpresa(checked);
      setmodel({ ...model, validaEmpresa: checked });

      /* if (checked == false) {
        setKeysEmpresas([])
      } */

      console.log(checked)
    }
  };

  const _onTooggleParadaPlanta = (
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) => {
    if (checked != null) {
     setParadaPlanta(checked);
      setmodel({ ...model, isParadaPlanta: checked }); 
    }
  };

  const onRendeFooter = React.useCallback(
    () => (
      <div>
        <ButtonForm
          back={dismiss}
          register={editProgramacion}
          disabledRegister={isSend}
        ></ButtonForm>
      </div>
    ),
    [isOpen, model, isSend, isSSEE, validaEmpresa, keysEmpresas]
  );

  return (

    <div>
      <PanelGenerico
        isOpen={isOpen}
        onDismiss={dismiss}
        isOnRenderHeader
        disabledFooter
        iconHeaderName="DependencyAdd"
        title="Editar Programación"
        subTitle="Modifique el formulario para editar programación"
        onRendeFooter={onRendeFooter}
      >
        <div className="ms-Grid" dir="ltr">
          <div className="ms-Grid-row">
            <div hidden={!isSend} style={ThemeBase.notPaddingSide}>
              <ProgressIndicator
                description="Enviando espere un momento"
                barHeight={3}
              />
            </div>

            <div
              className="ms-Grid-col ms-sm12 ms-md12 ms-lg12"
              style={ThemeBase.notPaddingSide}
            >
              {choice === "error" && (
                <CustomMessageBar
                  type={typeMessage.TYPE_ERROR}
                  message={textError}
                  dissmiss={resetChoice}
                ></CustomMessageBar>
              )}
              {choice === "send" && (
                <CustomMessageBar
                  dissmiss={() => {
                    refForm();
                    resetChoice();
                  }}
                ></CustomMessageBar>
              )}
            </div>
          </div>
        </div>

        <form
          ref={refForm}
          hidden={
            choice === "error" || choice === "send" || isSend ? true : false
          }
        >
          <br />
          <div className="ms-Grid" dir="ltr">
            <br />
            <Stack tokens={stackTokens}>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg12">
                  <TextField
                    label="Título"
                    name="título"
                    placeholder={model?.titulo}
                    onChange={_onChangeTextFild}
                    defaultValue={model?.titulo}
                    value={model?.titulo}
                    styles={ThemeBase.textFileStyles}
                    required
                  />
                </div>
              </div>
              {/* <pre>ProgramacionId: {model?.programacionId}</pre>
              <pre>{JSON.stringify(keysEmpresas, null, 2)}</pre> */}
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <ComboBox
                    label="Tipo"
                    autoComplete="off"
                    useComboBoxAsMenuWidth
                    calloutProps={{ directionalHintFixed: true }}
                    options={tipoProgramacion}
                    selectedKey={model?.tipoProgramacionId}
                    onChange={(e, o) => _onChangeCombo("tipo", e, o)}
                    styles={ThemeBase.comboBoxStyles}
                    required
                  />
                </div>

                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <ComboBox
                    label="Guardia"
                    autoComplete="off"
                    useComboBoxAsMenuWidth
                    calloutProps={{ directionalHintFixed: true }}
                    options={guardia}
                    selectedKey={model?.guardiaId}
                    onChange={(e, o) => _onChangeCombo("guardia", e, o)}
                    styles={ThemeBase.comboBoxStyles}
                    required
                  />
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <DatePicker
                    label="Fecha Acceso"
                    allowTextInput
                    ariaLabel="Select a date. Input format is day slash month slash year."
                    onSelectDate={(date?: any) => {
                      setmodel({
                        ...model,
                        acceso: date,
                      });
                      // console.log(date?.toISOString());
                    }}
                    formatDate={(d) => _formatDatePrefix("F. Acceso", d)}
                    onChange={(e: any) => console.log(model)}
                    value={model?.acceso}
                    textField={{ styles: ThemeBase.textFileStyles }}
                  />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <DatePicker
                    label="Fecha Límite"
                    placeholder={model?.fechaRecurrencia?.toDateString()}
                    allowTextInput
                    ariaLabel="Select a date. Input format is day slash month slash year."
                    formatDate={(d) => _formatDatePrefix("F. Recurrencia", d)}
                    value={model?.fechaRecurrencia}
                    textField={{ styles: ThemeBase.textFileStyles }}
                    disabled
                  />
                </div>
              </div>
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <TextField
                    label="Días Cierre"
                    name="diasCierre"
                    placeholder="Días Cierre"
                    onChange={_onChangeTextFildCierre}
                    value={model?.diasCierre?.toString()}
                    styles={ThemeBase.textFileStyles}
                    type="number"
                  />
                </div>
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <TextField
                    label="Cupos"
                    name="cupos"
                    placeholder="Cupos"
                    onChange={_onChangeTextFild}
                    type="number"
                    value={model?.cupos?.toString()}
                    styles={ThemeBase.textFileStyles}
                  />
                </div>
              </div>
              <br />
              <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <Toggle
                    label="¿Es Parada Planta?"
                    placeholder="¿Validar Empresa?"
                    onText="Si"
                    offText="No"
                    onChange={_onTooggleParadaPlanta}
                    checked={paradaPlanta}
                  />
                </div>
              {isSSEE && (
                <>
                  <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                      <Toggle
                        label="¿Validar Empresa?"
                        placeholder="¿Validar Empresa?"
                        onText="Si"
                        offText="No"
                        onChange={_onTooggleEmpresa}
                        checked={validaEmpresa}

                      />
                    </div>
                  </div>

                  <div className="ms-Grid-row">

                    {validaEmpresa && (
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                        <ComboBox
                          label="Seleccionar Empresas"
                          useComboBoxAsMenuWidth
                          calloutProps={{ directionalHintFixed: true }}
                          options={empresaCombo}
                          openOnKeyboardFocus
                          selectedKey={keysEmpresas}
                          onChange={(e, o) => _onChangeCombo("empresa", e, o)}
                          required
                          multiSelect
                          allowFreeform={true}
                        //componentRef={comboBoxRefEmpresa}
                        //onKeyUp={_onUpEmpresa}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    {/* <pre>{JSON.stringify(model,null,2)}</pre> */}
                  </div>

                </>
              )}

              {/* <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
              <ComboBox
                label="Seleccionar Empresas"
                useComboBoxAsMenuWidth
                calloutProps={{ directionalHintFixed: true }}
                options={empresaCombo}
                openOnKeyboardFocus
                selectedKey={keysEmpresas}
                onChange={(e, o) => _onChangeCombo("empresa", e, o)}
                required
                multiSelect
                allowFreeform={true}
                //componentRef={comboBoxRefEmpresa}
                //onKeyUp={_onUpEmpresa}
              />
            </div> */}
              {/* <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                  <Toggle
                    label="Acuartelamiento"
                    placeholder="Tiene cuarentena"
                    onText="Tiene Acuartelamiento"
                    offText="No Tiene Acuartelamiento"
                    checked={model ? model.tieneCuarentena : false}
                    onChange={_onChange}
                  />
                </div>
              </div> */}
            </Stack>
          </div>
        </form>
        <div id="dialogErrorMensajeLog">
        <DialogBaseWarning
          hideDialog={hideErrorMensajeLog}
          toggleHideDialog={toggleHideErrorMensajeLog}
          subText={mensajeTransaccion}
        />
      </div>
      </PanelGenerico>
    
    </div>

  );
}
