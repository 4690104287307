import { ComboBox, IComboBox, IComboBoxOption, Stack } from '@fluentui/react'
import React, { useEffect, useState } from 'react'
import { ISearch } from '../../../../asistencia/src/Interfaces/base/IBase'
import { Ifilter } from '../../../../asistencia/src/Interfaces/filter'
import { ButtonForm } from '../../components/Generic/buttonForm'
import { PanelGenerico } from '../../components/Generic/PanelGenerico'
import { IException, IItemComboBaseDTO } from '../../interfaces/base/IBase'
import { IFilterHistorico } from '../../interfaces/filter/IFilterHistorico'
import { IFilterUbicacion } from '../../interfaces/filter/IFilterUbicacion'
import { IEmpresa } from '../../interfaces/IEmpresa'
import ContratoService from '../../services/contratoService'
import EmpresaService from '../../services/empresaService'
import OrganigramaService from '../../services/organigramaService'
import ProgramacionService from '../../services/ProgramacionService'
import UbicacionService from '../../services/ubicacionesServices'
import { IndexNone, ItemNoneSelect, splitExceptionDetail, stackTokens, _initialFilter, _initialPagination } from '../../utils/Helper/helper'
import { IconHelper } from '../../utils/Helper/iconHelper'
import { MessageHelper } from '../../utils/Helper/messageHelper'
import { _initialDataCollection } from '../../utils/Helper/tableHelper'
import { UbicacionHelper } from '../../utils/Helper/ubicacionHelper'

const { total, pageSize } = _initialPagination();
const _filter: IFilterHistorico = { skip: total, take: pageSize };
const _setFilterObject: React.Dispatch<React.SetStateAction<any>> = () => { };

export const BusquedaHistorico = ({ filterObject = _filter, setFilterObject = _setFilterObject, isOpen = false, onDismiss = () => { }, reloadInitial = () => { } }) => {
    const [filterBusqueda, setFilterBusqueda] = useState<IFilterHistorico>(filterObject);

    /* Combo */
    const [empresaPersona, setEmpresaPersona] = useState<IItemComboBaseDTO[]>([]);
    const [empresaTransporte, setEmpresaTransporte] = useState<IItemComboBaseDTO[]>([]);
    const [gerencia, setGerencia] = useState<IItemComboBaseDTO[]>([]);
    const [lugarMovilizacion, setLugarMovilizacion] = useState<IItemComboBaseDTO[]>([]);
    const [centroCosto, setCentroCosto] = useState<IItemComboBaseDTO[]>([]);
    const [origen, setOrigen] = useState<IItemComboBaseDTO[]>([]);
    const [destino, setDestino] = useState<IItemComboBaseDTO[]>([]);

    /* Filter */
    const [filterEmpresaPersona, setFilterEmpresaPersona] = useState<Ifilter>({
        ..._initialFilter()
    });
    const [filterEmpresaTransporte, setFilterEmpresaTransporte] = useState<Ifilter>({
        ..._initialFilter()
    });
    const [filterGerencia, setFilterGerencia] = useState<ISearch>({
        search: ''
    });
    const [filterLugMov, setFilterLugMov] = useState<ISearch>({
        search: ''
    });
    const [filterCentroCosto, setFilterCentroCosto] = useState<Ifilter>({
        ..._initialFilter()
    });
    const [filterOrigen, setFilterOrigen] = useState<IFilterUbicacion>({
        tipo: UbicacionHelper.TIPO_ORIGEN
    });
    const [filterDestino, setFilterDestino] = useState<IFilterUbicacion>({
        tipo: UbicacionHelper.TIPO_DESTINO,
    });

    /* Ref */
    const empresaPersonaRef = React.useRef<IComboBox>(null);
    const empresaTransporteRef = React.useRef<IComboBox>(null);
    const gerenciaRef = React.useRef<IComboBox>(null);
    const lugMovRef = React.useRef<IComboBox>(null);
    const centroCostoRef = React.useRef<IComboBox>(null);
    const origenRef = React.useRef<IComboBox>(null);
    const destinoRef = React.useRef<IComboBox>(null);

    /* Ref Open */
    const _openOptionEmpresaPersona = React.useCallback(() => empresaPersonaRef.current?.focus(true), []);
    const _openOptionEmpresaTransporte = React.useCallback(() => empresaTransporteRef.current?.focus(true), []);
    const _openOptionGerencia = React.useCallback(() => gerenciaRef.current?.focus(true), []);
    const _openOptionLugMov = React.useCallback(() => lugMovRef.current?.focus(true), []);
    const _openOptionCentroCosto = React.useCallback(() => centroCostoRef.current?.focus(true), []);
    const _openOptionOrigen = React.useCallback(() => origenRef.current?.focus(true), []);
    const _openOptionDestino = React.useCallback(() => destinoRef.current?.focus(true), []);

    /* Hook */
    useEffect(() => {
        if (isOpen === true) {
            dataEmpresa(filterEmpresaPersona, setEmpresaPersona);
            dataEmpresa(filterEmpresaTransporte, setEmpresaTransporte);
            dataGerencia();
            dataLugarMovilizacion();
            dataCentroCosto();
            dataUbicacion(filterOrigen, setOrigen);
            dataUbicacion(filterDestino, setDestino);
        }
    }, [isOpen]);

    useEffect(() => {
        setFilterObject(filterBusqueda);
    }, [filterBusqueda]);

    useEffect(() => {
        if (filterEmpresaPersona.search != null) {
            dataEmpresa(filterEmpresaPersona, setEmpresaPersona);
        }
    }, [filterEmpresaPersona]);

    useEffect(() => {
        if (filterEmpresaTransporte.search != null) {
            dataEmpresa(filterEmpresaTransporte, setEmpresaTransporte);
        }
    }, [filterEmpresaTransporte]);

    useEffect(() => {
        if(isOpen === true){
            dataGerencia();
            if (filterGerencia.search != null) {
                dataGerencia();
            }

        }
      
    }, [filterGerencia]);

    useEffect(() => {
        if (filterLugMov.search != null) {
            if(isOpen === true){
                dataLugarMovilizacion();

            }
        }
    }, [filterLugMov]);

    useEffect(() => {
        if (filterCentroCosto.search != null) {
            dataCentroCosto()
        }
    }, [filterCentroCosto]);

    useEffect(() => {
        if (filterOrigen.search != null) {
            dataUbicacion(filterOrigen, setOrigen);
        }
    }, [filterOrigen]);

    useEffect(() => {
        if (filterDestino.search != null) {
            dataUbicacion(filterDestino, setDestino);
        }
    }, [filterDestino]);

    /* Api */
    const dataEmpresa = (filtro: Ifilter, setCombo: React.Dispatch<React.SetStateAction<any>>) => {
        EmpresaService.all(filtro)
            .then((res) => {
                if (res.status == 200) {
                    let d: IEmpresa[] = res.data.items;
                    let t: IItemComboBaseDTO[] = d.map((x) => {
                        return {
                            key: x.empresaId,
                            text: x.nombre as string,
                        };
                    });

                    t.unshift(ItemNoneSelect());
                    setCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataGerencia = () => {
        setGerencia([]);
        OrganigramaService.organigramaByTipo('01', filterGerencia)
            .then((res) => {
                if (res.status == 200) {
                    let t: IItemComboBaseDTO[] = res.data;

                    t.unshift(ItemNoneSelect());
                    setGerencia(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataLugarMovilizacion = () => {
        ProgramacionService.allLugarMovilizacion()
            .then((res) => {
                let t: IItemComboBaseDTO[] = res.data;

                t.unshift(ItemNoneSelect());
                setLugarMovilizacion(t);
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataCentroCosto = () => {
        ContratoService.allCentroCosto1(filterCentroCosto)
            .then((res) => {
                let t: IItemComboBaseDTO[] = res.data;

                t.unshift(ItemNoneSelect());
                setCentroCosto(t);
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataUbicacion = (filtro: any, setCombo: React.Dispatch<React.SetStateAction<any>>) => {
        UbicacionService.ubicacionPorFiltro(filtro)
            .then((res) => {
                let t: IItemComboBaseDTO[] = res.data;

                t.unshift(ItemNoneSelect());
                setCombo(t);
            })
            .catch((e: IException) => {
                console.log(splitExceptionDetail(e));
            });
    };

    /* Function */

    /* Event */
    const onChangeCombo = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {
                let isNone = option.key == IndexNone;

                if (isNone) {
                    clearCombo(type);
                }

                switch (type) {
                    case 'empresaPersona':
                        if (!isNone) {
                            setFilterBusqueda({
                                ...filterBusqueda,
                                empresaPersonaId: option.key.toString(),
                            })
                        }
                        break;

                    case 'empresaTransporte':
                        if (!isNone) {
                            setFilterBusqueda({
                                ...filterBusqueda,
                                empresaTransporteId: option.key.toString(),
                            })
                        }
                        break;

                    case 'gerencia':
                        if (!isNone) {
                            setFilterBusqueda({
                                ...filterBusqueda,
                                gerenciaId: option.key.toString(),
                            })
                        }
                        break;
                    case 'lugMov':
                        if (!isNone) {
                            setFilterBusqueda({
                                ...filterBusqueda,
                                lugarMovilizacionId: option.key.toString()
                            })
                        }
                        break;
                    case 'centroCosto':
                        if (!isNone) {
                            setFilterBusqueda({
                                ...filterBusqueda,
                                centroCostoId: option.key.toString()
                            })
                        }
                        break;
                    case 'origen':
                        if (!isNone) {
                            setFilterBusqueda({
                                ...filterBusqueda,
                                origenId: option.key.toString()
                            })
                        }
                        break;
                    case 'destino':
                        if (!isNone) {
                            setFilterBusqueda({
                                ...filterBusqueda,
                                destinoId: option.key.toString()
                            })
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const clearCombo = (type: string) => {
        switch (type) {
            case 'empresaPersona':
                setFilterBusqueda({
                    ...filterBusqueda,
                    empresaPersonaId: null,
                })
                break;

            case 'empresaTransporte':
                setFilterBusqueda({
                    ...filterBusqueda,
                    empresaTransporteId: null
                })
                break;

            case 'gerencia':
                setFilterBusqueda({
                    ...filterBusqueda,
                    gerenciaId: null,
                })
                break;
            case 'lugMov':
                setFilterBusqueda({
                    ...filterBusqueda,
                    lugarMovilizacionId: null,
                })
                break;
            case 'centroCosto':
                setFilterBusqueda({
                    ...filterBusqueda,
                    centroCostoId: null,
                })
                break;
            case 'origen':
                setFilterBusqueda({
                    ...filterBusqueda,
                    origenId: null,
                })
                break;
            case 'destino':
                setFilterBusqueda({
                    ...filterBusqueda,
                    destinoId: null,
                })
                break;
            default:
                break;
        }
    }

    const _onKeyUpCombo = (e: React.FormEvent<IComboBox>) => {

        let { id } = e?.target as HTMLInputElement;
        let { value } = e?.target as HTMLInputElement;

        switch (id) {
            case "empresaPersona-input":
                _openOptionEmpresaPersona()

                setFilterEmpresaPersona({
                    ...filterEmpresaPersona,
                    search: value,
                })
                break;
            case "empresaTransporte-input":
                _openOptionEmpresaTransporte()

                setFilterEmpresaTransporte({
                    ...filterEmpresaTransporte,
                    search: value,
                })
                break;
            case "gerencia-input":
                _openOptionGerencia()

                setFilterGerencia({
                    ...filterGerencia,
                    search: value,
                })
                break;
            case "lugMov-input":
                _openOptionLugMov();

                setFilterLugMov({
                    ...filterLugMov,
                    search: value
                })
                break;
            case "centroCosto-input":
                _openOptionCentroCosto();

                setFilterCentroCosto({
                    ...filterCentroCosto,
                    search: value
                })
                break;
            case "origen-input":
                _openOptionOrigen();

                setFilterOrigen({
                    ...filterOrigen,
                    search: value
                })
                break;
            case "destino-input":
                _openOptionDestino();

                setFilterDestino({
                    ...filterDestino,
                    search: value
                })
                break;
            default:
                break;
        }
    }

    /* Render Footer */
    const onRenderFooter = React.useCallback(() => {
        return (
            <>
                <ButtonForm
                    back={onDismiss}
                    register={() => reloadInitial()}
                    textRegister='Buscar'
                ></ButtonForm>
            </>
        )
    }, [filterObject])

    const onDismissPanel = () => {

        /* setFilterBusqueda({
            ...filterBusqueda,
            empresaPersonaId:"",
            empresaTransporteId: "",
            gerenciaId: "",
            lugarMovilizacionId:"",
            centroCostoId: "",
            origenId:"",
            destinoId:""
        }) */

        onDismiss()
    }

    return (
        <>
            <PanelGenerico
                isOpen={isOpen}
                onDismiss={onDismissPanel}
                isOnRenderHeader
                disabledFooter
                iconHeaderName={IconHelper.ICON_FILTERS.filterSearch}
                title='Filtro Avanzado'
                subTitle={MessageHelper.MESSAGE_FILTRO}
                onRendeFooter={onRenderFooter}
            >
                <>
                    <div className='ms-Grid' dir='ltr' style={{ paddingTop: '10%' }}>
                        <Stack tokens={stackTokens}>
                            <div className='ms-Grid-row'>
                                <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12'>
                                    <ComboBox
                                        label='Empresa Persona:'
                                        id='empresaPersona'
                                        options={empresaPersona}
                                        componentRef={empresaPersonaRef}
                                        selectedKey={filterObject?.empresaPersonaId}
                                        calloutProps={{ directionalHintFixed: true }}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        onChange={(e, o) => onChangeCombo('empresaPersona', e, o)}
                                        onKeyUp={_onKeyUpCombo}
                                    />
                                </div>
                            </div>

                            <div className='ms-Grid-row'>
                                <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12'>
                                    <ComboBox
                                        label='Empresa Transporte:'
                                        id='empresaTransporte'
                                        options={empresaTransporte}
                                        componentRef={empresaTransporteRef}
                                        selectedKey={filterObject?.empresaTransporteId}
                                        calloutProps={{ directionalHintFixed: true }}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        onChange={(e, o) => onChangeCombo('empresaTransporte', e, o)}
                                        onKeyUp={_onKeyUpCombo}
                                    />
                                </div>
                            </div>

                            <div className='ms-Grid-row'>
                                <div className='ms-Grid-col ms-sm12 ms-md12 ms-lg12'>
                                    <ComboBox
                                        label='Gerencia:'
                                        id='gerencia'
                                        options={gerencia}
                                        componentRef={gerenciaRef}
                                        selectedKey={filterObject?.gerenciaId}
                                        calloutProps={{ directionalHintFixed: true }}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        onChange={(e, o) => onChangeCombo('gerencia', e, o)}
                                        onKeyUp={_onKeyUpCombo}
                                    />
                                </div>
                            </div>

                            <div className='ms-Grid-row'>
                                <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                    <ComboBox
                                        label="Lugar Movilización:"
                                        id="movilizacion"
                                        options={lugarMovilizacion}
                                        componentRef={lugMovRef}
                                        selectedKey={filterObject?.lugarMovilizacionId}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        autoComplete='off'
                                        calloutProps={{ directionalHintFixed: true }}
                                        onChange={(e, o) => onChangeCombo("lugMov", e, o)}
                                        onKeyUp={_onKeyUpCombo}
                                    />
                                </div>
                                <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                    <ComboBox
                                        label="Centro de Costo:"
                                        id="centroCosto"
                                        options={centroCosto}
                                        componentRef={centroCostoRef}
                                        selectedKey={filterObject?.centroCostoId}
                                        calloutProps={{ directionalHintFixed: true }}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        onChange={(e, o) => onChangeCombo("centroCosto", e, o)}
                                        onKeyUp={_onKeyUpCombo}
                                    />
                                </div>
                            </div>

                            <div className='ms-Grid-row'>
                                <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                    <ComboBox
                                        label='Origen (Embarque)'
                                        id='origen'
                                        componentRef={origenRef}
                                        allowFreeform
                                        autoComplete="off"
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={origen}
                                        selectedKey={filterObject?.origenId}
                                        onChange={(e, o) => onChangeCombo("origen", e, o)}
                                        onKeyUp={_onKeyUpCombo}
                                    />
                                </div>
                                <div className='ms-Grid-col ms-sm12 ms-md6 ms-lg6'>
                                    <ComboBox
                                        label='Destino (Desembarque)'
                                        id='destino'
                                        componentRef={destinoRef}
                                        allowFreeform
                                        autoComplete="off"
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={destino}
                                        selectedKey={filterObject?.destinoId}
                                        onChange={(e, o) => onChangeCombo("destino", e, o)}
                                        onKeyUp={_onKeyUpCombo}
                                    />
                                </div>
                            </div>
                        </Stack>
                    </div>
                </>
            </PanelGenerico>
        </>
    )
}