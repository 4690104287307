import { IActivityItemProps, ICommandBarItemProps, Icon, Link, PanelType, Persona, PersonaSize, TooltipHost, ComboBox, Stack, DialogType } from '@fluentui/react';
import { IColumn, Selection } from "@fluentui/react/lib/DetailsList";
import { useContext, useEffect, useImperativeHandle, useState } from "react";
import { TableBase } from "../../../../components/Table/TableBase"
import { IException, IReportTelerik } from "../../../../interfaces/base/IBase";
import { IDataCollection, IPagination } from "../../../../interfaces/base/ITable";
import { IEmailHeloValido, IFilterAsignacion, IFilterPersonaTransporte, IFilterProgramacion, IProgramacionPersona } from "../../../../interfaces/IProgramacion";
import ProgramacionBusService from "../../../../services/programacionBusService";
import { baseImageUrl, splitExceptionDetail, stackTokensHorizontal, _initialFilter, _initialPagination } from "../../../../utils/Helper/helper";
import { IconHelper } from "../../../../utils/Helper/iconHelper";
import { _initialDataCollection } from "../../../../utils/Helper/tableHelper";
import ThemeBase from "../../../../utils/Theme/themeBase"
import { ActualizarDatosVuelo } from "../panel/actualizarDatosVuelo";
import { useBoolean } from "@fluentui/react-hooks";
import { MasivoDatosVuelo } from "../panel/masivoDatosVuelo";
import { RetirarAccesoPersonaVueloAprobado } from "./retirarAccesoPersonaVueloAprobado";
import { PanelPlantillaFiltro } from "../../../../components/PanelUpload/PanelPlantillaFiltro";
import React from 'react';
import { ProgramacionProps } from '../../../../types/ITypes';
import { AuthContext } from '../../../../auth/context/auth-context';
import { isRoleAccessAll, roleAdmin } from '../../../../utils/Helper/roleHelper';
import { ActualizarAccesoPersona } from '../../actualizarAccesoPersona';
import { LogActividad } from '../../logActividad/logActividad';
import RepoteBase from '../../../../components/Generic/ReporteBase';
import { DialogBase } from '../../../../components/Dialog/DialogBase';
import RepoteBaseGenerico from '../../../../components/Generic/ReporteBaseGenerico';

const reportByRole = (id: string) => {
    let report: IReportTelerik = {
        reportName: 'MOVProgramadoPersonaHelos.trdx',
        param: {
            programacionId: id,
        }
    }

    return report;
}

export const AccesoPersonaVueloAprobado = React.forwardRef((props: ProgramacionProps, forwardedRef) => {
    const [hidden, setHidden] = useState(false);

    /* Access & Role */
    const { access } = useContext(AuthContext);
    let role = access?.role ?? [];
    let isAdmin = isRoleAccessAll(role, roleAdmin());

    /* Data & Colum */
    const [data, setData] = useState<IDataCollection<IProgramacionPersona>>(_initialDataCollection);
    const [column, setColumn] = useState<IColumn[]>([]);

    /* Filter */
    const [filter, setFilter] = useState<IFilterAsignacion>({
        ..._initialFilter(),
        programacionId: props.programacionId,
    });

    /* Actualizar individual */
    const [isOpenActualizar, { setTrue: openActualizar, setFalse: dismissActualizar }] = useBoolean(false);
    const [selId, setSelId] = useState('');

    /* Panel Actualizar Acceso Persona */
    const [isOpenActualizarAcceso, { setTrue: openActualizarAcceso, setFalse: dismissActualizarAcceso },] = useBoolean(false);

    /* Panel Log */
    const [isOpenLog, { setTrue: openLog, setFalse: dismissLog },] = useBoolean(false);

    /* Panel Telerik */
    const [isOpenTelerik, { setTrue: openTelerik, setFalse: dismissTelerik }] = useBoolean(false);
    let reportTelerik = reportByRole(props.programacionId as string);

    /* Actualizacion masiva */
    const [isOpenMasivo, { setTrue: openMasivo, setFalse: dismissMasivo }] = useBoolean(false);
    const [isOpenRetiroMasivo, { setTrue: openRetiroMasivo, setFalse: dismissRetiroMasivo },] = useBoolean(false);

    /* Pagination */
    const [currentPage, setCurrentPage] = useState(_initialPagination().currentPage);
    const [pageSize, setPageSize] = useState(_initialPagination().pageSize);
    const [total, setTotal] = useState(_initialPagination().total);

    const [pager, setPager] = useState<IPagination>({
        ..._initialPagination(),
        onPageChange: (page) => dataByPager(page),
        onSizeChange: (pageSize) => setPageSize(pageSize),
    });

    //filtro
    const [isOpenPanelFiltro, { setTrue: openPanelFiltro, setFalse: dismissPanelFiltro },] = useBoolean(false);

    /* Dialog */
    const [hideSms, { toggle: toggleSms }] = useBoolean(true);
    const [hideEmailCAE, { toggle: toggleEmailCAE }] = useBoolean(true);

    /* Loading */
    const [isLoadingSms, { setTrue: loadingSms, setFalse: dismissLoadingSms }] = useBoolean(false);
    const [isLoadingEmail, { setTrue: loadingEmail, setFalse: dismissLoadingEmail }] = useBoolean(false);

    /* Selection */
    const [sel, setSel] = useState<any[]>([]);

    const selection = new Selection({
        onSelectionChanged: () => {
            let d: any[] = selection.getSelection();

            let t: IActivityItemProps[] = d
                .filter(x => !x.isDisabled)
                .map((x) => {
                    return {
                        key: `id_${x.programacionPersonaId}`,
                        activityDescription: [
                            <Link key={x.programacionPersonaId} className={ThemeBase.activityClass.nameText}>
                                {x.nombres}
                            </Link>,
                            <span key={`2_${x.programacionPersonaId}`}> </span>,
                            <span
                                key={`3_${x.programacionPersonaId}`}
                                className={ThemeBase.activityClass.nameText}
                            >
                            </span>,
                        ],
                        activityIcon: <Icon iconName={'ReminderPerson'} />,
                        timeStamp: `DNI: ${x.nroDOI}`,
                        programacionPersonaId: `${x.programacionPersonaId}`,
                    };
                });

            setSel(t);
        },
        getKey: (item: any) => item.programacionPersonaId
    });

    /* Imperative Hook */
    useImperativeHandle(forwardedRef, () => {
        return {
            filterFromParent: (search = '') => {

                let p = _initialPagination().currentPage;

                let f: IFilterAsignacion = {
                    ..._initialFilter(),
                    programacionId: props.programacionId,
                    skip: (p - 1) * pageSize,
                    take: pageSize,
                    search: search.trim(),
                }

                setFilter(f);
                setCurrentPage(p);
                reloadInitial(f);
            }
        }
    });

    /* Hook */
    useEffect(() => {
        if (props.programacionId != null) {
            reloadInitial();
        }
    }, []);

    useEffect(() => {
        setSel([]);
    }, [data])

    /* Hook Pagination */
    useEffect(() => {
        setPager({
            ...pager,
            currentPage: currentPage,
        });

        if (filter) {
            setFilter({
                ...filter,
                skip: (currentPage - 1) * pageSize,
                take: pageSize,
            });
        }
    }, [currentPage]);

    useEffect(() => {
        setPager({
            ...pager,
            onPageChange: (page) => dataByPager(page),
            onSizeChange: (pageSize) => setPageSize(pageSize),
            total: total,
        });
    }, [total]);

    /* Api */
    const dataPendiente = (param = filter) => {
        if (props.programacionId != '') {
            setData(_initialDataCollection);
            ProgramacionBusService.allPendienteVuelo(param)
                .then((res) => {
                    if (res.status == 200) {
                        setData(res.data);
                        setHidden(true);
                        setTotal(res.data.total);
                    }
                })
                .catch((e: IException) => {
                    alert(splitExceptionDetail(e));
                    setHidden(true);
                });
        }
    }

    /* Function */
    const reloadInitial = (param = filter) => {
        setHidden(false);
        setTitleColumn();
        dataPendiente(param);
    }

    const _leftBar = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_BACK,
                onClick: () => { alert('back') },
            },
            {
                ...IconHelper.ICON_RELOAD,
                onClick: () => reloadInitial(),
            },
            // {
            //     ...IconHelper.ICON_CANCEL,
            //     text: "Retirar",
            //     onClick: () => {
            //         if (sel.length == 0) {
            //             return alert("Debe seleccionar al menos un item");
            //         }

            //         openRetiroMasivo();
            //     },
            // },
        ];

        if (isAdmin) {
            _items.push(
                {
                    ...IconHelper.ICON_REPLY_MIRRORED,
                    text: 'Notificar CAE',
                    subMenuProps: {
                        items: [
                            {
                                ...IconHelper.ICON_MAIL_CHECK,
                                key: '1',
                                onClick: toggleEmailCAE
                            },
                            {
                                ...IconHelper.ICON_SMS,
                                key: '2',
                                onClick: toggleSms
                            },
                        ],
                    },
                },
            )
        }

        return _items;
    };

    const _rightNormal = () => {
        const _items: ICommandBarItemProps[] = [
            {
                ...IconHelper.ICON_EXCEL,
                onClick: openTelerik,
            },
        ];

        return _items;
    };

    const setTitleColumn = () => {
        let col: IColumn[] = [
            {
                key: "column1",
                name: "Foto",
                ariaLabel:
                    "Column operations for File type, Press to sort on File type",
                iconName: "-",
                isIconOnly: true,
                fieldName: "foto",
                minWidth: 30,
                maxWidth: 30,
                onRender: (item: IProgramacionPersona) => (
                    <TooltipHost content={`Foto`}>
                        <Persona
                            text=""
                            size={PersonaSize.size24}
                            imageUrl={baseImageUrl}
                        />
                    </TooltipHost>
                ),
            },
            {
                key: "column2",
                name: "Nombre",
                fieldName: "nombres",
                minWidth: 170,
                maxWidth: 170,
                isRowHeader: true,
                isResizable: true,
                data: "string",
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return (
                        <span style={{ fontSize: '90%' }}>{item.nombres}</span>
                    );
                },
            },
            {
                key: "column3",
                name: "Identificación",
                fieldName: "nroDOI",
                minWidth: 80,
                maxWidth: 90,
                isResizable: true,
                data: "number",
                isPadded: true,
                onRender: (item: IProgramacionPersona) => {
                    return (
                        <span
                            style={{ fontSize: "95%" }}>{`${item.tipoDocumento} ${item.nroDOI}`}</span>
                    );
                },
            },
            {
                key: 'column3.1',
                name: 'Nivel',
                fieldName: 'nivel',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column3.2',
                name: 'Level',
                fieldName: 'level',
                minWidth: 70,
                maxWidth: 70,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            // {
            //     key: "column16",
            //     name: "Actualizar Helo",
            //     fieldName: "opciones",
            //     minWidth: 90,
            //     maxWidth: 100,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: "number",
            //     onRender: (item: IProgramacionPersona) => (
            //         <>
            //             <Stack horizontal tokens={stackTokensHorizontal} style={ThemeBase.justifiedStyle}>
            //                 {!item.isDisabled && (
            //                     <>
            //                         <TooltipHost content={`Actualizar`}>
            //                             <Icon
            //                                 iconName='PageEdit'
            //                                 style={{ cursor: "pointer" }}
            //                                 className={ThemeBase.classes.iconFont}
            //                                 onClick={() => {
            //                                     setSelId(item.programacionPersonaId);
            //                                     openActualizar();
            //                                 }}
            //                             />
            //                         </TooltipHost>
            //                     </>
            //                 )}
            //             </Stack>
            //         </>
            //     ),
            // },
            {
                key: 'column4',
                name: 'Fecha Vuelo',
                fieldName: 'fechaVuelo',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column5',
                name: 'Aerolinea',
                fieldName: 'aerolinea',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column6',
                name: 'Vuelo',
                fieldName: 'vuelo',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column7',
                name: 'Ruta',
                fieldName: 'ruta',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column8',
                name: 'Hora Sale',
                fieldName: 'horaSale',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column9',
                name: 'Hora Llega',
                fieldName: 'horaLlega',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column10',
                name: 'Código Aerolinea',
                fieldName: 'codAerolinea',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column11',
                name: 'Código Gds',
                fieldName: 'codGds',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column12',
                name: 'Tarifa',
                fieldName: 'tarifa',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'column13',
                name: 'Status',
                fieldName: 'status',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: 'string',
                isPadded: true,
            },
            // {
            //     key: 'column4',
            //     name: 'Email',
            //     fieldName: 'email',
            //     minWidth: 180,
            //     maxWidth: 180,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: 'string',
            //     isPadded: true,
            // },
            // {
            //     key: 'column5',
            //     name: 'Celular',
            //     fieldName: 'celular',
            //     minWidth: 70,
            //     maxWidth: 90,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: 'string',
            //     isPadded: true,
            // },
            // {
            //     key: 'column6',
            //     name: 'Gerencia',
            //     fieldName: 'gerencia',
            //     minWidth: 150,
            //     maxWidth: 150,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: 'string',
            // },
            // {
            //     key: 'column6.1',
            //     name: 'Embarque',
            //     fieldName: 'embarque',
            //     minWidth: 100,
            //     maxWidth: 100,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: 'string',
            // },
            // {
            //     key: 'column6.2',
            //     name: 'Destino',
            //     fieldName: 'destino',
            //     minWidth: 100,
            //     maxWidth: 100,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: 'string',
            // },
            // {
            //     key: 'column7',
            //     name: 'Empresa Transportista',
            //     fieldName: 'empresaTransporte',
            //     minWidth: 450,
            //     maxWidth: 450,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: 'string',
            // },
            // {
            //     key: "column9",
            //     name: "Empresa Persona",
            //     fieldName: "empresaPersona",
            //     minWidth: 450,
            //     maxWidth: 450,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: "number",
            // },
            {
                key: "column14",
                name: "Proceso - Estado",
                fieldName: "nivelActual",
                minWidth: 250,
                maxWidth: 250,
                isResizable: true,
                isCollapsible: true,
                data: "number",
            },
            // {
            //     key: "column15",
            //     name: "Estado",
            //     fieldName: "estadoActual",
            //     minWidth: 200,
            //     maxWidth: 200,
            //     isResizable: true,
            //     isCollapsible: true,
            //     data: "number",
            // },
            {
                key: "column20",
                name: "Opciones",
                fieldName: "opciones",
                minWidth: 90,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                data: "number",
                onRender: (item: IProgramacionPersona) => (
                    <>
                        <Stack horizontal tokens={stackTokensHorizontal}>
                            {isAdmin &&
                                <TooltipHost content={`Editar`}>
                                    <Icon
                                        iconName={IconHelper.ICON_EDIT.iconProps?.iconName}
                                        style={{ cursor: "pointer" }}
                                        className={ThemeBase.classes.iconFont}
                                        onClick={() => {
                                            setSelId(item.programacionPersonaId);
                                            openActualizarAcceso();
                                        }}
                                    />
                                </TooltipHost>
                            }
                            {/* Actividad Log */}
                            <TooltipHost content={`Actividades`}>
                                <Icon
                                    iconName={IconHelper.ICON_INFO.iconProps?.iconName}
                                    style={{ cursor: "pointer" }}
                                    className={`${ThemeBase.classes.iconFont}`}
                                    onClick={() => {
                                        setSelId(item.programacionPersonaId);
                                        openLog();
                                    }}
                                />
                            </TooltipHost>
                        </Stack>
                    </>
                ),
            },
        ];

        setColumn(col);
    };

    /* Event Tab */
    const dataByPager = (page) => {
        if (true) {
            setTotal(0);

            setPager({
                ...pager,
                currentPage: page,
            });

            let f: IFilterPersonaTransporte = {
                ...filter,
                skip: (page - 1) * pageSize,
                take: pageSize,
            }

            setFilter(f);
            setCurrentPage(page);

            reloadInitial(f);
        }
    }

    //filtro
    const _buscarFiltro = () => {

        // setFilter(filter);
        // setup(filter);

        //dismissPanelFiltro();
    }

    const _limpiarFiltro = () => {

        let modelClear: IFilterProgramacion = {
            ...filter,
            search: "",
            empresaPersonaId: "",
            empresaTransporteId: "",
            gerenciaId: "",
            lugarMovilizacionId: "",
            centroCostoId: "",
            origenId: "",
            destinoId: "",

        }
        // setFilter(modelClear);
        // setup(modelClear);
    }

    /* Event */
    const smsCAE = () => {
        loadingSms();

        ProgramacionBusService.smsCAE(props.programacionId)
            .then((res) => {
                if (res.status == 200) {
                    toggleSms();
                    dismissLoadingSms();
                }
            })
            .catch((e) => {
                alert(splitExceptionDetail(e));
                dismissLoadingSms();
            });
    }

    const emailCAE = () => {
        loadingEmail();

        ProgramacionBusService.emailCAE(props.programacionId)
            .then((res) => {
                if (res.status == 200) {
                    toggleEmailCAE();
                    dismissLoadingEmail();
                }
            })
            .catch((e) => {
                alert(splitExceptionDetail(e));
                dismissLoadingEmail();
            });
    }

    return (
        <>
            <TableBase leftBar={_leftBar()} rigthBar={_rightNormal()} column={column} data={data.items} style={ThemeBase.notPaddingTableBase} selection={selection} pagination={pager} isLoading={!hidden}></TableBase>
            <ActualizarDatosVuelo programacionPersonaId={selId} isOpen={isOpenActualizar} dismiss={dismissActualizar} reloadParent={reloadInitial}></ActualizarDatosVuelo>
            <MasivoDatosVuelo programacionId={props.programacionId} isOpen={isOpenMasivo} dismiss={dismissMasivo} reloadParent={reloadInitial}></MasivoDatosVuelo>
            <RetirarAccesoPersonaVueloAprobado programacionId={props.programacionId} isOpen={isOpenRetiroMasivo} dismiss={dismissRetiroMasivo} sel={sel} reloadParent={reloadInitial}></RetirarAccesoPersonaVueloAprobado>

            <div id='panelFiltro'>
                <>
                    <PanelPlantillaFiltro
                        textHeader='Filtro'
                        subTextHeader='Elija las condiciones para el filtro personalizado'
                        textBoton='Buscar'
                        textBotonDefault='Limpiar'
                        onclickBoton={_buscarFiltro}
                        onclickBotonDefault={_limpiarFiltro}
                        openPanel={isOpenPanelFiltro}
                        closePanel={dismissPanelFiltro}
                        // onRenderHeader={onRenderHeaderFilter}
                        size={PanelType.medium}
                        disabledBoton={false}
                        estadoBarraEnvio={false}
                        nameIconHeader={"Filter"}
                        nameIconButton={"Search"}
                    // dep={[filter, keysNivel]}
                    >
                        <>

                            <div className="ms-Grid" dir="ltr">
                                {/* <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg612" >
                    <TextField
                      // label='Nro Documento Identidad'
                      placeholder='Busque por nombres o DOI'
                      name='doi'
                      value={filter?.search != undefined ? filter?.search : ""}
                      onChange={(e, o) => _onChangeTextFiltro(e, o)}
                    />
                  </div>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                    <TextField
                      label='Apellidos y Nombres'
                      name='nombres'
                      value={filter?.search != undefined ? filter?.search : ""}
                      onChange={(e, o) => _onChangeTextFiltro(e, o)}
                    />
                  </div>
                </div>
                <br /> */}
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <ComboBox
                                            label="Empresa Persona:"
                                            id="empresaPersona"
                                            options={[
                                                { key: "1", text: "opcion 1" }
                                            ]}
                                        /* options={empresaPersonaCombo}
                                        componentRef={empresaPersonaRef}
                                        selectedKey={filter?.empresaPersonaId}
                                        calloutProps={{ directionalHintFixed: true }}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        onChange={(e, o) => _onChangeComboFiltro("empresaPersona", e, o)}
                                        onKeyUp={_onKeyUpCombo} */
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <ComboBox
                                            label="Empresa Transporte:"
                                            id="empresaTransporte"
                                            options={[
                                                { key: "1", text: "opcion 1" }
                                            ]}
                                        /* options={empresaCombo}
                                        componentRef={empresaRef}
                                        selectedKey={filter?.empresaTransporteId}
                                        calloutProps={{ directionalHintFixed: true }}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        onChange={(e, o) => _onChangeComboFiltro("empresaTransporte", e, o)}
                                        onKeyUp={_onKeyUpCombo} */
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                        <ComboBox
                                            label="Gerencia:"
                                            options={[
                                                { key: "1", text: "opcion 1" }
                                            ]}
                                        /* options={gerenciaCombo}
                                        // componentRef={comboBoxRef}
                                        selectedKey={filter?.gerenciaId}
                                        calloutProps={{ directionalHintFixed: true }}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        // multiSelect
                                        onChange={(e, o) => _onChangeComboFiltro("gerencia", e, o)} */
                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                                        <ComboBox
                                            label="Lugar Movilización:"
                                            id="movilizacion"
                                            options={[
                                                { key: "1", text: "opcion 1" }
                                            ]}
                                        /* options={lugarMovilizacionCombo}
                                        componentRef={LugarMovilizacionRef}
                                        selectedKey={filter?.lugarMovilizacionId}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        // placeholder='Gerencia'
                                        autoComplete='off'
                                        calloutProps={{ directionalHintFixed: true }}
                                        onChange={(e, o) => _onChangeComboFiltro("movilizacion", e, o)}
                                        onKeyUp={_onKeyUpCombo} */
                                        // onBlur={() => _onBlurCombo('gerencia')}
                                        />

                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6" >
                                        <ComboBox
                                            label="Centro de Costo:"
                                            id="centroCosto"
                                            options={[
                                                { key: "1", text: "opcion 1" }
                                            ]}
                                        /* options={centroCostoCombo}
                                        // componentRef={comboBoxRef}
                                        selectedKey={filter?.centroCostoId}
                                        calloutProps={{ directionalHintFixed: true }}
                                        useComboBoxAsMenuWidth
                                        allowFreeform
                                        onChange={(e, o) => _onChangeComboFiltro("centroCosto", e, o)} */

                                        />
                                    </div>
                                </div>
                                <br />
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                        <ComboBox
                                            label='Origen (Embarque)'
                                            options={[
                                                { key: "1", text: "opcion 1" }
                                            ]}
                                        /* 
                                        componentRef={embarqueRef}
                                        allowFreeform
                                        autoComplete="off"
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        options={embarqueCombo}
                                        // onKeyUp={_onUpEmbarque}
                                        selectedKey={filter?.origenId}
                                        onChange={(e, o) => _onChangeComboFiltro("origen", e, o)} */
                                        // onBlur={_onBlurEmbarque}

                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                        <ComboBox
                                            label='Destino (Desembarque)'
                                            options={[
                                                { key: "1", text: "opcion 1" }
                                            ]}
                                        /* componentRef={campamentoRef}
                                        allowFreeform
                                        autoComplete="off"
                                        useComboBoxAsMenuWidth
                                        calloutProps={{ directionalHintFixed: true }}
                                        // onKeyUp={_onUpCampamento}
                                        options={destinoCombo}
                                        selectedKey={filter?.destinoId}
                                        onChange={(e, o) => _onChangeComboFiltro("destino", e, o)}
                                    // onBlur={_onBlurCampamento} */
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    </PanelPlantillaFiltro>

                </>

            </div>

            {/* Panel Actualizar */}
            <ActualizarAccesoPersona programacionPersonaId={selId} isOpen={isOpenActualizarAcceso} dismiss={dismissActualizarAcceso} reloadParent={reloadInitial}></ActualizarAccesoPersona>

            {/* Panel Log */}
            <LogActividad programacionPersonaId={selId} isOpen={isOpenLog} dismiss={dismissLog}></LogActividad>

            <DialogBase
                isHidden={hideSms}
                subTitle={'Al confirmar se hará el envío de los SMS correspondientes según el lugar de movilización'}
                tipo={DialogType.normal}
                toggleHidden={() => {
                    dismissLoadingSms();
                    toggleSms();
                }}
                dependency={[]}
                confirm={smsCAE}
                isDisableConfirm={isLoadingSms}
            ></DialogBase>

            {/* Dialog email CAE */}
            <DialogBase
                isHidden={hideEmailCAE}
                subTitle={'Al confirmar se desplegarán las notificaciones a los colaboradores con la información registrada'}
                tipo={DialogType.normal}
                toggleHidden={() => {
                    dismissLoadingEmail();
                    toggleEmailCAE();
                }}
                dependency={[]}
                confirm={emailCAE}
                isDisableConfirm={isLoadingEmail}
            ></DialogBase>

            {/* Panel - Reporte - Telerik */}
            <RepoteBaseGenerico
                NameReport={reportTelerik.reportName}
                Parameters={reportTelerik.param}
                openTelerik={isOpenTelerik}
                dismissTelerik={dismissTelerik}
            ></RepoteBaseGenerico>
        </>
    )
})