import React from "react";
import { Panel, PanelType, ActivityItem, FontIcon, FontSizes, IStackStyles, Stack, IComboBox, IComboBoxOption, MessageBar, MessageBarType, MessageBarButton, TextField, ComboBox, ProgressIndicator } from '@fluentui/react';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks';
import { NeutralColors } from '@fluentui/theme';
import ThemeBase from "../../utils/Theme/themeBase";
import { useState } from 'react';
import { IListarPersonaById, IPersona, IUpdatePersona } from "../../interfaces/IPersona";
import { IBase, IException, IItemComboBaseDTO, ISearch } from "../../interfaces/base/IBase";
import { Ifilter } from "../../interfaces/IFilter";
import { ItemNoneSelect, splitExceptionDetail, _initialFilter } from "../../utils/Helper/helper";
import { useEffect } from 'react';
import EmpresaService from '../../services/empresaService';
import { IEmpresa } from "../../interfaces/IEmpresa";
import OrganigramaService from '../../services/organigramaService';
import RosterService from '../../services/rosterService';
import { IRoster } from "../../interfaces/IRoster";
import ProgramacionService from '../../services/ProgramacionService';
import ConfiguracionService from '../../services/configuracionService';
import PersonaService from '../../services/PersonaService';

const stackStyles: IStackStyles = {
    root: {
        // background: '#ffff',
    },
};

function PanelActualizarPersona({
    personaId = "",
    personaTipo = "",
    isOpenPanel = false,
    dismissPanel = () => { },
    reloadParent = () => { }

}) {
    const [modelItem, setModelItem] = useState<IUpdatePersona>(null!)
    const [info, setInfo] = useState<IPersona>(null!);
    const [tipoPersona, setTipoPersona] = useState("");

    const [empresaPersonaCombo, setEmpresaPersonaCombo] = useState<IItemComboBaseDTO[]>([]);
    const empresaPersonaRef = React.useRef<IComboBox>(null);
    const _openOptionPersona = React.useCallback(() => empresaPersonaRef.current?.focus(true), []);
    const [filterEmpresaPersona, setFilterEmpresaPersona] = useState<Ifilter>({
        ..._initialFilter()
    });

    const [gerenciaCombo, setGerenciaCombo] = useState<IItemComboBaseDTO[]>([]);
    const gerenciaRef = React.useRef<IComboBox>(null);
    const _openOptionGerencia = React.useCallback(() => gerenciaRef.current?.focus(true), []);
    const [filteGerencia, setFilteGerencia] = useState<ISearch>({
        search: ""
    });

    const [superintendenciaCombo, setSuperintendenciaCombo] = useState<IItemComboBaseDTO[]>([]);
    const superintendenciaRef = React.useRef<IComboBox>(null);
    const _openOptionSuperintendencia = React.useCallback(() => superintendenciaRef.current?.focus(true), []);
    const [filterSuperintendencia, setFilterSuperintendencia] = useState<ISearch>(_initialFilter);

    const [rosterCombo, setRosterCombo] = useState<IItemComboBaseDTO[]>([]);
    const rosterRef = React.useRef<IComboBox>(null);
    const _openOptionRoster = React.useCallback(() => rosterRef.current?.focus(true), []);
    const [filterRoster, setFilterRoster] = useState<Ifilter>(_initialFilter);

    const [lugarMovilizacionCombo, setLugarMovilizacionCombo] = useState<IItemComboBaseDTO[]>([]);
    const lugarMovilizacionRef = React.useRef<IComboBox>(null);
    const _openOptionMovilizacion = React.useCallback(() => lugarMovilizacionRef.current?.focus(true), []);
    const [filterMovilizacion, setfilterMovilizacion] = useState<ISearch>(_initialFilter);

    const [puestoCombo, setPuestoCombo] = useState<IItemComboBaseDTO[]>([]);

    const [searchPuesto, setSearchPuesto] = useState("");
    const puestoRef = React.useRef<IComboBox>(null);
    const _openOptionPuesto = React.useCallback(() => puestoRef.current?.focus(true), []);

    const [nombrePuesto, setNombrePuesto] = useState("");
    const [nombreEmpresa, setNombreEmpresa] = useState("");

    const [nivelCombo, setNivelCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterNivel, setFilterNivel] = useState<ISearch>({
        search: ""
    });

    const [levelCombo, setLevelCombo] = useState<IItemComboBaseDTO[]>([]);
    const [filterLevel, setFilterLevel] = useState<ISearch>({
        search: ""
    });

    const [personaTipoCombo, setPersonaTipoCombo] = useState<IItemComboBaseDTO[]>([]);
    const [searchTipo, setSearchTipo] = useState("");

    const [personaEstadoCombo, setPersonaEstadoCombo] = useState<IItemComboBaseDTO[]>([]);
    const [searchEstado, setSearchEstado] = useState("");

    //panel
    const [isOpenEditar, { setTrue: openEditar, setFalse: dismissEditar },] = useBoolean(false);

    //mensajes de estado
    const [verLoading, setVerLoading] = useState(false);
    const [mensajeTransaccion, setMensajeTransaccion] = useState("");
    const [tipoMensaje, setTipoMensaje] = useState(0);
    const [verLoadingBoton, setVerLoadingBoton] = useState(false);

    /* useEffect(() => {
        dataEmpresaPersona();

    }, [filterEmpresaPersona]); */

    useEffect(() => {
    if(isOpenPanel === true){
        dataGerencia();
        
    }

    }, [filteGerencia, isOpenPanel]);

    useEffect(() => {
    if(isOpenPanel === true){

        dataSuperintendencia();
    }

    }, [filterSuperintendencia,isOpenPanel]);

    useEffect(() => {
    if(isOpenPanel === true){

        dataRoster();
    }

    }, [filterRoster,isOpenPanel]);

    useEffect(() => {
        /* if (filterMovilizacion.search != null) {
            
        } */
            if(isOpenPanel){
                dataLugarMovilizacion();
            }
    }, [filterMovilizacion, isOpenPanel]);

    useEffect(() => {
    if(isOpenPanel === true){

        dataNivel();
    }

    }, [filterNivel,isOpenPanel]);

    useEffect(() => {
    if(isOpenPanel === true){

        dataLevel();

    }
    }, [filterLevel,isOpenPanel]);

    useEffect(() => {
    if(isOpenPanel === true){

        dataPersonaEstado();
    }

    }, [searchEstado,isOpenPanel]);

    useEffect(() => {
    if(isOpenPanel === true){
        
        dataPersonaTipo();
    }

    }, [searchTipo,isOpenPanel]);

    useEffect(() => {
        if (isOpenPanel && personaId != '') {
            infoPersona();
        }
        // loadData();
    }, [isOpenPanel]);

    useEffect(() => {

        if (personaTipo == "SSEE") {
            // console.log("----------------- ssee ---------------")
            rosterCombo.unshift(ItemNoneSelect());
            lugarMovilizacionCombo.unshift(ItemNoneSelect());
            nivelCombo.unshift(ItemNoneSelect());
            levelCombo.unshift(ItemNoneSelect());
            setRosterCombo(rosterCombo);
            setLugarMovilizacionCombo(lugarMovilizacionCombo);
            setNivelCombo(nivelCombo);
            setLevelCombo(levelCombo);

        } else {
            // console.log("----------------- mmgg ---------------")
            rosterCombo.splice(0, 1);
            lugarMovilizacionCombo.splice(0, 1);
            nivelCombo.splice(0, 1);
            levelCombo.splice(0, 1);
            setRosterCombo(rosterCombo);
            setLugarMovilizacionCombo(lugarMovilizacionCombo);
            setNivelCombo(nivelCombo);
            setLevelCombo(levelCombo);
        }

    }, [personaTipo]);

    useEffect(() => {
        // dataPuesto();

        if (isOpenPanel === true) {

            /* console.log("----------------------------------");
            console.log("panel Abierto Puesto?: " + isOpenPanel); */

            PersonaService.getPuestoCombo(searchPuesto)
                .then((res) => {
                    if (res.status == 200) {

                        let d: IBase[] = res.data.data;

                        let t: IItemComboBaseDTO[] = d.map(x => {
                            return {
                                key: x.id,
                                text: x.nombre
                            }
                        });


                        if (modelItem?.puestoId != undefined && nombrePuesto != "") {

                            let result = t.some(x => x.key === modelItem?.puestoId)
                            // let result = t.some(x => x.key === '9af2ee2c-c3bb-4fd2-b14e-435a8e88d285');
                            // console.log("nombre puesto4: " + nombrePuesto);

                            if (result == false) {

                                let addPuesto: IItemComboBaseDTO = {
                                    // key: "9af2ee2c-c3bb-4fd2-b14e-435a8e88d285",
                                    // text: "prueba",
                                    key: modelItem?.puestoId,
                                    text: nombrePuesto,
                                }

                                t.unshift(addPuesto)
                                setPuestoCombo(t)

                                // console.log("----------------------------------");
                                // console.log("puestoId4: " + modelItem?.puestoId);
                                // console.log("nombre puesto4: " + nombrePuesto);
                                // console.log("existe puesto combo4?: " + result);

                                // console.log(puestoCombo);
                            } else {

                                setPuestoCombo(t);
                            }

                        }

                        // setPuestoCombo(t);
                    }
                })
                .catch((e: IException) => {
                    alert(splitExceptionDetail(e));

                });

        } else {

            // console.log("----------------------------------");
            // console.log("panel Abierto?: " + isOpenPanel);
            puestoCombo.splice(0, 1);
            setPuestoCombo(puestoCombo);

        }

    }, [searchPuesto, isOpenPanel, modelItem?.puestoId, nombrePuesto]);


    useEffect(() => {
        // dataPuesto();

        if (isOpenPanel === true) {

            // console.log("----------------------------------");
            // console.log("panel Abierto?: " + isOpenPanel);

            EmpresaService.all(filterEmpresaPersona)
                .then((res) => {
                    if (res.status == 200) {

                        let d: IEmpresa[] = res.data.items;
                        let t: IItemComboBaseDTO[] = d.map((x) => {
                            return {
                                key: x.empresaId,
                                text: x.nombre as string,
                            };
                        });


                        if (modelItem?.empresaId != undefined && filterEmpresaPersona?.search != "") {

                            let resul = t.some(x => x.key === modelItem?.empresaId)
                            // let result = t.some(x => x.key === '9af2ee2c-c3bb-4fd2-b14e-435a8e88d285');
                            // console.log("nombre empresa4: " + nombreEmpresa);

                            if (resul == false) {

                                let addEmpresa: IItemComboBaseDTO = {
                                    // key: "9af2ee2c-c3bb-4fd2-b14e-435a8e88d285",
                                    // text: "prueba",
                                    key: modelItem?.empresaId,
                                    text: nombreEmpresa,
                                }

                                t.unshift(addEmpresa)
                                setEmpresaPersonaCombo(t);

                                // console.log("----------------------------------");
                                // console.log("empresaId4: " + modelItem?.empresaId);
                                // console.log("nombre empresa4: " + nombreEmpresa);
                                // console.log("existe empresa combo4?: " + resul);

                                // console.log(puestoCombo);
                            } else {

                                setEmpresaPersonaCombo(t);
                            }

                        }

                        // setPuestoCombo(t);
                    }
                })
                .catch((e: IException) => {
                    alert(splitExceptionDetail(e));

                });

        } else {

            // console.log("----------------------------------");
            // console.log("panel Abierto?: " + isOpenPanel);
            

            empresaPersonaCombo.splice(0, 1);
            setEmpresaPersonaCombo(empresaPersonaCombo);

        }

    }, [filterEmpresaPersona?.search, isOpenPanel, modelItem?.empresaId, nombreEmpresa]);


    /* Api */
    const infoPersona = () => {
        if (personaId != undefined) {
            let d: IListarPersonaById = {
                listaPersonas: [personaId],
            };

            PersonaService.getInfoPersonaById(d)
                .then((res) => {
                    if (res.status == 200) {

                        /* console.log("---------- persona ----------");
                        console.log(res.data[0].tipoPersona) */

                        setTipoPersona(res.data[0].tipoPersona);
                        let d: IPersona = res.data[0];

                        setModelItem({
                            personaId: d.personaId,
                            persona: d.persona,
                            primerNombre: d.primerNombre,
                            segundoNombre: d.segundoNombre,
                            primerApellido: d.apellidoPaterno,
                            segundoApellido: d.apellidoMaterno,
                            empresaId: d.empresaId,
                            gerenciaId: d.gerenciaId,
                            superIntendenciaId: d.areaReporteNivelDosId,
                            catalogoRosterId: d.catalogoRosterId == null ? "-1" : d.catalogoRosterId,
                            codigoEnrolar: d.tarjeta, //tarjeta
                            LugarMovilizacionId: d.lugarMovilizacionId == null ? "-1" : d.lugarMovilizacionId,
                            nivelId: d.nivelId,
                            levelId: d.levelId,
                            estadoId: d.estadoId,
                            email: d.email,
                            celular: d.celular,
                            tipoId: d.tipoPersonaId,
                            puestoId: d.puestoId
                        });

                        setNombrePuesto(d.puesto);
                        setNombreEmpresa(d.empresa);

                    }
                })
                .catch((e: IException) => {
                    alert(splitExceptionDetail(e));
                });
        }
    };

    /* const dataEmpresaPersona = () => {

        EmpresaService.all(filterEmpresaPersona)
            .then((res) => {
                if (res.status == 200) {
                    // console.log("***** empresa *****");
                    // console.table(res.data.items);
                    let d: IEmpresa[] = res.data.items;
                    let t: IItemComboBaseDTO[] = d.map((x) => {
                        return {
                            key: x.empresaId,
                            text: x.nombre as string,
                        };
                    });

                    setEmpresaPersonaCombo(t);

                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    }; */

    const dataGerencia = () => {
        // setGerenciaCombo([]);
        OrganigramaService.organigramaByTipo("01", filteGerencia)
            .then((res) => {
                if (res.status == 200) {
                    let t: IItemComboBaseDTO[] = res.data;
                    // t.unshift(ItemNoneSelect());
                    setGerenciaCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataSuperintendencia = () => {
        // setArea([]);
        OrganigramaService.organigramaByTipo("06", filterSuperintendencia)
            .then((res) => {
                if (res.status == 200) {

                    let t: IItemComboBaseDTO[] = res.data;
                    // t.unshift(ItemNoneSelect());

                    setSuperintendenciaCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataRoster = () => {
        // setArea([]);
        RosterService.all(filterRoster)
            .then((res) => {
                if (res.status == 200) {


                    let d: IRoster[] = res.data;

                    let t: IItemComboBaseDTO[] = d.map(x => {
                        return {
                            key: x.id,
                            text: x.codigo
                        }
                    });

                    if (personaTipo == "SSEE") {
                        // console.log("----------------- persona tipo ---------------");
                        // console.log(personaTipo);

                        t.unshift(ItemNoneSelect());
                        setRosterCombo(t);
                    } else {
                        setRosterCombo(t);
                    }

                    // setRosterCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataLugarMovilizacion = () => {
        ProgramacionService.allLugarMovilizacion()
            .then((res) => {
                // console.log("************** lugar **********");
                // console.log(res.data);
                let t: IItemComboBaseDTO[] = res.data

                if (personaTipo == "SSEE") {

                    t.unshift(ItemNoneSelect());
                    setLugarMovilizacionCombo(t);
                } else {

                    setLugarMovilizacionCombo(t);
                }
                // setLugarMovilizacionCombo(t);

            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));
            });
    };

    const dataNivel = () => {

        ConfiguracionService.allNivel(filterNivel)
            .then((res) => {
                if (res.status == 200) {

                    let t: IItemComboBaseDTO[] = res.data;

                    if (personaTipo == "SSEE") {

                        t.unshift(ItemNoneSelect());
                        setNivelCombo(t);
                    } else {

                        setNivelCombo(t);
                    }
                    // setNivelCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    };

    const dataLevel = () => {

        ConfiguracionService.allLevel(filterLevel)
            .then((res) => {
                if (res.status == 200) {

                    let t: IItemComboBaseDTO[] = res.data;

                    if (personaTipo == "SSEE") {

                        t.unshift(ItemNoneSelect());
                        setLevelCombo(t);
                    } else {

                        setLevelCombo(t);
                    }

                    // setLevelCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    };

    const dataPersonaTipo = () => {

        PersonaService.getPersonaTipo(searchTipo)
            .then((res) => {
                if (res.status == 200) {

                    let d: IBase[] = res.data.data;

                    let t: IItemComboBaseDTO[] = d.map(x => {
                        return {
                            key: x.id,
                            text: x.nombre
                        }
                    });
                    // t.unshift(ItemSelectAll());
                    setPersonaTipoCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    };

    const dataPersonaEstado = () => {

        PersonaService.getPersonaEstado(searchEstado)
            .then((res) => {
                if (res.status == 200) {

                    let d: IBase[] = res.data.data;

                    let t: IItemComboBaseDTO[] = d.map(x => {
                        return {
                            key: x.id,
                            text: x.nombre
                        }
                    });

                    // t.unshift(ItemSelectAll());
                    setPersonaEstadoCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    };

    /* const dataPuesto = () => {

        PersonaService.getPuestoCombo(searchPuesto)
            .then((res) => {
                if (res.status == 200) {

                    let d: IBase[] = res.data.data;

                    let t: IItemComboBaseDTO[] = d.map(x => {
                        return {
                            key: x.id,
                            text: x.nombre
                        }
                    });

                    setPuestoCombo(t);
                }
            })
            .catch((e: IException) => {
                alert(splitExceptionDetail(e));

            });
    }; */

    const ActualizarDatoPersona = () => {

        console.log("************ persona ******************")
        // console.log(modelItem);

        let modelUpdatePersona: IUpdatePersona = {
            personaId: modelItem.personaId,
            primerNombre: modelItem.primerNombre,
            segundoNombre: modelItem.segundoNombre,
            primerApellido: modelItem.primerApellido,
            segundoApellido: modelItem.segundoApellido,
            empresaId: modelItem.empresaId,
            gerenciaId: modelItem.gerenciaId,
            superIntendenciaId: modelItem.superIntendenciaId,
            catalogoRosterId: modelItem.catalogoRosterId != "-1" ? modelItem.catalogoRosterId : null,
            codigoEnrolar: modelItem.codigoEnrolar, //tarjeta
            LugarMovilizacionId: modelItem.LugarMovilizacionId != "-1" ? modelItem.LugarMovilizacionId : null,
            nivelId: modelItem.nivelId != "-1" ? modelItem.nivelId : null,
            levelId: modelItem.levelId != "-1" ? modelItem.levelId : null,
            tipoId: modelItem.tipoId,
            estadoId: modelItem.estadoId,
            email: modelItem.email,
            celular: modelItem.celular,
            puestoId: modelItem.puestoId
        }

        console.log(modelUpdatePersona);
        setVerLoading(true);
        setVerLoadingBoton(true);
        PersonaService.putPersonalMMG(modelUpdatePersona)
            .then((res) => {
                if (res.status == 200) {
                    // alert("Se registró correctamente!!");
                    setMensajeTransaccion("Se ha actualizado correctamente");
                    setTipoMensaje(2);
                    setVerLoading(false);
                    setVerLoadingBoton(true);
                }

            }).catch(function (error) {
                if (error.response) {
                    // alert(e.message);
                    setMensajeTransaccion(error.response.data.detail)
                    setTipoMensaje(1);
                    setVerLoading(false);
                }
            });
    }

    const _onChangeTextEditarPersona = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (event != undefined) {
            if (newValue != undefined) {
                let nameInput = event.currentTarget.getAttribute("name");

                switch (nameInput) {
                    case "primerNombre":
                        setModelItem({
                            ...modelItem,
                            primerNombre: newValue.toUpperCase(),
                        });

                        break;
                    case "segundoNombre":
                        setModelItem({
                            ...modelItem,
                            segundoNombre: newValue,
                        });

                        break;
                    case "primerApellido":
                        setModelItem({
                            ...modelItem,
                            primerApellido: newValue.toUpperCase(),
                        });

                        break;
                    case "segundoApellido":
                        setModelItem({
                            ...modelItem,
                            segundoApellido: newValue,
                        });

                        break;
                    case "email":
                        setModelItem({
                            ...modelItem,
                            email: newValue,
                        });

                        break;
                    case "celular":
                        setModelItem({
                            ...modelItem,
                            celular: newValue,
                        });

                        break;
                    case "tarjeta":
                        setModelItem({
                            ...modelItem,
                            codigoEnrolar: newValue,
                        });

                        break;

                    default:
                        break;
                }
            }
        }
    };

    const _onChangeComboEditarPersona = (type: string, event: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (event != undefined) {
            if (option != undefined) {

                switch (type) {
                    case 'empresa':
                        setModelItem({
                            ...modelItem,
                            empresaId: option.key.toString(),
                        })
                        // setEmpresaId(option.key.toString());

                        break;
                    case 'gerencia':
                        setModelItem({
                            ...modelItem,
                            gerenciaId: option.key.toString(),
                        })

                        _ObtenerSuperIntendencia(option.key.toString());

                        break;

                    case 'superintendencia':
                        setModelItem({
                            ...modelItem,
                            superIntendenciaId: option.key.toString(),
                        })

                        break;
                    case 'roster':

                        /* console.log("----------------- roster combo ---------------")
                        console.log(option.key.toString()) */

                        setModelItem({
                            ...modelItem,
                            catalogoRosterId: option.key.toString(),
                        })

                        break;
                    case 'lugarMovilizacion':
                        setModelItem({
                            ...modelItem,
                            LugarMovilizacionId: option.key.toString(),
                        })

                        break;
                    case 'nivel':
                        setModelItem({
                            ...modelItem,
                            nivelId: option.key.toString(),
                        })

                        break;
                    case 'level':
                        setModelItem({
                            ...modelItem,
                            levelId: option.key.toString(),
                        })

                        break;
                    case 'tipoPersona':
                        setModelItem({
                            ...modelItem,
                            tipoId: option.key.toString(),
                        })

                        break;
                    case 'estadoPersona':
                        setModelItem({
                            ...modelItem,
                            estadoId: option.key.toString(),
                        })
                        break;
                    case 'puesto':
                        setModelItem({
                            ...modelItem,
                            puestoId: option.key.toString(),
                        })
                        break;
                    default:
                        break;
                }
            }
        }
    };

    const _ObtenerSuperIntendencia = (key: any) => {
        OrganigramaService.allComboSuperIntendencia(key, "")
            .then((res) => {
                let d: IBase[] = res.data.data;

                let t: IItemComboBaseDTO[] = d.map((x) => {
                    return {
                        key: x.id,
                        text: x.nombre,
                    };
                });

                setSuperintendenciaCombo(t)
            })
            .catch((e) => {
                return alert(e.message);
            });
    };

    const _onKeyUpCombo = (e: React.FormEvent<IComboBox>) => {

        let { id } = e?.target as HTMLInputElement;
        let { value } = e?.target as HTMLInputElement;

        switch (id) {
            case "empresa-input":
                _openOptionPersona()
                setFilterEmpresaPersona({
                    ...filterEmpresaPersona,
                    search: value,
                })

                break;
            case "gerencia-input":
                _openOptionGerencia()

                setFilteGerencia({
                    ...filteGerencia,
                    search: value,
                })

                break;
            case "superintendencia-input":
                _openOptionSuperintendencia();
                setFilterSuperintendencia({
                    ...filterSuperintendencia,
                    search: value
                })

                break;

            case "roster-input":
                _openOptionRoster();
                setFilterRoster({
                    ...filterRoster,
                    search: value
                })

                break;
            case "lugarMovilizacion-input":
                _openOptionMovilizacion();
                setfilterMovilizacion({
                    ...filterMovilizacion,
                    search: value
                })

                break;
            case "puesto-input":
                _openOptionPuesto();
                setSearchPuesto(value);

                break;
            default:
                break;
        }
    }

    const _goBackEditar = () => {
        dismissPanel();
        // dataTablaMarca(search);
        reloadParent()
        setVerLoadingBoton(false);
        setTipoMensaje(3);
    }

    const onRenderHeader = React.useCallback(
        () => (
            <>
                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12" style={{ paddingLeft: '2em' }} >
                    <ActivityItem
                        style={{
                            marginTop: 3
                        }}
                        activityDescription={[
                            <div>
                                <div className="LayoutPage-demoBlock">
                                    <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size18 }}>Editar Persona</div>
                                </div>
                            </div>

                        ]}
                        activityIcon={
                            <FontIcon
                                iconName="Contact"
                                style={{
                                    color: ThemeBase.theme.palette.blackTranslucent40,
                                    fontSize: 35,
                                    height: 35,
                                    width: 35,
                                    marginTop: 10
                                    // margin: "0 5px",

                                }}
                            />
                        }
                        comments={[
                            <div className="LayoutPage-demoBlock">
                                <div style={{ color: NeutralColors.gray130, fontSize: FontSizes.size12 }}>Edite datos de la persona: {info?.persona}</div>
                                <br />
                                <br />
                            </div>
                        ]}
                        // timeStamp={}
                        className={ThemeBase.activityClass.exampleRoot}
                    />
                </div>
            </>
        ),
        [openEditar, info?.persona],
    );

    const onRenderFooter = React.useCallback(
        () => (
            <>
                {verLoadingBoton == false && (
                    <>
                        <Stack horizontal horizontalAlign="end" styles={stackStyles}>
                            {/* <DefaultButton onClick={onclickBotonDefault}>{textBotonDefault}</DefaultButton> */}
                            <PrimaryButton
                                onClick={ActualizarDatoPersona}
                                style={{ marginLeft: '12px', width: "100%" }}
                                text="Actualizar"
                            /* iconProps={
                                {
                                    iconName: `${nameIconButton}`
                                }
                            } */
                            />
                        </Stack>
                    </>
                )}
            </>
        ),
        [personaId, modelItem, isOpenPanel, dismissEditar, ActualizarDatoPersona, reloadParent],
    );

    return (
        <>
            <Panel
                isOpen={isOpenPanel}
                onDismiss={dismissPanel}
                onRenderHeader={onRenderHeader}
                type={PanelType.medium}
                closeButtonAriaLabel="Close"
                onRenderFooterContent={onRenderFooter}
                isFooterAtBottom={true}
                isLightDismiss
            >
                <>
                    {tipoMensaje == 1 && (
                        <>
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                //onDismiss={p.resetChoice}
                                dismissButtonAriaLabel="Close"
                            //truncated={true}
                            //overflowButtonAriaLabel="See more"
                            >
                                <b>{mensajeTransaccion}</b>{" "}
                            </MessageBar>
                            <br></br>
                        </>
                    )}
                    {tipoMensaje == 2 && (
                        <>
                            <MessageBar
                                actions={
                                    <div>
                                        <MessageBarButton onClick={() => _goBackEditar()}>
                                            Conforme
                                        </MessageBarButton>
                                    </div>
                                }
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                            >
                                {mensajeTransaccion}
                            </MessageBar>
                            <br></br>
                        </>
                    )}
                    {!verLoading && (
                        <form action="">
                            <div className="ms-Grid" dir="ltr">
                                {/* <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        
                                        <pre>{JSON.stringify(modelItem, null, 2)}</pre>
                                    </div>

                                </div> */}
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label="Primer Nombre: "
                                            placeholder=""
                                            name='primerNombre'
                                            value={modelItem?.primerNombre}
                                            onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label="Segundo Nombre: "
                                            placeholder=""
                                            name='segundoNombre'
                                            value={modelItem?.segundoNombre}
                                            onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label="Apellido Paterno: "
                                            placeholder=""
                                            name='primerApellido'
                                            value={modelItem?.primerApellido}
                                            onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label="Apellido Materno: "
                                            placeholder=""
                                            name='segundoApellido'
                                            value={modelItem?.segundoApellido}
                                            onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label="Email: "
                                            placeholder=""
                                            type="email"
                                            name='email'
                                            value={modelItem?.email}
                                            onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label="N° Celular:"
                                            placeholder=""
                                            name='celular'
                                            value={modelItem?.celular}
                                            onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Tipo:"
                                            id="tipoPersona"

                                            options={personaTipoCombo}
                                            // componentRef={empresaRef}
                                            selectedKey={modelItem?.tipoId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeComboEditarPersona("tipoPersona", e, o)}
                                        // onKeyUp={_onKeyUpCombo}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Estado:"
                                            id="estadoPersona"
                                            options={personaEstadoCombo}
                                            // componentRef={empresaRef}
                                            selectedKey={modelItem?.estadoId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeComboEditarPersona("estadoPersona", e, o)}
                                        // onKeyUp={_onKeyUpCombo}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Empresa:"
                                            id="empresa"
                                            options={empresaPersonaCombo}
                                            componentRef={empresaPersonaRef}
                                            selectedKey={modelItem?.empresaId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeComboEditarPersona("empresa", e, o)}
                                            onKeyUp={_onKeyUpCombo}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Puesto:"
                                            id="puesto"
                                            options={puestoCombo}
                                            componentRef={puestoRef}
                                            selectedKey={modelItem?.puestoId}
                                            defaultSelectedKey={modelItem?.puestoId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeComboEditarPersona("puesto", e, o)}
                                            onKeyUp={_onKeyUpCombo}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Gerencia:"
                                            id="gerencia"
                                            options={gerenciaCombo}
                                            componentRef={gerenciaRef}
                                            selectedKey={modelItem?.gerenciaId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeComboEditarPersona("gerencia", e, o)}
                                            onKeyUp={_onKeyUpCombo}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Superintendencia:"
                                            id="superintendencia"
                                            options={superintendenciaCombo}
                                            componentRef={superintendenciaRef}
                                            selectedKey={modelItem?.superIntendenciaId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeComboEditarPersona("superintendencia", e, o)}
                                            onKeyUp={_onKeyUpCombo}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                </div>
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Roster:"
                                            id="roster"
                                            options={rosterCombo}
                                            componentRef={rosterRef}
                                            selectedKey={modelItem?.catalogoRosterId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeComboEditarPersona("roster", e, o)}
                                            onKeyUp={_onKeyUpCombo}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Lugar Movilización:"
                                            id="lugarMovilizacion"
                                            options={lugarMovilizacionCombo}
                                            componentRef={lugarMovilizacionRef}
                                            selectedKey={modelItem?.LugarMovilizacionId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeComboEditarPersona("lugarMovilizacion", e, o)}
                                            onKeyUp={_onKeyUpCombo}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>

                                </div>
                                {/* <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <pre>nombre puesto: {nombrePuesto}</pre>
                                        <pre>cantidad: {nombrePuesto?.length}</pre>
                                        <pre>{JSON.stringify(puestoCombo, null, 2)}</pre>
                                    </div>

                                </div> */}
                                <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Nivel:"
                                            id="nivel"
                                            options={nivelCombo}
                                            selectedKey={modelItem?.nivelId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeComboEditarPersona("nivel", e, o)}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Level:"
                                            id="level"
                                            options={levelCombo}
                                            selectedKey={modelItem?.levelId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeComboEditarPersona("level", e, o)}
                                        />
                                    </div>
                                
                                </div> 
                                <br />
                                {/* <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <TextField
                                            label="Tarjeta: "
                                            name='tarjeta'
                                            value={modelItem?.codigoEnrolar != null ? modelItem?.codigoEnrolar : ""}
                                            onChange={(e, o) => _onChangeTextEditarPersona(e, o)}
                                        />
                                    </div>
                                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                        <ComboBox
                                            label="Tipo:"
                                            id="tipoPersona"

                                            options={personaTipoCombo}
                                            // componentRef={empresaRef}
                                            selectedKey={modelItem?.tipoId}
                                            calloutProps={{ directionalHintFixed: true }}
                                            useComboBoxAsMenuWidth
                                            allowFreeform
                                            onChange={(e, o) => _onChangeComboEditarPersona("tipoPersona", e, o)}
                                        // onKeyUp={_onKeyUpCombo}
                                        // onBlur={_onBlurEmpresa}
                                        />
                                    </div>
                                </div> */}
                            </div>
                        </form>
                    )}
                    {verLoading && (
                        <>
                            <br></br>
                            <ProgressIndicator
                                barHeight={5}
                                label="Enviando información"
                                description="Favor Espere un momento"
                            />
                        </>
                    )}
                </>

            </Panel>
        </>
    );
}

export { PanelActualizarPersona }